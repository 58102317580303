<template>
  <b-container class="app-now-playing">
    <b-row class="align-items-center h-100">
      <b-col class="d-none d-lg-block" lg="3"><TrackInfo class="track-info" /></b-col>
      <b-col cols="12" md="8" lg="6"><PlayBar class="playbar" /></b-col>
      <b-col class="d-none d-md-block" md="4" lg="3"><AudioControls class="audio-controls text-right" /></b-col>
    </b-row>
  </b-container>
</template>

<script>

import TrackInfo from './TrackInfo.vue'
import PlayBar from './PlayBar.vue'
import AudioControls from './AudioControls.vue'

export default {
  components: { TrackInfo, PlayBar, AudioControls, },
  mounted: function() {

    // error
    this.$bus.on('audio:error', (msg) => {
      this.$bus.emit('notification:error', msg)
    })

    // window title
    this.$audio.on('status', (status) => {
      let title = 'Mediastation'
      if (status?.ready && status.track()?.title) {
        title = `${title} - ${status.track().title}`
      }
      document.title = title
    })

    // event listener
    document.addEventListener('keydown', this.onKeyDown)
    
  },
  methods: {

    onKeyDown(ev) {
      if (ev.keyCode == 32) {
        if (ev.target.nodeName != 'INPUT' && ev.target.nodeName != 'TEXTAREA') {
          this.$audio.playPause()
        }
      }
      if (ev.keyCode == 38) { // arrow up
        let volume = this.$audio.volume()
        this.$audio.volume(Math.min(1, volume + this.volume_offset(ev)))
        ev.preventDefault()
        ev.stopPropagation()
      }
      if (ev.keyCode == 40) { // arrow down
        let volume = this.$audio.volume()
        this.$audio.volume(Math.max(0, volume - this.volume_offset(ev)))
        ev.preventDefault()
        ev.stopPropagation()
      }
    },

    volume_offset(ev) {
      return (ev.ctrlKey || ev.metaKey) ? 0.01 : 0.05
    }

  }
}

</script>

<style lang="scss" scoped>

.app-now-playing {
  width: 100%;
  height: 100%;
  max-width: none;
  padding-left: var(--navigation-width);
  padding-right: var(--navigation-width);
  background-color: #282828 !important;
  border-color: #282828 !important;
}

@import '../../css/mixins';
@include media-phone() {
  .app-now-playing {
    padding: 0px;
  }
}

</style>
