
export default class {

  constructor(options) {
    this._http = options.http
    this._dummy_password = '{123456}'
  }

  async get() {

    // get it
    let config = await this._get()

    // toggle passwords
    if (config.security.config_password) {
      config.security.config_password = this._dummy_password
    }
    if (config.security.browsing_password) {
      config.security.browsing_password = this._dummy_password
    }

    // done
    return config
  }

  async save(config) {

    // get previous
    let previous = await this.get()

    // some skips
    const skips = [
      'general.logging',
      'navigation.trees',
      'network.external_ip'
    ]
    
    // now start a diff
    let diff = {}
    for (const group in config) {
      for (const name in config[group]) {
        if (skips.includes(`${group}.${name}`) == false) {
          if (JSON.stringify(config[group][name]) != JSON.stringify(previous?.[group]?.[name])) {
            if (diff[group] == null) {
              diff[group] = {}
            }
            diff[group][name] = config[group][name]
          }
        }
      }
    }

    // done
    if (Object.keys(diff).length == 0) {
      console.log('[CONFIG] No differences found')
      return
    }

    // log
    //console.log(JSON.stringify(diff))

    // save
    await this.submit(diff)
  }

  async submit(json) {
    await this._http.patch('/control/config', json)
  }

  async _get() {
    return await this._http.getWs('/control/config')
  }

}
