import media from '../model/media.js'
import utils from '../utils/utils.js'

export function areSamePlaylists(playlist1, playlist2) {

  try {

    // null stuff
    if (playlist1 == null && playlist2 == null) return true
    if (playlist1 == null && playlist2 != null) return false
    if (playlist1 != null && playlist2 == null) return false

    // id
    if (playlist1.id != playlist2.id) {
      return false
    }

    // title
    if (playlist1.title != playlist2.title) {
      return false
    }

    // tracks
    return utils.arrayEquals(playlist1.tracks, playlist2.tracks, media.equal);

  } catch (err) {
    console.warn(err)
    return false;
  }

}
