<template>
  <div :id="item.id" class="list-item" :class="computedClass" @mouseenter="mouseEnter" @mousemove="mouseMove" @mouseleave="mouseLeave">
    <b-popover :show.sync="menuShown" :target="menuIconId" :container="item.id" triggers="manual" custom-class="menu" v-if="hoverShown">
      <div @click="playNext" v-if="canEnqueue">{{ $t('browser_menu_play_next') }}</div>
      <div @click="enqueue" v-if="canEnqueue">{{ $t('browser_menu_enqueue') }}</div>
      <div @click="updateThumbnail" v-if="canEditThumbnail">{{ $t('browser_menu_update_thumb') }}</div>
      <div @click="download" v-if="canDownload">{{ $t('browser_menu_download') }}</div>
      <div @click="copyLink" v-if="canBeLinked">{{ $t('browser_menu_copy_link') }}</div>
      <div @click="gotoAlbum" v-if="canGotoAlbum">{{ $t('browser_menu_goto_album') }}</div>
      <div @click="suppress" v-if="canSuppress">{{ $t('browser_menu_delete') }}</div>
    </b-popover>
    <a @click="onLink">
      <div class="thumbnail" :class=" { 'default': item.thumbnail == null }">
        <img src="/icons/badge-4k.png" class="badge-4k" v-if="item.type == 'video' && item.is4K()" />
        <!--Hero :tag="item.id"-->
          <img :src="item.thumbnail" alt="" class="thumbnail" @error="setDefaultThumb" v-if="item.thumbnail" />
          <img :src="getDefaultThumb()" class="thumbnail" alt="" v-else />
        <!--/Hero-->
        <div class="menu-container" :class="hoverShown ? 'active' : ''" v-if="hasMenu">
          <Icon :id="menuIconId" name="three-dots" :size=12 @click.native="showMenu" />
        </div>
      </div>
      <div class="caption" v-if="hasCaption">
        <span>{{ item.title }}</span>
      </div>
    </a>
  </div>
</template>

<script>

import media from '../../js/model/media.js'
import utils from '../../js/utils/utils.js'

export default {
  props: [ 'item', 'items' ],
  data: function() {
    return {
      hoverTimer: null,
      hoverShown: false,
      menuShown: false,
      suppressed: false,
    }
  },
  computed: {
    menuIconId() {
      return `menu-icon-${this.item.id}`
    },
    computedClass() {
      return [ media.getMediaType(this.item.id), this.suppressed ? 'suppressed' : '' ]
    },
    hasCaption() {
      return this.item.type != 'image'
    },
    canEnqueue() {
      return [ 'malbum' ].includes(this.item.type)
    },
    canEditThumbnail() {
      return [ 'martist', 'video', 'playlist' ].includes(this.item.type)
    },
    canDownload() {
      return [ 'audio', 'image', 'video', 'malbum' ].includes(this.item.type)
    },
    canBeLinked() {
      return this.item.type.startsWith('search_') == false
    },
    canGotoAlbum() {
      return [ 'search_track' ].includes(this.item.type)
    },
    canSuppress() {
      return [ 'playlist' ].includes(this.item.type)
    },
    hasMenu() {
      return this.canEnqueue || this.canEditThumbnail || this.canDownload || this.canBeLinked || this.canGotoAlbum || this.canSuppress
    },
    url() {
      if (this.item.type == 'malbum') {
        return { name: 'album_by_artist', params: { id: this.item.id, artist: this.item.artist, album: this.item.title } }
      } else if (this.item.type == 'martist') {
        return { name: 'artist_albums', params: { id: this.item.id, artist: this.item.title } }
      } else if (this.item.type == 'playlist') {
        return { name: 'playlist', params: { id: this.item.id, item: this.item, slug: this.item.slug } }
      } else if (this.item.container && this.item.custom != true) {
        return { name: 'browser', params: { type: this.$route.params.type, id: this.item.id, item: this.item } }
      } else if (this.item.type == 'video') {
        return { name: 'movie', params: { id: this.item.uid, item: this.item, slug: this.item.slug } }
      } else if (this.item.type == 'image') {
        return { name: 'image', params: { images: this.items, initialIndex: this.items.indexOf(this.item), } }
      } else {
        return { name: 'custom', params: { id: this.item.id, item: this.item } }
      }
    }
  },
  unmounted: function() {
    this.hideMenu()
  },
  methods: {
    
    getDefaultThumb() {
      switch (media.getMediaType(this.item.id)) {
        case 'image':
          return this.item.container ? '../../icons/folder_images.png' : '../../icons/image.png'
        case 'audio':
          return this.item.container ? '../../icons/folder_music.png' : '../../icons/audio.png'
        case 'video':
          return this.item.container ? '../../icons/folder_video.png' : '../../icons/video.png'
      }
    },
    setDefaultThumb(e) {
      let src = this.getDefaultThumb()
      if (src && e.target.src != src) {
        e.target.src = src
        e.target.closest('.thumbnail').classList.add('default')
      }
    },

    onLink(e) {

      // hacky but working
      if (e.target.classList.contains('no-next-click')) {
        e.target.classList.remove('no-next-click')
        return
      }

      // get url
      let url = this.url

      // custom
      if (url.name == 'custom') {

        // audio track play
        if (url.params.item.type == 'audio') {
          this.$audio.play({
            title: null,
            tracks: [ this.item ],
            start: 0,
          })
        }

        // done here
        return
      }

      // default
      this.$router.push(url)
      
    },

    gotoAlbum() {
      this.$router.push({ name: 'album_by_artist', params: {
        item: this.item,
        artist: this.item.artist,
        album: this.item.album
      } })
    },

    mouseEnter() {
    },
    mouseMove(ev) {

      // if not already shown
      if (this.hoverShown == false) {

        // clear previous timer
        clearTimeout(this.hoverTimer)

        // default delay
        let delay = 500

        // if user is hovering where the menu is then show immediately
        let hotspot = ev.target?.closest('.list-item')?.querySelector('.menu-container')
        if (hotspot != null) {
          var rect = hotspot.getBoundingClientRect()
          if (ev.clientX >= rect.left && ev.clientX <= rect.right && ev.clientY >= rect.top && ev.clientY <= rect.bottom) {
            delay = 0
          }
        }

        // now set timer
        this.hoverTimer = setTimeout(() => {
          this.hoverShown = true
        }, delay)

      }
    },
    mouseLeave() {
      clearTimeout(this.hoverTimer)
      if (this.menuShown == false) {
        this.hoverShown = false
      }
    },
    showMenu(ev) {
      ev.preventDefault()
      ev.stopImmediatePropagation()
      document.addEventListener('click', this.hideMenu)
      document.addEventListener('contextmenu', this.hideMenu)
      this.hoverShown = true
      this.menuShown = true
    },
    hideMenu() {
      document.removeEventListener('click', this.hideMenu)
      document.removeEventListener('contextmenu', this.hideMenu)
      this.menuShown = false
      this.hoverShown = false
    },
    
    preprocessMenu(ev) {
      ev.stopImmediatePropagation()
      this.hideMenu()
    },

    async enqueue(ev) {
      this.preprocessMenu(ev)
      let tracks = await this.$http.getItems(this.item.id)
      this.$audio.enqueue(tracks)
    },
    
    async playNext(ev) {
      this.preprocessMenu(ev)
      let tracks = await this.$http.getItems(this.item.id)
      this.$audio.playNext(tracks)
    },
    
    async copyLink(ev) {
      this.preprocessMenu(ev)
      if (await utils.copyLinkToItem(this.item)) {
        this.$bus.emit('notification:success', 'browser_url_copied_ok')
      }
    },
    
    updateThumbnail(ev) {
      this.preprocessMenu(ev)
      this.$router.push({ name: 'edit_thumbnail', params: { id: this.item.id, item: this.item }})
    },
    
    download(ev) {
      this.preprocessMenu(ev)
      this.$bus.emit('notification:info', 'lengthy_operation')
      window.location.href = this.item.getDownloadUrl(true)
    },

    async suppress(ev) {
      this.preprocessMenu(ev)
      let value = await this.$bvModal.msgBoxConfirm(this.$i18n.t('pl_delete_confirm'), {
          okVariant: 'danger',
          okTitle: 'Yes',
      })
      if (value) {
        let id1 = this.$audio.status().playlist.id.toString().split(media.separator).pop()
        let id2 = this.item.uid
        await this.$http.delete(`/playlist?id=${encodeURIComponent(this.item.id)}`)
        if (id1 === id2)  this.$audio.playlistInfo(null, null)
        this.suppressed = true
      }
    }

  }
}

</script>

<style scoped>

a, a:hover {
  text-decoration: none;
  cursor: pointer;
}

.list-item.suppressed {
  display: none;
}

.list-item .thumbnail {
  position: relative;
  height: var(--library-item-size);
}

.list-item .thumbnail img {
  z-index: 10;
  -user-drag: none;
  -webkit-user-drag: none;
}

.list-item.video .thumbnail {
  height: calc(var(--library-item-size) * var(--video-thumbnail-ratio));
}

.list-item .thumbnail .badge-4k {
  position: absolute;
  height: auto !important;
  width: 20px;
  right: 4px;
  top: 4px;
}

.list-item .thumbnail .menu-container {
  --size: 32px;
  position: absolute;
  width: var(--size);
  height: var(--size);
  right: 0px;
  bottom: 0px;
  padding-top: 5px;
  padding-left: 11px;
  border-radius: 30px 0px 0px 0px;
  background-color: rgba(0,0,0,0.9);
  visibility: hidden;
}

.list-item .thumbnail.default .menu-container {
  background-color: transparent;
}

.list-item .thumbnail .menu-container.active {
  visibility: visible;
  z-index: 20;
}

.list-item .thumbnail img.thumbnail {
  text-indent: -10000px; /* to hide broken image icon */
  width: var(--library-item-size);
  height: var(--library-item-size);
  object-fit: cover;
}

.list-item .thumbnail.default img.thumbnail {
  padding: calc(var(--library-item-size) * 1/6);
  border: 1px solid var(--ghosted-text-color);
}

.list-item.video .thumbnail img.thumbnail {
  height: calc(var(--library-item-size) * var(--video-thumbnail-ratio));
}

.list-item.video .thumbnail.default img.thumbnail {
  padding: calc(var(--library-item-size) * 1/3) calc(var(--library-item-size) * 1/6);
}

.list-item .caption {
  margin-top: 4px;
  font-size: 12pt;
  text-align: center;
  font-weight: bold;
  max-height: 44pt;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
