<template>
  <router-link :to="to" class="app-navigation-item">
    <div class="indicator" v-if="active" />
    <Icon :name="icon" :size=18 :color="computedColor" />
  </router-link>
</template>

<script>
export default {
  props: [ 'icon', 'to', 'active' ],
  computed: {
    computedColor() {
      return this.active ? 'var(--highlight-color)' : 'var(--text-color)'
    }
  }
}
</script>

<style lang="scss" scoped>

.app-navigation-item {
  display: block;
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
  cursor: pointer;

  .indicator {
    position: absolute;
    background-color: var(--highlight-color);
    width: 6px;
    height: 40px;
    top: -1px;
  }
}

@import 'css/mixins';
@include media-phone() {
  .app-navigation-item {
    i {
      font-size: 14pt !important;
    }
    .indicator {
      width: 4px;
      height: 32px;
      top: 0px;
    }
  }
}

</style>
