
import Vue from 'vue'
import router from './router.js'
import { BootstrapVue } from 'bootstrap-vue'
import Hero from 'vue-hero-transition'
import VueI18n from 'vue-i18n'
import Plugin from './utils/plugin.js'
import App from '../src/App.vue'
import mitt from 'mitt'
import http from './http.js'
import store from './store.js'
import audio from './audio/manager.js'
import { initI18n } from './utils/i18n.js'

// css
//import 'bootstrap/dist/css/bootstrap.css'
import 'bootswatch/dist/darkly/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '../css/main.scss'

// plugins
Vue.use(BootstrapVue)
Vue.use(VueI18n)
Vue.use(Plugin)
Vue.use(Hero)

// custom bus (to prepare vue3)
Vue.prototype.$bus = mitt()

// our stuff
Vue.prototype.$http = http
Vue.prototype.$audio = new audio(Vue.prototype.$bus, Vue.prototype.$http)

// do it
new Vue({
  i18n: initI18n(),
  store: store,
  router: router,
  render: h => h(App),
}).$mount('#app')
