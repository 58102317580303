<template>
  <div class="track-info" v-if="track">
    <img class="cover" :src="track.thumbnail" @click="goToAlbum()" />
    <div class="info">
      <div class="title" @click="goToAlbum()">{{ track.title }}</div>
      <div class="artist" @click="goToArtist()">{{ track.artist }}</div>
    </div>
  </div>
</template>

<script>

export default {
  data: function() {
    return {
      track: null,
    }
  },
  mounted: function() {
    this.$audio.on('status', (status) => {
      this.track = status?.track()
    })
  },
  methods: {
    goToArtist: function() {
      this.$router.push({ name: 'artist_albums', params: { artist: this.track.artist }})
    },
    goToAlbum: function() {
      this.$router.push({ name: 'album_by_artist', params: {
        artist: this.track.compilation ? '' : this.track.artist,
        album: this.track.album
      }})
    }
  }
}

</script>

<style scoped lang="scss">

.track-info {
  overflow-x: hidden;
  white-space: nowrap;
  cursor: pointer;
 .cover {
    width: 64px;
    height: 64px;
    object-fit: cover;
  }

  .info {
    display: none;
    vertical-align: middle;
    margin-left: 16px;  

    div {
      color: var(--text-color);
      font-size: 12pt;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: var(--disabled-text-color);
        text-underline-offset: 4px;
      }
    }

    .title {
      font-weight: bold;
    }
  }

}

@media (min-width: 1200px) {
  .track-info .info {
    display: inline-block;
  }
}

</style>
