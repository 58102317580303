<template>
  <div class="app-navigation">
    <img class="logo" src="../../icons/mediastation.png" />
    <hr/>
    <NavigationItem icon="image" :to="route('image')" :active="isMediaActive('image')" />
    <NavigationItem icon="music-note-beamed" :to="route('audio')" :active="isMediaActive('audio')" />
    <NavigationItem icon="film" :to="route('video')" :active="isMediaActive('video')" />
    <hr/>
    <NavigationItem icon="play-btn" :to="{ name: 'slideshow', params: { slideshow: true } }" :active=false />
    <hr/>
    <NavigationItem icon="cloud-download" class="import" :to="{ name: 'import' }" :active="$route.name == 'import'" v-if="importEnabled"/>
    <NavigationItem icon="sliders" class="config" :to="{ name: 'configuration' }" :active="$route.name == 'configuration'" />
    <PlaybackDevice class="control" :size=14 placement="right" :toggleOnClick=false />
  </div>
</template>

<script>

import NavigationItem from './NavigationItem.vue'
import PlaybackDevice from '../audio/PlaybackDevice.vue'
import media from '../../js/model/media.js'

export default {
  components: { NavigationItem, PlaybackDevice, },
  data: function () {
    return {
      importEnabled: false
    }
  },
  mounted: async function() {
    let tidalHealth = await this.$http.getWs('/tidal/health')
    this.importEnabled = tidalHealth.enabled
  },
  methods: {
    
    isMediaActive(type) {
      if (this.$route.name != 'configuration' && this.$route.name != 'import') {
        let breadcrumbs = this.$store.state.breadcrumbs
        if (media.getMediaType(breadcrumbs[0]?.id) == type) {
          return true
        }
      }

      // too bad
      return false
    },
    
    route(type) {
      let root = media.getRoots()[type]
      return { name: 'browser', params: { id: root.id, type: this.$t(root.title()).toLowerCase() } }
    },

    config() {
      this.$router.replace({ name: 'configuration' })
    },
    
  }
}
</script>

<style lang="scss" scoped>

.app-navigation {
  height: 100%;
  background-color: rgba(var(--dark-bg-rgb), 0.8);
  text-align: center;
  padding-top: 32px;

  hr {
    margin: 2rem 8px;
    border-color: var(--faded-text-color);
  }

  .logo {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }

  .playback-device {
    display: none;
  }
}

@import 'css/mixins';
@include media-phone() {
  .app-navigation {
    
    hr{
      margin: 1rem 8px;
    }
    
    .logo {
      width: 24px;
      height: 24px;
      margin-bottom: 1rem;
    }

    .import, .config {
      display: none;
    }
    
    .playback-device {
      display: inline-block;
    }
  }
}

</style>
