
export default class AudioPlayer {

  // constants
  static get STATE_STOPPED() { return 'stopped' }
  static get STATE_PLAYING() { return 'playing' }
  static get STATE_BUFFERING() { return 'buffering' }
  static get STATE_PAUSED() { return 'paused' }

  constructor(options) {

    // init
    this._reset()
    this._listeners = {}

    // options
    this._options = options || {}
    this.on('play', options.onplay)
    this.on('end', options.onend)
    this.on('status', options.onstatus)
    this.on('error', options.onerror)
  }

  cleanup() {
  }

  async playlist(playlist) {
    this._playlist = playlist
    if (this._playlist != null) {
      this._playlist.editable = this._isPlaylistEditable()
    }
  }
  
  status() {
    return {
      state: this.state() || AudioPlayer.STATE_STOPPED,
      error: this.error() || false,
      ready: this.ready() || false,
      buffering: this.buffering(),
      playing: this.playing(),
      playlist: this._playlist,
      index: this._index,
      position: this.position(),
      volume: this.volume(),
      track: function() {
        return this.playlist?.tracks?.[this.index]
      }
    }
  }

  description() {
    throw 'description: not implemened'
  }

  async checkAvailability(timeoutMs) {
    return true
  }

  has_tracks() {
    return this._playlist != null && this._playlist.tracks != null && this._playlist.tracks.length > 0
  }
  
  tracks() {
    return this._playlist?.tracks
  }

  track() {
    return this.tracks()?.[this._index]
  }

  state() {
    throw 'ready: not implemened'
  }

  buffering() {
    return this.state() == AudioPlayer.STATE_BUFFERING
  }

  playing() {
    return this.state() == AudioPlayer.STATE_PLAYING
  }

  error() {
    return false
  }

  ready() {
    throw 'ready: not implemened'
  }

  play() {
    throw 'play: not implemened'
  }

  pause() {
    throw 'pause: not implemened'
  }

  stop() {
    throw 'stop: not implemened'
  }

  position() {
    throw 'position: not implemened'
  }

  duration() {
    throw 'duration: not implemened'
  }

  volume(_) {
    throw 'volume: not implemened'
  }

  volumeDown() { }
  
  volumeUp() { }

  seek(_) {
    throw 'seek: not implemened'
  }

  goto(_) {
    throw 'goto: not implemened'
  }

  previous() {
    this.goto(this._prevIndex())
  }

  next() {
    this.goto(this._nextIndex())
  }

  playlist_info(id, title) {
    if (this._playlist != null) {
      this._playlist.id = id
      this._playlist.title = title
      this._emit('status')
    }
  }

  async enqueue(track) {
    if (this._playlist?.tracks) {
      this._playlist.tracks.push(track)
      this._emit('status')
      return true
    }
  }

  async dequeue(index) {
    if (this._playlist?.tracks) {
      let track_id = this.track()?.id
      this._playlist.tracks = this._playlist.tracks.filter((_, idx) => idx != index )
      if (this.has_tracks() && this._index == index) {
        this._index = index - 1
        this.next()
      } else {
        this._index = this._playlist.tracks.findIndex((t) => t.id == track_id)
        if (this._index == -1) {
          this.stop()
          this._reset()
        }
      }
      this._emit('status')
      return true
    }
  }

  async empty() {
    if (this._playlist?.tracks) {
      while (this._playlist.tracks.length > 1) {
        if (this._index == 0) await this.dequeue(1)
        else await this.dequeue(0)
      }
    }
  }

  async requeue(from, to) {
    if (this._playlist?.tracks) {
      let track_id = this.track()?.id
      this._playlist.tracks.splice(to, 0, ...this._playlist.tracks.splice(from, 1))
      this._index = this._playlist.tracks.findIndex((t) => t.id == track_id)
      this._emit('status')
      return true
    }
  }

  async playNext(track) {
    if (this._playlist?.tracks) {
      let track_id = this.track()?.id
      this._playlist.tracks.splice(this._index+1, 0, track)
      this._index = this._playlist.tracks.findIndex((t) => t.id == track_id)
      this._emit('status')
      return true
    }
  }

  on(type, callback) {
    if (this._listeners[type] == null) {
      this._listeners[type] = []
    }
    if (callback != null) {
      this._listeners[type].push(callback)
    }
  }

  _prevIndex() {
    return (this._index == 0) ? this._playlist.tracks.length-1 : this._index-1
  }

  _nextIndex() {
    return (this._index + 1 ) % this._playlist.tracks.length
  }

  _isPlaylistEditable() {
    throw '_isPlaylistEditable: not implemened'
  }

  _emit(type, event) {
    for (const listener of this._listeners[type]) {
      listener(event)
    }
  }

  _reset() {
    this._playlist = {
      id: null,
      title: null,
      tracks: [],
      editable: false,
    }
    this._index = null
  }

  _display_error(msg) {
    this._options?.bus?.emit('audio:error', msg)
  }

}
