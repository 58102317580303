<template>
  <Icon :name="computedIconVolume" :class="computedClassVolume" :size=size @click.native="onVolume"/>
</template>

<script>

export default {
  props: [ 'mode', 'size' ],
  data: function() {
    return {
      volume: null,
      savedVolume: 0,
    }
  },
  computed: {
    isVisible() {
      return this.mode == 'less' || this.volume < 0
    },
    computedIconVolume() {
      if (this.mode == 'less') {
        if (this.volume == null) return 'volume-off-fill'
        if (this.volume < 0) return 'volume-down-fill'
        if (this.volume < 0.01) return 'volume-mute-fill'
        if (this.volume > .66) return 'volume-up-fill'
        return 'volume-down-fill'
      } else if (this.mode == 'more') {
        return 'volume-up-fill'
      }
    },
    computedClassVolume() {
      if (this.volume != null && this.volume < 0) return 'enabled'
      if (this.mode == 'more' && (this.volume == null || this.volume >= 0)) return 'd-none'
      return this.$audio.ready() && this.volume != null ? 'enabled' : 'disabled'
    },
  },
  mounted: function() {
    this.$audio.on('status', (status) => {
      this.volume = status?.volume
    })
  },
  methods: {
    onVolume() {
      if (this.volume == null) {
        return
      } else if (this.volume < 0) {
        if (this.mode == 'less') this.$audio.volumeDown()
        if (this.mode == 'more') this.$audio.volumeUp()
      } else {
        let volume = this.$audio.volume()
        this.$audio.volume(this.savedVolume)
        this.savedVolume = volume
      }
    },
  }
}

</script>

<style lang="scss" scoped>
@import '/css/mixins';

.audio-controls {
  @include user-select-none;
  white-space: nowrap;
}

.audio-controls .control {
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
}

.audio-controls .control.enabled {
  color: var(--text-color) !important;
}

.audio-controls .control.disabled {
  color: var(--disabled-text-color) !important;
}

.audio-controls .volume {
  width: 100px;
}

</style>
