<template>
  <div class="app-import-tidal">
    <div class="form">
      <div class="help">{{ $t('import_tidal_help')}}</div>
      <b-input type="text" autocomplete="off" autocorrect="off" v-model="query" @keydown.enter.native="search" placeholder="https://listen.tidal.com/album/"/>
      <b-button @click="search" variant="primary">{{ $t('import_find') }}</b-button>
    </div>
    <div v-if="results" class="previews">
      <!--div class="help">{{ $t('import_preview_help')}}</div-->
      <div class="preview" v-for="album in results" :key="album.id">
        <div class="actions">
          <b-button @click="play(album)" variant="primary">⏵</b-button>&nbsp;
          <b-button @click="run(album)" variant="primary">{{ $t('import_run') }}</b-button>
          <b-button @click="showOptions(album)" variant="primary">☰</b-button>
        </div>
        <img class="cover" :src="cover(album)" />
        <div class="details">
          <div class="title"><a :href="album.url" target="_blank">{{ album.title  }}</a></div>
          <div class="artist">{{ album.artist.name || album.artists[0].name }}</div>
          <div class="year">{{ album.releaseDate ? album.releaseDate.substr(0, 4) : '' }}</div>
          <div class="tracks"><span :id="'tracks_'+album.id">{{ $tc('import_tracks_count', album.numberOfTracks, { count: album.numberOfTracks} )  }}</span></div>
          <TidalQuality :quality="album.audioQuality" class="quality"></TidalQuality>
        </div>
        <b-popover :target="'tracks_'+album.id" triggers="hover" @show="loadTracks(album.id)">
          <div class="tracks" v-if="tracks[`a${album.id}`]">
            <div v-for="track in tracks[`a${album.id}`]" :key="track.id">
              {{ track.item.trackNumber }}. {{ track.item.title }}
            </div>
          </div>
          <div v-else>
            {{ $t('loading') }}
          </div>
        </b-popover>
      </div>
    </div>
    <b-modal v-model="options" id="import-options" modal-class="import-options" :title="$t('import_options')" @ok="advanced()">
      <div class="query">
        <b-form>
          <b-form-group :label="$t('import_album')">
            <b-input v-model="import_title" />
          </b-form-group>
          <b-form-group :label="$t('import_artist')">
            <b-input v-model="import_artist"/>
          </b-form-group>
          <b-form-group :label="$t('import_compilation')">
            <b-checkbox v-model="import_compil"/>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>

import TidalQuality from '../audio/TidalQuality.vue'

export default {
  components: { TidalQuality },
  data: function() {
    return {
      query: '',
      results: null,
      tracks: {},
      options: false,
      candidate: null,
      import_title: null,
      import_artist: null,
      import_compil: false,
    }
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
    
    cover(album) {
      return `https://resources.tidal.com/images/${album.cover?.replaceAll('-', '/')}/320x320.jpg`
    },
    
    search() {
      // check and clear
      if (this.query == '') return
      this.results = null
      let query = this.query

      // check if it is a url
      if (query.includes('tidal.com')) {
        this.searchByUrl(query)
      } else if (query.match(/^\d+$/)) {
        this.searchById(query)
      } else {
        this.searchByText(query)
      }
    },
    
    searchByUrl(url) {
      let tokens = url.split('/')
      for (let i=0; i<tokens.length; i++) {
        if (tokens[i] == 'album' && i != tokens.length-1) {
          return this.searchById(tokens[i+1])
        }
      }
      this.$bus.emit('notification:error', 'import_find_ko')
    },
    
    async searchById(id) {
      try {
        let info = await this.$http.getWs(`/tidal/info/album/${id}`)
        if (info.result?.id == null) throw new Error()
        this.results = [ info.result ]
      } catch (_) {
        this.$bus.emit('notification:error', 'import_find_ko')
      }
    },
    
    async searchByText(query) {
      try {
        let albums = await this.$http.getWs(`/tidal/search/album?query=${query}`)
        this.results = albums.result.items
      } catch (_) {
        this.$bus.emit('notification:error', 'import_find_ko')
      }
    },

    async loadTracks(id) {

      // already loaded?
      let key = `a${id}`
      if (this.tracks[key]) return
      
      try {
        let tracks = await this.$http.getWs(`/tidal/info/album/${id}`)
        this.$set(this.tracks, key, tracks.result.items)
      } catch (err) {
        console.log(err)
        //this.$bus.emit('notification:error', 'import_find_ko')
      }
    },

    async play(album) {

      // request a device
      if (await this.$audio.requestDevice('tidal') == -1) {
        this.$bus.emit('notification:error', `tidal_no_device_available`)
        return
      }

      // play it
      this.$http.getWs(`/tidal/play/album/${album.id}`)

    },

    showOptions(album) {
      this.candidate = album
      this.import_title = album.title
      this.import_artist = album.artist.name || album.artists[0].name
      this.import_compil = (this.import_artist == 'Various Artists')
      this.options = true
    },

    advanced() {
      this.run(this.candidate, this.import_title, this.import_artist, this.import_compil)
    },
    
    run(album, title, artist, compilation) {

      // build url
      let url = `/tidal/import?type=album&id=${album.id}`
      if (title) url = `${url}&title=${encodeURIComponent(title)}`
      if (artist) url = `${url}&artist=${encodeURIComponent(artist)}`
      if (compilation) url = `${url}&compilation=true`

      // now do it
      this.$http.getWs(url).then((result) => {
        if (result.imported + result.skipped == 0) {
          this.$bus.emit('notification:error', 'import_run_ko')
        } else if (result.errors != 0) {
          this.$bus.emit('notification:error', this.$t('import_ko_partial', result.errors))
          this.$bus.emit('notification:info', this.$t('import_run_ok', result))
        } else {
          this.$bus.emit('notification:success', this.$t('import_run_ok', result))
          if (this.results.length == 1) {
            this.query = ''
            this.results = null
          }
        }
      })
    }
  }
}

</script>

<style lang="scss" scoped>
@import '/css/mixins';

.app-import-tidal {

  .form {

    .help {
      margin-bottom: 16px;
    }

    button {
      margin-top: 16px;
    }
  }

  .previews {

    margin-top: 32px;
    border-top: 1px solid var(--ghosted-text-color);
    padding-top: 32px;

    .help {
      font-size: 13pt;
      font-style: italic;
      margin-bottom: 32px;
    }

    .preview {

      @include user-select-none;
      margin-bottom: 48px;
      clear: both;

      .actions {
        float: right;

        button {
          padding: 4px 8px;
          margin-bottom: 8px;
        }
      }

      .cover {
        float: left;
        width: 128px;
        height: 128px;
        border-radius: 8px;
      }

      .details {
        margin-left: 160px;
      }

      .title {
        font-size: 14pt;
        font-weight: bold;
      }

      .artist, .year {
        font-size: 12pt;
      }

      .quality {
        margin-top: 8px;
      }
      
    }

  }

}

</style>
