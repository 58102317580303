<template>
  <div>
    <b-input size="sm" type="text" v-model="text" :disabled="disabled" />
    <span>{{ $t('cfg_semicolon_separated') }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data: function() {
    return {
      text: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.text = newValue == null ? '' : newValue.join(';')
      }
    },
    text(newValue) {
      const parsed = newValue.split(';')
      this.$emit('input', parsed)
    }
  }
};
</script>
