
import AudioPlayerRemote  from './remote.js'

export default class extends AudioPlayerRemote {

  type() {
    return 'Sonos'
  }

  actionCommand(path) {
    return `/sonos/${this._options.device.name}/${path}`
  }

  shouldPing() {
    return false
  }
  
}
