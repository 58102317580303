
import slugify from 'slugify'
import utils from '../utils/utils.js'

function getAudioQualityDesc(quality) {
  if (quality == 'hires') return 'Hi-Res'
  if (quality == 'lossless') return 'Lossless'
  if (quality == 'hifi') return 'Hi-Fi'
  if (quality == 'hdaudio') return 'HD Audio'
  if (quality == 'lossy') return 'Lossy'
  if (quality == 'undefined') return '???'
  return ''
}

const separator = '-'
const imageRoot = 'M1'
const audioRoot = 'M2'
const videoRoot = 'M3'

export default {

  separator: separator,

  getMediaType: function(id) {
    if (id == null) return null
    if (id?.startsWith(imageRoot)) return 'image'
    if (id?.startsWith(audioRoot)) return 'audio'
    if (id?.startsWith(videoRoot)) return 'video'
    return null
  },

  getAudioQualityDesc: getAudioQualityDesc,

  getRoots: function() {
    return {
      'image': { id: imageRoot, title: () => 'media_photo', },
      'audio': { id: audioRoot, title: () => 'media_music', },
      'video': { id: videoRoot, title: () => 'media_video', },
    }
  },

  getLeafId: function(id) {
    return id?.split(this.separator)?.pop()
  },

  augment(item) {

    item.isRoot = function() {
      return (this.id?.length == 2)
    },
  
    item.getBrowseUrl = function() {
      if (this.container) {
        return `${window.location.protocol}//${window.location.host}${window.location.pathname}#/browse/${this.id}`
      } else {
        return this.getDownloadUrl(false)
      }
    }
  
    item.getDownloadUrl = function(asAttachment) {
      if (this.container) {
        return `${window.location.protocol}//${window.location.host}/zip?id=${this.id}`
      } else {
        let url = `${window.location.protocol}//${window.location.host}${this.url}`
        if (asAttachment) url  = `${url}&download=1`
        return url
      }
    }

    item.getQualityDesc = function() {
      return getAudioQualityDesc(this.quality)
    }

    item.getFormatDetails = function() {
      if (this.mime_type == 'audio/mpeg') {
        return 'MP3'
      } else if (this.mime_type == 'audio/aac') {
        return 'AAC'
      } else if (this.mime_type == 'audio/x-flac') {
        return 'FLAC'
      } else if (this.mime_type == 'audio/mp4') {
        return 'ALAC'
      } else {
        return null
      }
    }

    item.getQualityDetails = function() {
      if (this.quality == '' || this.quality == 'undefined') {
        return ''
      } else if (this.upnp_url.endsWith('mp3') || this.upnp_url.endsWith('aac')) {
          return `${this.bitrate} kpbs`
      } else if (this.upnp_url.endsWith('m4a') || this.upnp_url.endsWith('flac')) {
        return `${this.bits_per_sample} bits / ${this.sample_rate/1000} kHz`
      } else {
        return ''
      }
    }

    item.getVideoResolution = function() {
      return utils.getVideoResolution(this)
    }

    item.is4K = function() {
      return this.getVideoResolution() == '4K'
    }

    item.getShowTag = function() {
      let re = /S\d\dE\d\d/
      let m = this.title.match(re)
      return m?.length ? m[0] : null
    }

    item.isShowEpisode = function() {
      return this.getShowTag() != null
    }

    item.getShowSeason = function() {
      let tag = this.getShowTag()
      if (tag == null) return -1
      return parseInt(tag.substr(1, 2))
    }

    item.getShowEpisode = function() {
      let tag = this.getShowTag()
      if (tag == null) return -1
      return parseInt(tag.substr(4, 2))
    }

    if (item.id) {
      item.uid = this.getLeafId(item.id)
      if (!utils.isDigit(item.uid[0])) {
        item.uid = item.uid.substr(1)
      }
    }
    
    item.cleanTitle = item.title.replace(item.getShowTag(), '').trim()
    item.slug = slugify(item.title, { lower: true })

    return item

  },

  equal(a, b) {

    // should be same service
    if (a.service != b.service) {
      return false
    }
    
    // normal items
    if (a.service == null) {
      let id1 = a.id.split(separator).pop()
      let id2 = b.id.split(separator).pop()
      return id1 == id2
    }

    // tidal
    if (a.service == 'tidal') {
      let id1 = a.tidal_id || a.extra.item_id || a.extra.tidal_match.track_id;
      let id2 = b.tidal_id || b.extra.item_id || b.extra.tidal_match.track_id;
      return id1 == id2;
    }

    // too bad
    return false

  },

}
