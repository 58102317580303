<template>
  <div class="voice-command" v-if="enabled">
    <div class="voice-command-prompt" :class="promptClass" v-if="transcript">
      {{ transcript }}
    </div>
    <div class="voice-command-button" :class="iconClass" @click="toggleListening">
      <Icon :name="icon" color="white" :size="20" />
    </div>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      enabled: false,
      recognizing: null,
      recording: false,
      transcript: null,
      success: null,
      endTimer: null,
      clearTimer: null,
    }
  },
  computed: {
    icon() {
      return this.recording || (this.success != null) ? 'chat-right-quote-fill' : 'chat-right-quote'
    },
    promptClass() {
      return this.success === false ? 'error' : ''
    },
    iconClass() {
      if (this.success == null) {
        return this.recording ? 'recording' : ''
      } else {
        return this.success ? 'success' : 'error'
      }
    },
  },
  mounted: async function () {

    // check status
    let textHealth = await this.$http.getWs('/command/health')
    this.enabled = textHealth.enabled

    // create a new SpeechRecognition instance
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    this.recognition = new SpeechRecognition()

    // configure
    this.recognition.lang = 'en-US'
    this.recognition.continuous = false
    this.recognition.interimResults = true

    // reset on start
    this.recognition.onstart = () => {
      console.log('Recognition started.')
      clearTimeout(this.clearTimer)
      this.transcript = null
      this.recording = true
    }

    // process intermediate results
    // safari does not call onspeechend automatically
    // so we create a timeout timer after each result
    this.recognition.onresult = (event) => {
      clearTimeout(this.endTimer)
      if (!this.recording) return
      this.transcript = ''
      for (var i=0; i<event.results.length; i++) {
        this.transcript += event.results[i][0].transcript + ' '
      }
      this.transcript = this.transcript.trim().toLowerCase()
      if (this.transcript.length == 0) {
        this.transcript = null
      } else {
        this.endTimer = setTimeout(() => {
          this.recognition.stop()
        }, 500)
      }
    }

    // we have something!
    this.recognition.onspeechend = () => {
      console.log('Recognition ended.')
      clearTimeout(this.endTimer)
      if (this.transcript != null) {
        console.log(`Recognized: ${this.transcript}`)
        this.$http.request({
          method: 'POST',
          url: `/command/do/${this.transcript}`,
        }).then((_) => this.success = true)
        .catch((_) => this.success = false)
      }
    }

    // errors
    this.recognition.onnomatch = (event) => {
      console.log('Recognition no match.')
      clearTimeout(this.endTimer)
      this.recognition.stop()
    }

    // done
    this.recognition.onend = () => {
      console.log('Recognition stopped.')
      this.recording = false
      this.clearTimeout = setTimeout(() => {
        this.transcript = null
        this.success = null
      }, 2500)
    }
  },
  methods: {
    toggleListening() {
      if (!this.recording) {
        this.recognition.start()
      } else {
        this.recognition.abort()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.voice-command {
  display: flex;
  flex-direction: row;
  align-items: center;

  .voice-command-prompt {
    background-color: rgba(0, 0, 0, 0.2);
    height: 56px;
    margin-right: 16px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    &.success {
      color: var(--success-color);
    }
    &.error {
      color: var(--danger-color);
    }
  }
  .voice-command-button {
    background-color: rgba(0, 0, 0, 0.2);
    //border: 1px var(--highlight-color) solid;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
      font-size: 18pt !important;
      color: var(--highlight-color) !important
    }

    &.recording {
      i {
        font-size: 22pt !important;
      }
    }

    &.success {
      i {
        color: var(--success-color) !important;
      }
    }
    &.error {
      i {
        color: var(--danger-color) !important;
      }
    }


  }
}
</style>