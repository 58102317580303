<template>
  <div class="maintenance" v-if="config">
    <div class="action">
      <div class="buttons">
        <b-button @click="run('/control/restart')" variant="primary"><img alt="" src="../../icons/refresh.png" /><span>{{ $t('cfg_restart_server') }}</span></b-button>
      </div>
      <div class="description">{{ $t('cfg_restart_server_desc') }}</div>
    </div>
    <div class="action">
      <div class="buttons">
        <b-button @click="run('/control/stop', true)" variant="primary"><img src="../../icons/stop.png" /><span>{{ $t('cfg_shutdown_server') }}</span></b-button>
      </div>
      <div class="description">{{ $t('cfg_shutdown_server_desc') }}</div>
    </div>
    <div class="action">
      <div class="buttons">
        <b-button @click="run('/control/scan')" variant="primary"><img src="../../icons/folder_magnify.png" /><span>{{ $t('cfg_run_scan') }}</span></b-button>
      </div>
      <div class="description">{{ $t('cfg_run_scan_desc') }}</div>
    </div>
    <div class="action">
      <div class="buttons">
        <b-button @click="run('/control/rescan/audio')" variant="primary"><img src="../../icons/video_rescan.png" /><span>{{ $t('cfg_run_rescan_audio') }}</span></b-button>
      </div>
      <div class="description">{{ $t('cfg_run_rescan_audio_desc') }}</div>
    </div>
    <div class="action">
      <div class="buttons">
        <b-button @click="run('/control/rescan/video')" variant="primary"><img src="../../icons/video_rescan.png" /><span>{{ $t('cfg_run_rescan_video') }}</span></b-button>
      </div>
      <div class="description">{{ $t('cfg_run_rescan_video_desc') }}</div>
    </div>
    <div class="action">
      <div class="buttons">
        <b-button id="delete_thumbs" @click="run(`/control/db/thumbnail/delete?del_user_thumbs=${delete_user_thumbs?1:0}`, true)" variant="primary"><img src="../../icons/image_delete.png" /><span>{{ $t('cfg_delete_thumbs') }}</span></b-button>
      </div>
      <div class="description">
        <div><span v-html="$t('cfg_delete_thumbs_desc')" /></div>
        <div><br/><b-checkbox v-model="delete_user_thumbs" /> <span>{{ $t('cfg_delete_user_thumbs') }}</span></div>
      </div>
    </div>
    <div class="action">
      <div class="buttons">
        <b-button @click="run('/control/db/media/delete', true)" variant="primary"><img src="../../icons/bin.png" /><span>{{ $t('cfg_delete_media') }}</span></b-button>
      </div>
      <div class="description"><span v-html="$t('cfg_delete_media_desc')" /></div>
    </div>
    <div class="action">
      <div class="buttons">
        <b-button @click="run('/utils/check_update')" variant="primary"><img src="../../icons/update_check.png" /><span>{{ $t('cfg_check_update') }}</span></b-button>
      </div>
      <div class="description">{{ $t('cfg_check_update_desc') }}</div>
    </div>
    <div class="action">
      <div class="buttons">
        <b-button @click="view_log" variant="primary"><img src="../../icons/view_logs.png" /><span>{{ $t('cfg_get_logs') }}</span></b-button>
      </div>
      <div class="description" v-if="config">
        <span>{{ $t('cfg_set_log_level') }}</span>
        <b-select class="log-levels" size="sm" v-model="config.general.logging.level" @change="setlog">
          <option value="fatal">Fatal</option>
          <option value="error">Error</option>
          <option value="warn">Warn</option>
          <option value="info">Info</option>
          <option value="verbose">Verbose</option>
          <option value="debug">Debug</option>
        </b-select>
      </div>
      <b-modal v-model="view" modal-class="log-viewer" size="xl" :hide-header=true :ok-only=true >
        <div class="log"><div>{{ log }}</div></div>
      </b-modal>
    </div>
  </div>
</template>

<script>

import Config from '../../js/model/config.js'

export default {
  data: function() {
    return {
      config: null,
      delete_user_thumbs: false,
      view: false,
      log: null,
    }
  },
  methods: {
    async refresh() {
      let config = new Config({ http: this.$http })
      this.config = await config.get()
    },
    async setlog(level) {
      try {
        let config = new Config({ http: this.$http })
        var json = { general: { logging: { level: level } } }
        await config.submit(json)
        this.$bus.emit('notification:success', 'exec_ok')
      } catch (err) {
        console.error(err)
        this.$bus.emit('notification:error', 'exec_ko')
      }
    },
    async run(cmd, confirm) {
      let confirmed = true
      if (confirm) {
        confirmed = await this.$bvModal.msgBoxConfirm(this.$i18n.t('cfg_confirm'), {
          okVariant: 'danger',
          okTitle: 'Yes',
        })
      }
      if (confirmed) {
        this.$http.get(`${cmd}`)
          .then(() => this.$bus.emit('notification:success', 'exec_ok'))
          .catch(() => this.$bus.emit('notification:error', 'exec_ko'))
      }
    },
    async view_log() {
      this.log = await this.$http.getWs('/utils/log')
      this.view = true
    },
  }

}

</script>

<style scoped>

.maintenance .action {
  display: flex;
  flex-wrap: wrap;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid var(--ghosted-text-color);
}

.maintenance .action:last-child {
  border-bottom: 1px solid var(--ghosted-text-color);
}

.maintenance .action .buttons {
  flex: 0 0 40%;
  align-self: center;
  text-align: inherit;
  margin: 0px;
  padding: 0px;
}

.maintenance .action .buttons .btn {
  width: 260px;
  margin: 12px 0px 12px 16px;
  padding-left: 12px;
  text-align: left;
}

.maintenance .action .description {
  flex: 0 0 60%;
  align-self: center;
}

.maintenance .log-levels {
  width: 200px;
  display: inline;
  margin-left: 8px;
}

.log-viewer .log {
  background-color: black;
  height: 600px;
  overflow-y: scroll;
  padding: 8px;
}

.log-viewer .log div {
  overflow-x: scroll;
  white-space: pre;
  font-family: monospace;
  font-size: 10pt;
  color: #4af626;
}

</style>
