<template>
  <div class="bar" @click="set" :class="barClass" :draggable="draggable" @dragstart="dragstart" @drag="drag" @dragend="dragend">
    <div class="slider">
      <div class="done" :style="doneStyle"></div>
      <div class="left" :style="leftStyle"></div>
    </div>
    <div class="thumb-container">
      <div class="thumb" :class="thumbClass" :style="thumbStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    enabled: Boolean,
    value: Number,
    callback: Function,
    thumbHidden: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    continuous: {
      type: Boolean,
      default: true,
    }
  },
  data: function() {
    return {
      dragging: false,
      dragBarWidth: null,
      dragStartValue: null,
      dragStartPos: null,
      dragLastPos: null,
      dragValue: null,
      dragPauseTime: null,
      dragPauseValue: null,
      dragLastNotify: null,
    }
  },
  computed: {
    position() {
      return this.dragValue || this.value
    },
    progress() {
      return this.position ? Math.max(0, Math.min(100, this.position * 100)) : 0
    },
    remaining() {
      return 100 - this.progress
    },
    barClass() {
      return { 'dragged': this.dragging, }
    },
    thumbClass() {
      return { 'visible': !this.thumbHidden }
    },
    thumbStyle() {
      if (!this.enabled || this.position == null) {
        return { 'display': 'none' }
      } else {
        return { 'margin-left': this.progress + '%' }
      }
    },
    doneStyle() {
      return { 'width': this.progress + '%' }
    },
    leftStyle() {
      return { 'left': this.progress + '%', 'width': this.remaining + '%' }
    }
  },
  methods: {
    set(ev) {
      if (!this.enabled) return
      this.callback(ev.offsetX / ev.target.offsetWidth)
    },
    dragstart(ev) {
      let img = document.getElementById('thumb-ghost')
      if (img == null) {
        img = new Image()
        img.id = 'thumb-ghost'
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='
        document.body.appendChild(img)
      }
      ev.dataTransfer.setDragImage(img, 0, 0)
      this.dragStartPos = ev.clientX
      this.dragStartValue = parseFloat(this.value)
      this.dragBarWidth = ev.target.closest('.bar').offsetWidth
      this.dragLastPos = ev.clientX
      this.dragValue = this.dragStartValue
      this.dragLastNotify = null
      this.dragging = true
      this.$forceUpdate()
    },
    drag(ev) {

      // if continuous check for pauses
      let notify = this.continuous
      if (notify == false && this.dragLastPos == ev.clientX) {                      // if we have not moved
        if (this.dragLastPos == this.dragPauseValue) {                              // if we are at same position
          if (this.dragPauseTime != 0 && Date.now() - this.dragPauseTime > 250) {   // for long enough
            notify = true                                                           // then force notification
            this.dragPauseTime = 0                                                  // but only once
          }
        } else {
          this.dragPauseValue = ev.clientX                                          // else record pause position
          this.dragPauseTime = Date.now()                                           // and start time
        }
      }

      // do it
      this.process(ev, notify)                                                     // process with new value

    },
    dragend(ev) {
      this.process(ev, true)                                                        // process always (fast release in non-continuous)
      this.dragValue = null                                                         // let offical value take precedence again
      this.dragging = false                                                         // and stop dragging
      this.$forceUpdate()                                                           // force update
    },
    process(ev, notify) {
      if (ev.pageX != 0 && ev.pageY != 0) {                                                            // some events are totally off...
        if (notify || ev.clientX != this.dragLastPos) {                                                // if forced to notify or position changed
          this.dragLastPos = ev.clientX                                                                // record last position
          this.dragValue = this.dragStartValue + (ev.clientX - this.dragStartPos) / this.dragBarWidth  // and calc new value
          if (notify && this.dragValue != this.dragLastNotify) {                                       // check if last notification is same
            this.callback(Math.max(0.0, Math.min(1.0, this.dragValue)))                                // notify the new value
            this.dragLastNotify = this.dragValue                                                       // and record it
          }
        }
      }
    }
  },

}
</script>

<style lang="scss" scoped>

.bar {
  
  --height: 16px;
  height: var(--height);
  padding-top: calc(var(--height)/2);
  display: inline-block;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }

  .slider {
    height: 2px;
    border-radius: 1px;
    //background-color: var(--disabled-text-color);
    position: relative;
    top: -1px;

    .done {
      display: inline-block;
      position: absolute;
      width: 0%;
      left: 0;
      height: 100%;
      background-color: white;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
  }

    .left {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0%;
      width: 100%;
      height: 100%;
      background-color: var(--disabled-text-color);
    }
  }

  .thumb-container {
    position: relative;
    top: -6px;
    --thumb-width: 4px;
    --thumb-height: 8px;
    padding-right: var(--thumb-size);
    // background-color: red;

    .thumb {
      position: relative;
      width: var(--thumb-width);
      height: var(--thumb-height);
      border-radius: calc(var(--thumb-width) / 2);
      background-color: white;
      visibility: hidden;

      &.dragged, &:hover {
        cursor: pointer;
        --thumb-zoomed-scale: 1.2;
        width: calc(var(--thumb-width) * var(--thumb-zoomed-scale));
        height: calc(var(--thumb-height) * var(--thumb-zoomed-scale));
        border-radius: calc(var(--thumb-width) * var(--thumb-zoomed-scale) / 2);
        top: -1px;
      }

    }

  }

  &:hover .thumb {
    visibility: visible;
  }

}

</style>
