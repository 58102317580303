<template>
  <div class="dancing-bars">
    <div class="bar bar-1"></div>
    <div class="bar bar-2"></div>
    <div class="bar bar-3"></div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>

.dancing-bars {
  height: 12px;
  position: relative;
}

.dancing-bars .bar {
  height: 1px;
  width: 4px;
  display: inline-block;
  background-color: var(--highlight-color);
  bottom: 0;
  position: absolute;

  animation-play-state: running;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.dancing-bars .bar.bar-1 {
  left: 0;
  animation-name: danceHeight1;
  animation-duration: 250ms;
}

.dancing-bars .bar.bar-2 {
  left: 6px;
  animation-name: danceHeight2;
  animation-duration: 350ms;
}

.dancing-bars .bar.bar-3 {
  left: 12px;
  animation-name: danceHeight3;
  animation-duration: 300ms;
}

@keyframes danceHeight1 {
  from { height: 1px; }
  to { height: 9px; }
}

@keyframes danceHeight2 {
  from { height: 1px; }
  to { height: 6px; }
}

@keyframes danceHeight3 {
  from { height: 1px; }
  to { height: 12px; }
}

</style>