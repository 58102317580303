
import AudioPlayerRemote  from './remote.js'

export default class extends AudioPlayerRemote {

  type() {
    return 'Pod'
  }

  actionCommand(action) {
    return `/player/${action}?uuid=${this._options.device.uuid}`
  }
  
  delayBeforeSwitchToSameNameDevice() {
    return 3000;
  }
}
