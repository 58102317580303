<template>
  <div class="app-search-results">
    <div class="section" v-if="albums.length">
      <div class="title">{{ $t('browser_section_albums') }}</div>
      <LibraryItemList :items="albums" />
    </div>
    <div class="section" v-if="tracks.length">
      <div class="title">{{ $t('browser_section_tracks') }}</div>
      <LibraryItemList :items="tracks" />
    </div>
    <div class="no-results" v-if="tracks.length == 0 && albums.length == 0">
      <Icon name="search" size="48" />
      <div class="main">{{ $t('search_results_none') }}</div>
      <div class="sub">{{ $t('try_again')  }}</div>
    </div>
  </div>
</template>

<script>

import media from '../../js/model/media.js'
import LibraryItemList from './LibraryItemList.vue'

export default {
  components: { LibraryItemList, },
  data: function() {
    return {
      results: null,
    }
  },
  computed: {
    tracks() {
      return this.results?.tracks || []
    },
    albums() {
      return this.results?.albums || []
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$bus.emit('search:clear')
    next()
  },
  created: async function() {
    let query = this.$route.params.query
    if (this.$store.state.searchResults?.query == query) {
      this.results = this.$store.state.searchResults.results
    }
    if (this.results == null) {
      this.results = await this.$http.search(query, this.$store)
      this.prepare()
    }
    this.$bus.emit('search:set', query)
  },
  mounted: async function() {
    this.prepare()
  },
  methods: {
    prepare() {
      if (this.results != null) {
        for (let album of this.results.albums) {
          album = media.augment(album)
        }
        for (let track of this.results.tracks) {
          track = media.augment(track)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.app-search-results {

  .section {
    margin-bottom: var(--library-item-space);
    .title {
      text-transform: uppercase;
      font-size: 14pt;
      font-weight: bold;
      color: var(--text-color);
    }

  }
  .no-results {
    text-align: center;
    margin-top: 96px;
    font-size: 12pt;
    .main {
      margin-top: 16px;
      font-weight: bold;
    }
    .sub {
      font-weight: 300;
    }
  }

}

</style>
