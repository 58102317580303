<template>
  <div class="status" v-if="status">
    <div class="group">
      <div class="section">{{ $t('cfg_status') }}</div>
      <div class="field">
        <div class="label">{{ $t('cfg_version') }}</div>
        <div class="value">{{ status ? status.version : '' }}</div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_uptime') }}</div>
        <div class="value">{{ status ? status.uptime : '' }}</div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_ip_address') }}</div>
        <div class="value">{{ status ? status.local_ip : '' }}</div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_media_scan') }}</div>
        <div class="value">{{ status ? status.crawling_status : '' }}</div>
      </div>
    </div>
    <div class="group" id="browser_url" v-if="status && status.browsing_enabled">
      <div class="section">{{ $t('cfg_browser_url') }}</div>
      <div class="field">
        <div class="label">{{ $t('cfg_internal_url') }}</div>
        <div class="value"><span v-html="internal_url" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_external_url') }}</div>
        <div class="value"><div v-html="external_url" />
          <div id="port_buttons" class="buttons" style="float:left; margin-top: 8px; margin-bottom: 8px;">
            <!--b-button size="sm" @click="port_open" variant="primary"><img src="../../icons/port_open.png"><span>{{ $t('cfg_port_open') }}</span></b-button-->
            <b-button size="sm" @click="port_test" variant="primary"><img src="../../icons/port_test.png"><span>{{ $t('cfg_port_test') }}</span></b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="group">
      <div class="section">{{ $t('cfg_database') }}</div>
      <div class="field">
        <div class="label">{{ $t('cfg_filesize') }}</div>
        <div class="value">{{ status ? status.db_size_kb : '' }}</div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_photo_items') }}</div>
        <div class="value">{{ status ? status.photo_count : '' }}</div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_music_items') }}</div>
        <div class="value">{{ status ? status.music_count : '' }}</div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_video_items') }}</div>
        <div class="value">{{ status ? status.video_count : '' }}</div>
      </div>
      <!--div class="field">
        <div class="label">{{ $t('cfg_thumbnails') }}</div>
        <div class="value">{{ status ? status.thumb_count : '' }}</div>
      </div-->
    </div>
    <div class="buttons">
      <b-button id="refresh" variant="primary" @click="refresh"><img alt="" src="../../icons/refresh.png" /><span>{{ $t('refresh') }}</span></b-button>
    </div>
  </div>  
</template>

<script>

export default {

  data: function() {
    return {
      status: null,
      external_ip: null,
    }
  },

  computed: {
    internal_url() {
      return this.get_browser_link(this.status?.local_ip)
    },
    external_url() {
      return this.get_browser_link(this.external_ip)
    },
  },

  methods: {

    async refresh() {

      // status
      this.status = await this.$http.getWs('/control/status')

      // url
      if (this.status.browsing_enabled) {
        let data = await this.$http.getWs('/utils/external_ip')
        this.external_ip = data.external_ip
      }

    },

    async port_test() {
      window.open(`http://www.nabocorp.com/mediastation/testport.php?url=${this.get_url(this.external_ip)}`)
    },

    async port_open() {
      //TODO
      /*if (confirm(g_labels['cfg_port_open_confirm'])) {
        on_tab_exec()
        $.ajax({
          url: '/utils/port/open?port='+$('#config_port').text(),
          success: function() { show_confirm(g_labels['cfg_port_open_ok']); on_tab_execed(); },
          error: function() { show_error(g_labels['cfg_port_open_ko']); on_tab_execed(); }
        })
      }*/
    },

    get_url(ip) {
      let port = parseInt(this.status.config_port)
      return `http://${ip}${port != 0 && port != 80 ? ':' + port : ''}`
    },

    get_browser_link(ip) {
      if (ip?.length == null) return ''
      var url = this.get_url(ip)
      return `<a href="${url}" target="_blank">${url}/</a>`
    }

  }

}


</script>

<style scoped>

</style>
