<template>
  <div>
    <div class="pill" :class="translated">
      <span class="label">{{ translated }}</span>
    </div>
  </div>
</template>

<script>

import utils from '../../js/utils/utils.js'

export default {
  props: [ 'quality' ],
  data: function() {
    return {
    }
  },
  computed: {
    translated() {
      return utils.getTidalQualityDesc(this.quality)
    },

  },
  mounted: function() {
  },
  methods: {
  }
}

</script>

<style lang="scss" scoped>

.pill {

  display: inline-block;
  background-color: #3F3F43;
  border-radius: 2px;
  padding: 0px 8px 4px 8px;
  font-stretch: expanded;
  letter-spacing: 1px;

  .label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 8pt;
    color: #FFFFFF;
  }

  &.max {
    background-color: #3A362D;
    .label {
      color: #F8D656;
    }
  }

  &.high {
    background-color: #283B3E;
    .label {
      color: #34FFEE;
    }
  }


}

</style>
