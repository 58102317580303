<template>
  <div class="music-album" @mousedown="hidePopovers">
    <div class="album-info" v-if="album">
      <!--Hero :tag="album.id"-->
        <Icon class="cover" name="music-note-list" :size=128 color="var(--disabled-text-color)" v-if="coverIcon" />
        <img class="cover" :src="cover" @error="coverError = true" v-else />
      <!--/Hero-->
      <div class="info-bar">
        <div class="misc-info">
          <div v-if="isTidal() || isMatchedInTidal" class="tidal">
            <img :src="'/icons/tidal.png'" />
            <Icon v-if="isMatchedInTidal" name="link-45deg" :size="12" color="white"/>
          </div>
        </div>
        <div id="menu-album" class="menu-album">
          <Icon class="cursor-pointer" name="three-dots" :size=10 />
          <b-popover target="menu-album" triggers="hover" placement="bottom" custom-class="menu">
            <div class="disabled">{{ $t('album_menu_title') }}</div>
            <div v-if="isTidal()" @click="removeFromLibrary()">{{ $t('album_menu_remove_from_library') }}</div>
            <div v-if="canMatchInTidal && !isMatchedInTidal" @click="matchInTidal()">{{ $t('album_menu_tidal_match') }}</div>
            <div v-if="canMatchInTidal &&  isMatchedInTidal" @click="unmatchInTidal()">{{ $t('album_menu_tidal_unmatch') }}</div>
          </b-popover>
        </div>
      </div>
      <div class="title">{{ title }}</div>
      <div>
        <span class="artist"><a @click="goToArtist(artist)">{{ artist }}</a></span>
        <span class="year" v-if="year"><a @click="goToYear(year)">{{ year }}</a></span>
      </div>
      <div class="quality" v-if="albumAudioQuality != ''">
        <span class="icon"><img :src="`/icons/audio_${albumAudioQuality}.png`" /></span>
        <span class="text">{{ audioQualityDesc }}</span>
      </div>
    </div>
    <table class="album-tracks" v-if="tracks">
      <div v-for="(track, index) in tracks" :key="track.id">
        <tr v-if="!playlist && isNewVolume(index)">
          <td colspan="99" class="volume">
            {{ $t('album_volume', { volume: track.volume }) }}
          </td>
        </tr>
        <tr class="track" :class="{ active: isCurrent(track) }" :id="track.id">
          <td class="bars"><DancingBars v-if="isCurrent(track)" /></td>
          <td class="index">{{ playlist ? index+1 : (track.index == 0 ? '' : track.index) }}</td>
          <td class="title" @click="play(index)">
            {{ track.title }}<span class="artist" v-if="compilation"> - 
              <a @click="goToArtist(track.artist)">{{ track.artist }}</a>
            </span>
          </td>
          <td class="menu"><Icon class="cursor-pointer" :id="menuIconId(track)" name="three-dots" :size=12 /></td>
          <td class="duration">{{ track.duration_fmt }}</td>
          <b-popover :id="tidalPopoverId(track)" :target="track.id" triggers="manual" placement="top" custom-class="external-open" :container="track.id">
            <b-button size="sm" @click="openWeb(track)">{{ $t('external_open_web') }}</b-button>
            <b-button size="sm" @click="openApp(track)">{{ $t('external_open_app') }}</b-button>
          </b-popover>
          <b-popover :id="menuPopoverId(track)" :target="menuIconId(track)" triggers="hover" custom-class="menu" @shown="loadPlaylists() ; forceMenuIcon(track)" @hidden="hideMenuIcon">
            <div class="disabled">
              <!--{{ $t('browser_info_genre') }}{{ $t('colon') }}{{ track.genre }}<br/>-->
              {{ $t('browser_info_quality') }}{{ $t('colon') }}{{ track.getQualityDesc() }}
              <span v-if="track.getFormatDetails()"><br/>{{ $t('browser_info_format') }}{{ $t('colon') }}{{ track.getFormatDetails() }}</span>
              <span v-if="track.getQualityDetails()"><br/>{{ track.getQualityDetails() }}</span>
            </div>
            <div @click="playNext(track)">{{ $t('browser_menu_play_next') }}</div>
            <div @click="enqueue(track)">{{ $t('browser_menu_enqueue') }}</div>
            <div :id="addToPlaylistId(track)" v-if="playlists && playlists.length">Add to playlist</div>
            <div @click="download(track)">{{ $t('browser_menu_download') }}</div>
            <div @click="copyLink(track)">{{ $t('browser_menu_copy_link') }}</div>
            <b-popover :target="addToPlaylistId(track)" triggers="hover" custom-class="menu no-arrow" :container="menuPopoverId(track)">
              <div v-for="playlist in playlists" :key="playlist.id" @click="addToPlaylist(playlist.id, track)">
                {{ playlist.title }}
              </div>
            </b-popover>
          </b-popover>
        </tr>
      </div>
    </table>
    <b-modal id="pick-match" v-model="pickMatch" modal-class="pick-match-modal" :title="matchPickDialogTitle" :okOnly="true" okVariant="secondary" :okTitle="$t('cancel')">
      <table>
        <tr v-for="candidate in candidates" :key="candidate.album_id" class="match-candidate" @click="associateInTidal(candidate)">
          <td>{{ candidate.title }}</td>
          <td>{{ candidate.date.substr(0, 4) }}</td>
          <td>{{ candidate.quality.toUpperCase() }}</td>
          <td>{{ $tc('import_tracks_count', candidate.track_count, { count: candidate.track_count } ) }}</td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>

import DancingBars from '../components/DancingBars.vue'
import media from '../../js/model/media.js'
import utils from '../../js/utils/utils.js'

export default {
  props: [ 'id', 'item' ],
  components: { DancingBars, },
  data: function() {
    return {
      album: null,
      tracks: [],
      currentTrack: null,
      coverError: false,
      playlists: null,
      pickMatch: false,
      candidates: null,
    }
  },
  computed: {
    canMatchInTidal() {
      return this.album.type == 'malbum' && this.isTidal() == false
    },
    isMatchedInTidal() {
      return this.tracks.length > 0 && this.tracks[0].extra?.tidal_match != null
    },
    playlist() {
      return this.$route.name == 'playlist'
    },
    compilation() {
      let artist = this.tracks[0]?.artist
      for (const track of this.tracks) {
        if (track.artist != artist) {
          return true
        }
      }
      return false
    },
    coverIcon() {
      return this.coverError
    },
    cover() {
      return this.album?.thumbnail
    },
    title() {
      return this.album?.title
    },
    artist() {
      return this.playlist ? '' : this.compilation ? this.$t('compilation_artist') : this.tracks[0]?.artist
    },
    year() {
      if (this.playlist) return null
      let year = this.tracks[0]?.year
      for (const track of this.tracks) {
        if (track.year != year) {
          return null
        }
      }
      return year
    },
    albumAudioQuality() {
      var all_of_quality = (quality) => {
        for (const track of this.tracks) {
          if (track.quality != quality) {
            return false
          }
        }
        return true
      }
      for (let quality of ['hires', 'lossless', 'hifi', 'hdaudio']) {
        if (all_of_quality(quality)) return quality
      }
      return ''
    },
    audioQualityDesc() {
      return media.getAudioQualityDesc(this.albumAudioQuality)
    },
    matchPickDialogTitle() {
      return `${this.album?.title} / ${this.tracks?.[0]?.date?.substr(0,4)} / ${this.$tc('import_tracks_count', this.tracks?.length, { count: this.tracks?.length })}`
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('pop_breadcrumb')
    next()
  },
  created: async function() {
    this.album = this.item
    await this.load()
    this.$store.commit('add_breadcrumb', this.album)
  },
  mounted: async function() {
    this.$audio.on('status', (status) => {
      this.currentTrack = status?.playing ? status?.track() : null
    })
  },
  methods: {

    async load() {

      // if we have no id it means we come from a search result
      if (this.$route.name == 'album_by_artist') {

        // get params
        let artist = this.$route.params.artist
        let album = this.$route.params.album

        // load tracks specifically
        this.tracks = await this.$http.getAlbumTracks(artist, album)
        this.tracks = this.tracks.map((item) => media.augment(item))

        // now fill album
        this.album = this.item
        if (this.album == null) {
          this.album = JSON.parse(JSON.stringify(this.tracks[0]))
          this.album.type = 'malbum'
          this.album.title = this.album.album
          this.album = media.augment(this.album)
        }

      } else if (this.$route.name == 'album_by_id' || this.$route.name == 'playlist') {
      
        // first let's make sure we have an album
        if (this.album == null) {
          this.album = await this.$http.getItem(this.id)
        }

        // load tracks
        if (this.$route.name == 'playlist') {
          let playlist = await this.$http.getPlaylist(this.id)
          this.tracks = playlist.tracks
        } else {
          this.tracks = await this.$http.getItems(this.id)
        }

      }
      
      // load playlists
      this.loadPlaylists()

    },

    async loadPlaylists() {
      this.playlists = await this.$http.getWs(`/playlists`)
    },
    
    menuIconId(track) {
      return `track-menu-icon-${track.id}`
    },
    
    menuPopoverId(track) {
      return `menu-popover-${track.id}`
    },
    
    tidalPopoverId(track) {
      return `tidal-popover-${track.id}`
    },
    
    addToPlaylistId(track) {
      return `track-menu-add-to-${track.id}`
    },

    isSingleVolume() {
      let volume = this.tracks[0].volume
      for (const track of this.tracks) {
        if (track.volume != volume) return false;
      }
      return true;
    },

    isNewVolume(index) {
      if (this.isSingleVolume()) return false;
      if (index == 0) return true;
      return this.tracks[index].volume != this.tracks[index-1].volume;
    },
    
    isCurrent(track) {
      
      // need a current track
      if (this.currentTrack == null) {
        return false
      }
      
      // tidal can have two cases: real or mapped
      if (this.currentTrack.service == 'tidal' && this.currentTrack.tidal_id != null) {
        if (track.extra?.item_id == this.currentTrack.tidal_id) return true
        if (track.extra?.tidal_match?.track_id == this.currentTrack.tidal_id) return true
      }

      // default
      return media.getLeafId(track.id) == media.getLeafId(this.currentTrack.id)

    },
    
    isTidal() {
      for (const track of this.tracks) {
        if (track.service == 'tidal') {
          return true
        }
      }
      return false
    },

    goToArtist(artist) {
      this.$router.push({ name: 'artist_albums', params: { artist: artist }})
    },

    goToYear(year) {
      this.$router.push({ name: 'year_albums', params: { year: year }})
    },

    play(index) {
      let track = this.tracks[index] 
      if (track.service =='tidal' && utils.getPlaybackDevice().type == utils.getLocalPlaybackDevice().type) {
        this.$root.$emit('bv::hide::popover')
        this.$root.$emit('bv::show::popover', this.tidalPopoverId(track))
      } else {
        this.$audio.play({
          id: this.playlist ? this.album.id : null,
          title: this.playlist ? this.album.title : null, // album.title would be the title of the playlist...
          tracks: this.tracks,
          start: index,
        })
      }
    },
    
    forceMenuIcon(track) {
      document.getElementById(this.menuIconId(track)).closest('.track').classList.add('hover')
    },
    
    hideMenuIcon() {
      [].forEach.call(document.querySelectorAll('.track.hover'), function(el) {
        el.classList.remove('hover')
      })
    },
    
    playNext(track) {
      this.$root.$emit('bv::hide::popover', this.menuIconId(track))
      this.$audio.playNext(track)
    },
    
    enqueue(track) {
      this.$root.$emit('bv::hide::popover', this.menuIconId(track))
      this.$audio.enqueue(track)
    },
    
    async addToPlaylist(playlist_id, track) {
      this.$root.$emit('bv::hide::popover', this.menuIconId(track))
      this.$bus.emit('playlist:append', { id: playlist_id, track: track })
    },
    
    async copyLink(track) {
      this.$root.$emit('bv::hide::popover', this.menuIconId(track))
      if (await utils.copyLinkToItem(track)) {
        this.$bus.emit('notification:success', 'browser_url_copied_ok')
      }
    },
    
    download(track) {
      this.$root.$emit('bv::hide::popover', this.menuIconId(track))
      window.location.href = track.getDownloadUrl(true)
    },

    async matchInTidal() {
      this.$root.$emit('bv::hide::popover')
      this.$http.get(`/tidal/match?id=${this.album.id}`).then((response) => {
        this.$bus.emit('notification:success', 'tidal_match_ok')
        this.tracks = response.data.map((item) => media.augment(item))
      }).catch((err) => {
        if (err.response.status == 300) {
          this.candidates = err.response.data.slice(0, 25).map((c) => {
            return {
              ...c,
              quality: utils.getTidalQualityDesc(c.quality)
            }
          })
          this.pickMatch = true
        } else {
          this.$bus.emit('notification:error', 'tidal_match_ko')
        }
      })
    },

    async associateInTidal(candidate) {
      this.$root.$emit('bv::hide::modal', 'pick-match')
      this.$http.get(`/tidal/match?id=${this.album.id}&artist_id=${candidate.artist_id}&album_id=${candidate.album_id}`).then((response) => {
        this.$bus.emit('notification:success', 'tidal_match_ok')
        this.tracks = response.data.map((item) => media.augment(item))
      }).catch((_) => {
        this.$bus.emit('notification:error', 'tidal_match_ko')
      })
    },
    
    async unmatchInTidal() {
      this.$http.get(`/tidal/unmatch?id=${this.album.id}`).then((response) => {
        this.$bus.emit('notification:success', 'tidal_unmatch_ok')
        this.tracks = response.data.map((item) => media.augment(item))
      }).catch((_) => {
        this.$bus.emit('notification:error', 'tidal_unmatch_ko')
      })
    },
    
    removeFromLibrary() {
      this.$root.$emit('bv::hide::popover')
      this.$bvModal.msgBoxConfirm(this.$t('remove_from_library_confirm'), {
          //centered: true,
          okVariant: 'danger',
        }).then((value) => {
          if (!value) return
          let ids = this.tracks.filter((t) => t.service != null).map((t) => t.extra.item_id)
          this.$http.post('/tidal/delete', JSON.stringify({ items: ids })).then(() => {
            this.$router.go(-1);            
          })
        })
    },
    
    openWeb(track) {
      if (track.service == 'tidal') {
        this.openWith('https://listen.tidal.com/', track.extra.url)
      }
    },
    
    openApp(track) {
      if (track.service == 'tidal') {
        this.openWith('tidal://', track.extra.url)
      }
    },
    
    openWith(prefix, base_url) {
      this.$root.$emit('bv::hide::popover')
      let tokens = base_url.split('/')
      let url = prefix + tokens.slice(3).join('/')
      if (url.startsWith('http')) window.open(url)
      else window.location = url
    },

    hidePopovers() {
      this.$root.$emit('bv::hide::popover')
    }

  }
}

</script>

<style lang="scss" scoped>
@import '/css/mixins';

.music-album {

  padding-bottom: 32px;
  
  .popover.external-open {
    max-width: none;
  }

  .album-info {
    text-align: center;
    margin-top: 64px;
    
    .cover {
      width: 300px;
      height: 300px;
      object-fit: cover;
      margin-bottom: 4px;
      border-radius: 4px;
    }

    .info-bar {
      width: 300px;
      margin: 0 auto;
      margin-bottom: 16px;
      display: flex;

      .misc-info {
        flex: 50%;
        text-align: left;

        .tidal {

          img {
            height: 10px;
            opacity: var(--faded-opacity);
          }

          .icon {
            margin-right: 4px;
            opacity: var(--faded-opacity);
          }

        }

      }

      .menu-album {
        flex: 50%;
        display: inline;
        text-align: right;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        i {
          opacity: var(--disabled-opacity);
          position: relative;
          top: -4px;
        }
        &:hover {
          border-color: var(--faded-text-color);
          i {
            opacity: var(--faded-opacity);
          }
        }
      }

    }
    
    .title {
      font-size: 18pt;
      font-weight: 900;
      margin-bottom: 8px;
      color: var(--text-color);
    }

    .artist {
      color: var(--faded-text-color);
      font-size: 13pt;
    }
    
    .year {
      color: var(--faded-text-color);
      font-size: 13pt;
      &::before {
        content: ', ';
      }
    }

    .artist, .year {
      a:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: var(--disabled-text-color);
        text-underline-offset: 3px;
      }
    }
    
    .quality {
      .icon img {
        height: 16px;
        margin-right: 8px;
        opacity: var(--faded-opacity);
      }
      .text {
        color: var(--faded-text-color);
        font-size: 13pt;
      }
    }

  }
  
  .album-tracks {
    margin: 48px auto 0px;

    .volume {
      padding-top: 32px;
      padding-bottom: 16px;
      color: var(--faded-text-color);
      text-transform: uppercase;
    }
  
    .track {
      
      td {
        color: var(--text-color);
        vertical-align: baseline;
        padding: 8px 12px;
        @include user-select-none;
      }

      .bars {
        padding-right: 24px;
      }
      
      .index {
        padding-right: 16px;
        font-variant-numeric: tabular-nums;
        color: var(--faded-text-color);
        text-align: right;
        font-size: 12pt;
        width: 48px;
      }
      
      .title {
        width: 480px;
        max-width: 480px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13pt;
        cursor: pointer;

        .artist {
          color: var(--faded-text-color);
          font-size: 12pt;
          font-weight: 300;
          vertical-align: baseline;
          a:hover {
            cursor: pointer;
            text-decoration: underline;
            text-decoration-color: var(--disabled-text-color);
            text-underline-offset: 3px;
          }
        }
      }
      
      .menu {
        i {
          color: transparent !important;
        }
      }
      
      &:hover, &.hover {
        .menu {
          i {
            color: var(--text-color) !important;
          }
        }
      }
      
      .duration {
        font-variant-numeric: tabular-nums;
        color: var(--faded-text-color);
        font-size: 12pt;
      }
    }		
  }

}

@include media-phone() {
  .music-album {
    padding-right: 0px;
    overflow-x: hidden;
    
    .album-info {
      margin-top: 16px;

      .cover {
        width: 192px;
        height: 192px;
      }
      .title {
        font-size: 14pt;
      }
      .artist, .year {
        font-size: 12pt;
      }
    }

    .album-tracks {
      width: 100%;

      .track {
        td {
          padding: 8px;
        }
        .bars, .menu, .duration {
          display: none;
        }
        .index {
          font-size: 10pt;
          padding-right: 4px;
          padding-left: 0px;
        }
        .title {
          width: auto;
          max-width: none;
          font-size: 12pt;
        }

        &.active {
          .title {
            color: var(--highlight-color);
          }
        }
      }
    }
  }
}

</style>

<style lang="scss">

.pick-match-modal {
  .modal-dialog {
    max-width: 800px;
    table {
      width: 100%;
      .match-candidate {
        cursor: pointer;
        &:hover {
          color: var(--highlight-color);
        }
        td {
          white-space: nowrap;
          max-width: 256px;
          overflow-x: hidden;
        }
      }
    }
  }
}

</style>
