<template>
  <div class="thumbnail-editor">

    <!--
            Lazy initialization of tabs: ThumbnailGallery tabs should have:
              - id set on the b-tab
              - same value set as ref on the ThumbnailGallery component
    -->

    <b-tabs align="center" @activate-tab="activate">
      <b-tab :title="$t('set_thumb_default')" id="default" active>
        <ThumbnailGallery :callbacks="callbacks" :default=true :item="item" ref="default"/>
      </b-tab>
      <b-tab title="TheMovieDb" v-if="item.type=='video'" id="moviedb">
        <ThumbnailGallery :callbacks="callbacks" path="/moviedb/search?query=" :item="item" ref="moviedb"/>
      </b-tab>
      <b-tab title="Last.fm" v-if="item.type=='martist' || item.type=='playlist'" id="lastfm">
        <ThumbnailGallery :callbacks="callbacks" path="/utils/artist/thumbnail/lastfm?artist=" :item="item" :has-exact=true ref="lastfm"/>
      </b-tab>
      <b-tab :title="$t('set_thumb_url')">
        <b-container>
          <b-row>
            <label>{{ $t('set_thumb_url_desc') }}</label>
          </b-row>
          <b-row>
            <b-col cols=10><b-form-input v-model="user_url" /></b-col>
            <b-col cols=2><b-button variant="primary" @click="set">Set</b-button></b-col>
          </b-row>
        </b-container>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

import ThumbnailGallery from './ThumbnailGallery.vue'

export default {
  components: { ThumbnailGallery, },
  props: [ 'item' ],
  data: function() {
    return {
      user_url: '',
    }
  },
  computed: {
    callbacks() {
      return {
        'default': this.default,
        'delete': this.delete,
        'album': this.album,
        'url': this.url,
      }
    }
  },
  mounted: function() {
    this.$refs.default.init()
    this.$store.commit('add_breadcrumb', this.item)
  },
  methods: {

    activate(newTabIndex, _, bvEvent) {
      // get the tab by index in the event and the find it by id in the ref...
      let tab = bvEvent?.vueTarget?.getTabs()?.[newTabIndex]
      let ref = this.$refs[tab?.id]
      ref?.init()
    },

    async default() {
      await this.$http.put(`/thumbnail?id=${encodeURIComponent(this.item.id)}&default=1`)
      this.exit()
    },
    async delete() {
      if (this.item.type == 'playlist') await this.$http.put(`/playlist/thumbnail?id=${encodeURIComponent(this.item.id)}`)
      else await this.$http.put(`/thumbnail?id=${encodeURIComponent(this.item.id)}&delete=1`)
      this.exit()
    },
    async album(album) {
      await this.$http.put(`/thumbnail?id=${encodeURIComponent(this.item.id)}&album=${encodeURIComponent(album)}`)
      this.exit()
    },
    async url(url) {
      if (this.item.type == 'playlist') await this.$http.put(`/playlist/thumbnail?id=${encodeURIComponent(this.item.id)}&url=${encodeURIComponent(url)}`)
      else await this.$http.put(`/thumbnail?id=${encodeURIComponent(this.item.id)}&url=${encodeURIComponent(url)}`)
      this.exit()
    },

    set() {
      if (this.user_url?.length > 0) {
        this.url(this.user_url)
      }
    },
    
    exit() {
      this.$bus.emit('item:thumbnail', this.item)
      this.$router.back()
    }
  }
}

</script>

<style scoped>

.thumbnail-editor {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: var(--content-header-height);
}

.thumbnail-editor label {
  margin-bottom: 1rem;
}

</style>
