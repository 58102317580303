
export default {

  clone: function(obj) {
    return JSON.parse(JSON.stringify(obj))
  },

  arrayEquals: function(a, b, cb) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => cb ? cb(val, b[index]) : val === b[index])
  },

  wait: function(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  },

  isSafari: function() {
    const userAgent = navigator.userAgent.toLowerCase()
    return (userAgent.includes('safari') && !userAgent.includes('chrome'));
  },
  
  getVideoResolution: function(info) {
    if (info.width >= 3840 || info.height >= 2160) return '4K'
    if (info.width == 1920 || info.height == 1080) return '1080p'
    if (info.width == 1280 || info.height == 720) return '720p'
    if (info.width == 1440 || info.height == 810) return 'HD'
    return 'SD'
  },

  getCodecName: function(codec) {
    if (codec.includes('E-AC-3')) return 'DD+'
    return codec
  },

  formatTime: function(seconds) {
    if (seconds == null) return null
    let minutes = Math.floor(seconds / 60)
    minutes = (minutes >= 10) ? minutes : '0' + minutes
    seconds = Math.floor(seconds % 60)
    seconds = (seconds >= 10) ? seconds : '0' + seconds
    return minutes + ":" + seconds
  },

  getLocalPlaybackDevice: function() {
    return {
      type: 'local',
      uuid: 'dfbc15fb-5478-40ea-aa56-eb8d08a22ef2',
      description: 'local_playback'
    }
  },

  getPlaybackDevice: function() {
    return this._unserializeDevice(localStorage.playbackDevice)
  },

  getPreviousPlaybackDevice: function() {
    return this._unserializeDevice(localStorage.playbackDevicePrevious)
  },

  _unserializeDevice: function(device) {
    try {
      return JSON.parse(device)
    } catch (_) {
      return this.getLocalPlaybackDevice()
    }
  },

  setPlaybackDevice: function(device) {
    // if current device is not a service specific device then save it
    if (this.serviceSpecificDevices().includes(this.getPlaybackDevice().type) == false) {
      localStorage.playbackDevicePrevious = localStorage.playbackDevice
    }
    localStorage.playbackDevice = JSON.stringify(device || this.getLocalPlaybackDevice())
  },

  serviceSpecificDevices: function() {
    return ['tidal']
  },

  copyLinkToItem: async function(item) {
    return this.writeToClipboard(item.getBrowseUrl())
  },

  writeToClipboard: async function (text) {
    try {
      let do_copy = false
      if (this.isSafari()) {
        do_copy = true
      } else {
        let result = await navigator.permissions.query({name: 'clipboard-write'})
        do_copy = (result.state == 'granted' || result.state == 'prompt')
      }
      if (do_copy) {
        await navigator.clipboard.writeText(text)
        return true;            
      } else {
        console.log('Clipboard access not granted')
      }
    } catch (err) {
      console.error(err)
    }

    // too bad
    return false
  },

  secondsToHMS(seconds) {
    return [3600, 60]
      .reduceRight(
        (p, b) => r => [Math.floor(r / b)].concat(p(r % b)),
        r => [r]
      )(seconds)
      .map(a => a.toString().padStart(2, '0'))
      .join(':')
  },

  minutesToHM(minutes) {
    let hours = Math.floor(minutes / 60)
    minutes = minutes - hours * 60
    return `${hours}h${minutes.toString().padStart(2, '0')}`
  },

  bytesToHuman(size, decimals) {
    if (size === 0) return '0 B';
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const base = 1024;
    const exponent = Math.floor(Math.log(size) / Math.log(base));
    const sizeInUnits = (size / Math.pow(base, exponent)).toFixed(decimals || 0);
    return sizeInUnits + ' ' + units[exponent];
  },

  isTouchDevice() {
    return ('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)
  },

  getTidalQualityDesc(quality) {
    if (quality == 'HI_RES') return 'max'
    else if (quality == 'LOSSLESS') return 'high'
    else if (quality == 'HIGH') return 'high'
    else return 'low'
  },

  isDigit(str) {
    return /^\d+$/.test(str);
  },

  isSyncedLyrics(lyrics) {
    let hasTimestamp = function(line) {
      return /^\[\d\d:\d\d\.\d\d\]/i.test(line)
    }
    if (lyrics == null) return false
    for (let line of lyrics.split('\n')) {
      if (hasTimestamp(line) == false) {
        return false
      }
    }
    return true
  }

}
