<template>
  <span>
    <Icon :id="id" name="list" color="white" :size=12 />
    <b-popover :target="id" :container="container" custom-class="menu" triggers="hover" :placement="placement">
      <div @click="clear" v-if="hasTracks">{{ $t('new') }}</div>
      <div @click="load">{{ $t('load') }}</div>
      <div @click="save" v-if="hasTracks">{{ $t('save') }}</div>
      <div @click="rename" v-if="hasTitle">{{ $t('rename') }}</div>
      <div @click="suppress" v-if="hasTitle">{{ $t('delete') }}</div>
    </b-popover>
  </span>
</template>

<script>

export default {
  props: {
    playlist: {},
    container: {},
    placement: {
      type: String,
      default: 'right',
    }
  },
  data: function() {
    return {
      id: `menu-${Math.floor(Math.random()*1000)}`
    }
  },
  computed: {
    hasTracks() {
      return this.playlist != null && this.playlist.tracks != null && this.playlist.tracks.length > 0
    },
    hasTitle() {
      return this.playlist != null && this.playlist.title != null && this.playlist.title.length > 0
    }
  },
  methods: {
    clear() {
      this.$root.$emit('bv::hide::popover')
      this.$audio.stop()
    },
    load() {
      this.$root.$emit('bv::hide::popover')
      this.$bus.emit('playlist:load')
    },
    save() {
      this.$root.$emit('bv::hide::popover')
      this.$bus.emit('playlist:save')
    },
    rename() {
      this.$root.$emit('bv::hide::popover')
      this.$bus.emit('playlist:rename')
    },
    suppress() {
      this.$root.$emit('bv::hide::popover')
      this.$bus.emit('playlist:delete')
    },
  }

}

</script>

<style scoped>

.menu {
  cursor: pointer;
}

</style>
