<template>
  <div class="thumbnail-gallery">
    <b-container v-if="path" class="search">
      <b-row>
        <b-col cols=10><b-form-input v-model="query" /></b-col>
        <b-col cols=2><b-button variant="primary" @click="find">{{ $t('browser_search') }}</b-button></b-col>
      </b-row>
      <b-row v-if="hasExact">
        <b-col cols=12><b-form-checkbox v-model="exact">{{ $t('set_thumb_exact_search') }}</b-form-checkbox></b-col>
      </b-row>
    </b-container>
    <div class="gallery" :class="item.type">
      <div v-for="image in images" :key="image.url" class="item">
        <img :src="image.url" @click="select(image)" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: [ 'callbacks', 'default', 'item', 'path', 'q', 'has-exact' ],
  data: function() {
    return {
      inited: false,
      query: null,
      exact: true,
      images: null,
    }
  },
  mounted: function() {
    this.query = this.q || this.item.cleanTitle
  },
  methods: {

    init() {
      if (this.inited == false) {
        if (this.default) { 
          this.load()
        } else {
          this.find()
        }
        this.inited = true
      }
    },

    load() {

      if (this.item.type == 'martist') {

        // 1st default
        this.images = [{
          type: 'default',
          url: `/thumbnail?id=${encodeURIComponent(this.item.id)}&default=1`
        }]

        // add albums
        this.$http.getItems(this.item.id).then((albums) => {
          for (const album of albums) {
            this.images.push({
              type: 'album',
              data: album.id,
              url: album.thumbnail
            })
          }
        })

      } else if (this.item.type == 'playlist') {

        // only delete
        this.images = [{
          type: 'delete',
          url: './icons/folder_music.png'
        }]

      } else if (this.item.type == 'video') {

        // only delete
        this.images = [{
          type: 'delete',
          url: './icons/video_big.png'
        }]
      
      }
    
    },
    async find() {

      // generic
      this.images = []
      let url = `${this.path}${this.query}${(this.hasExact ? '&exact=' + (this.exact ? 'true' : 'false') : '')}`
      let data = await this.$http.getWs(url)

      // depends on service
      if (this.path.includes('moviedb')) {

        // get moviedb base url
        let configuration = await this.$http.getWs('/moviedb/configuration')
        const moviedb_base_url = configuration?.images?.secure_base_url || 'https://image.tmdb.org/t/p/'

        // now parse result
        for (const movie of data.results) {
          if (movie.poster_path != null) {
            var thumb_url = `${moviedb_base_url}w780/${movie.poster_path}`
            this.images.push({
              type: 'url',
              data: thumb_url, 
              url: thumb_url
            })
          }
        }

      } else {
      
        // basic
        for (const result of data.results) {
          this.images.push({
            type: 'url',
            data: result,
            url: result
          })
        }
      
      }

    },
    select(image) {
      let callback = this.callbacks?.[image.type]
      if (callback != null) {
        callback(image.data)
      }
    },
  }
}

</script>

<style scoped>

.thumbnail-gallery .search .row {
  margin-bottom: 0.75rem;
}

.thumbnail-gallery .gallery {

  --gallery-item-size: 155px;
  
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  align-content: start;
  padding-bottom: 32px;

}

.thumbnail-gallery .gallery .item {
  flex: 0 0 auto;
  cursor: pointer;
  width: var(--gallery-item-size);;
  margin: 10px;
  border: 1px solid var(--ghosted-text-color);
}

.thumbnail-gallery .gallery .item img {
  width: var(--gallery-item-size);
  height: var(--gallery-item-size);
  object-fit: cover;
}

.thumbnail-gallery .gallery.video .item img {
  height: calc(var(--gallery-item-size) * var(--video-thumbnail-ratio));
}

</style>