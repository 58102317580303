<template>
  <div class="movie" v-if="movie">
    <div class="header">
      <!--Hero :tag="movie.id"-->
        <img class="cover" :src="cover" />
      <!--/Hero-->
      <router-link :to="{ name: 'video_player', params: { slug: movie.slug, movie: movie, } }">
        <b-button class="play" variant="primary"><Icon name="play-btn" :size=14 /><span>{{ $t('movie_play') }}</span></b-button>
      </router-link>
      <b-button class="cast" variant="primary" @click="cast"><Icon name="cast" :size=14 /><span>{{ $t('movie_cast') }}</span></b-button>
      <b-button variant="primary" @click="search"><Icon name="search" :size=14 /><span>{{ $t('movie_update_info') }}</span></b-button>
    </div>
    <div class="details">
      <div class="info" v-if="info">
        <div v-if="voteAverage" class="rating"><Icon name="star-fill" :size=12 /><span>{{ parseFloat(voteAverage).toFixed(1) }}</span></div>
        <div v-if="year">{{ year }}</div>
        <div>{{ duration }}</div>
      </div>
      <div class="title">
        <span>{{ title }}</span>
        <a v-if="info" :href="`https://www.themoviedb.org/movie/${this.info.id}`" target="_blank"><Icon name="link" :size=24 /></a>
      </div>
      <div class="tagline">{{ tagline }}</div>
      <div class="techinfo">
        <span v-for="(info,index) in techInfo" :key="index">{{ info }}</span>
      </div>
      <div class="genres">
        <b-badge variant="info" class="genre" v-for="genre in genres" :key="genre.id">
          {{ genre.name }}
        </b-badge>
      </div>
      <div class="director" v-if="director">
        <span class="job">{{ $t('movie_director') }}{{ $t('colon') }}</span>
        <span><a :href="`https://www.themoviedb.org/person/${director.id}`" target="_blank">{{ director.name }}</a></span>
      </div>
      <div class="overview">{{ overview }}</div>
      <div class="cast" v-if="credits">
        <div class="actor" v-for="member in credits.cast.slice(0, 5)" :key="member.id">
          <a :href="`https://www.themoviedb.org/person/${member.id}`" target="_blank">
            <b-avatar class="profile" :src="member.profile_path == null ? '' : `${moviedb_base_url}w185${member.profile_path}`" />
          </a>
          <div class="name">{{ member.name }}</div>
        </div>
      </div>
      <div class="fileinfo">
        <span class="filename">{{ movie.filename }}</span>
      </div>
    </div>
    <b-modal v-model="pick" id="moviedb-picker" modal-class="moviedb-picker" :title="$t('browser_search')" :ok-only=true :ok-title="$t('cancel')">
      <div class="query">
        <b-form inline>
          <b-input v-model="search_query"/>
          <b-button variant="primary" @click="search">{{ $t('browser_search') }}</b-button>
        </b-form>
      </div>
      <div class="results" v-if="search_results">
        <div v-for="result in search_results" :key="result.id" :id="`moviedb-result-${result.id}`" class="result" @click="update(result)">
          <span>{{ result.title }}</span>
          <span v-if="result.release_date != null">({{ result.release_date.substr(0,4) }})</span>
          <b-popover :target="`moviedb-result-${result.id}`" :container="`moviedb-result-${result.id}`" triggers="hover" placement="left" v-if="result.poster_path != null">
            <img class="poster" :src="`${moviedb_base_url}w500${result.poster_path}`"  />
          </b-popover>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import utils from '../../js/utils/utils.js'
import lang from '../../js/utils/lang.js'

export default {
  props: [ 'id', 'item' ],
  data: function() {
    return {
      movie: null,
      tech: null,
      info: null,
      show: null,
      credits: null,
      moviedb_base_url: 'https://image.tmdb.org/t/p/',
      search_query: null,
      search_results: null,
      pick: false,
    }
  },
  computed: {
    cover() {
      if (this.moviedb_base_url != null && this.info?.poster_path?.length) {
        return `${this.moviedb_base_url}w500${this.info.poster_path}`
      }
      return this.movie.thumbnail
    },
    title() {
      return this.episodeInfo?.name || this.info?.title || this.movie.title
    },
    tagline() {
      return this.movie.isShowEpisode()
        ? (this.episodeInfo?.tagline || `${this.$t('show_season')} ${this.movie.getShowSeason()}, ${this.$t('show_episode')} ${this.movie.getShowEpisode()}`)
        : this.info?.tagline
    },
    genres() {
      return this.show?.genres || this.info?.genres
    },
    duration() {
      return utils.minutesToHM(Math.floor(this.movie.duration_raw/60))
    },
    filesize() {
      return utils.bytesToHuman(this.movie.filesize)
    },
    voteAverage() {
      return this.episodeInfo?.vote_average || this.info?.vote_average
    },
    year() {
      let year = this.episodeInfo?.air_date || this.info?.air_date || this.info?.release_date
      return year != null && year.length >= 4 ? year.substr(0, 4) : null
    },
    overview() {
      return this.episodeInfo?.overview || this.info?.overview
    },
    episodeInfo() {
      if (this.info?.season_number != null && this.info.season_number == this.movie.getShowSeason()) {
        return this.info.episodes.find((e) => e.episode_number == this.movie.getShowEpisode())
      } else {
        return null
      }
    },
    techInfo() {
      if (this.tech == null) {
        return null
      }
      let infos = []
      if (this.tech.video_stream != null) {
        infos.push(this.videoInfo(this.tech.video_stream))
        infos.push(this.movie.dynamic_range.toUpperCase())
      }
      if (this.tech.audio_streams != null) {
        for (const audio_stream of this.tech.audio_streams) {
          infos.push(this.audioInfo(audio_stream))
        }
      }
      infos.push(utils.bytesToHuman(this.movie.filesize, 1))
      return infos
    },
    director() {
      return this.credits?.crew?.filter(e => e.job == 'Director')?.[0]
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('pop_breadcrumb')
    next()
  },
  created: async function() {
    this.movie = this.item
    await this.load()
    this.search_query = this.movie.cleanTitle
    this.$store.commit('add_breadcrumb', this.movie)
  },
  methods: {

    async load() {

      // first let's make sure we have a movie
      if (this.movie == null) {
        this.movie = await this.$http.getItem(this.id)
      }

      // now load all the stuff in parallel and without try/catch (as will be needed with await)
      this.$http.getWs(`/utils/video/info?id=${this.movie.id}`).then((data) => {
        this.tech = data
      })
      this.$http.getWs('/moviedb/configuration').then((configuration) => {
        this.moviedb_base_url = configuration?.images?.secure_base_url || this.moviedb_base_url
      })
      this.$http.getWs(`/moviedb/info?id=${this.movie.id}&moviedb_id=${this.movie.moviedb_id}`).then((data) => {
        this.info = data
      })
      this.$http.getWs(`/moviedb/credits?id=${this.movie.id}&moviedb_id=${this.movie.moviedb_id}`).then((data) => {
        this.credits = data
      })
      if (this.movie?.moviedb_id?.[0] == 't') {
        this.$http.getWs(`/moviedb/info?id=${this.movie.id}&moviedb_id=${this.movie.moviedb_id}&level=root`).then((data) => {
          this.show = data
        })
      }
    },

    cast() {
      this.$bus.emit('movie:cast', this.movie)
    },

    search() {
      this.$http.getWs(`/moviedb/search?query=${this.search_query}`).then((data) => {
        this.search_results = data?.results.map((result) => {
          result.title = result.title || result.name
          result.release_date = result.release_date || result.first_air_date
          return result
        })
        this.pick = true
      })
    },

    update(moviedb_result) {

      // hide
      this.$bvModal.hide('moviedb-picker')

      // process change
      if (moviedb_result != null && moviedb_result.id != this.movie.moviedb_id) {

        // submit and reload browser
        let url = `/moviedb/id/set?id=${this.movie.id}&moviedb_id=${moviedb_result.id}&moviedb_type=${moviedb_result.media_type}`
        if (this.movie.getShowSeason() != -1 && this.movie.getShowEpisode() != -1) {
          url = `${url}&season=${this.movie.getShowSeason()}&episode=${this.movie.getShowEpisode()}`
        }
        this.$http.getWs(url).then((data) => {
          this.movie.moviedb_id = data?.moviedb_id
          this.$bus.emit('item:thumbnail', this.movie)
          this.load()
        })

      }

    },

    videoInfo(info) {
      return `${this._codec(info.profile)} ${utils.getVideoResolution(info)}`
    },

    audioInfo(info) {
      let language = lang.getLanguageName(info.language)
      let codec = info.codec == null || info.codec == '0000' ? info.profile : info.codec
      return `${language} ${utils.getCodecName(codec)}`
    },

    _codec(profile) {

      // divx
      if (profile.toLowerCase().includes('mpeg-4 part 2')) {
        return 'DivX'
      }

      // split
      let tokens = profile.split(/[\(\/]/)
      return tokens[0]
    },
    
  }
}

</script>

<style lang="scss" scoped>

.movie {
  padding-bottom: 32px;
	padding-right: calc(var(--navigation-width) + var(--content-padding));
  padding-top: 64px;
  width: 80%;
  min-width: 700px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
	
  .header {
    flex: 0 0 30%;
    font-size: 13pt;
		text-align: center;
    padding-right: 64px;
    --size: 220px;
		
    .cover {
			width: var(--size);
			height: calc(var(--size) * var(--video-cover-ratio));
			object-fit: cover;
			margin-bottom: 16px;
      cursor: pointer;
		}

    .btn {
      width: var(--size);
      margin-bottom: 12px;
      span {
        margin-left: 8px;
        font-weight: bold;
      }
    }

	}

  .details {
    flex: 0 0 70%;

    .info {
      float: right;
      text-align: right;
      font-size: 15pt;

      * {
        margin: 2px 0;
      }
      
      .rating, .rating i {
        font-size: 22pt;
        font-weight: bold;
        color: #f6cd03 !important;
        span {
          margin-left: 6px;
        }
      }

    }
		
    .title {
			margin-bottom: 8px;
			color: var(--text-color);
      span {
        font-size: 24pt;
        font-weight: 900;
        margin-right: 8px;
      }
		}

    .tagline {
      font-size: 14pt;
      color: var(--faded-text-color);
      margin-bottom: 24px;
    }

    .techinfo {
      font-size: 12pt;
      margin-bottom: 16px;
      span::after {
        content: '•';
        padding: 0px 8px;
      }
      span:last-child::after {
        content: none;
      }
    }

    .genres {
      margin-bottom: 32px;
      .genre {
        padding: 8px;
        font-size: 11pt;
        margin-right: 16px;
        background-color: var(--highlight-color);
      }
    }

    .director {
      font-size: 13pt;
      margin-bottom: 16px;
      color: var(--faded-text-color);
      a {
        color: var(--faded-text-color);
        text-decoration: none;
      }
    }

    .overview {
      font-size: 13pt;
      margin-bottom: 32px;
    }

    .cast {
      .actor {
        
        width: 96px;
        margin: 0px 16px;
        text-align: center;
        display: inline-block;
        vertical-align: top;

        .profile {
          width: 80px;
          height: 80px;
          margin-bottom: 8px;
        }

        .name {
          font-size: 12pt;
        }

      }
    }
  }

  .fileinfo {
    margin-top: 32px;
    padding-bottom: 32px;
    .filename {
      font-style: italic;
    }
  }

}

@import 'css/mixins';
@include media-phone() {
  .movie {
  	padding-right: 0px;
    overflow-x: hidden;
    
    .movie-info {
      margin-top: 16px;

      .cover {
        width: 192px;
        height: 192px;
      }
      .title {
        font-size: 14pt;
      }
    }

  }
}

.moviedb-picker {

  .query {
    margin-bottom: 16px;
    input {
      flex: 1 0 auto;
      margin-right: 8px;
    }
  }
  .results {
    
    padding: 0px 2px;
    
    .result {
      
      margin: 2px 0px;
      font-size: 12pt;
      cursor: pointer;
      
      &:hover {
        color: var(--highlight-color);
      }

      .poster {
        --size: 128px;
        width: var(--size);
        height: calc(var(--size) * var(--video-thumbnail-ratio));
        object-fit: contain;
      }
    }
  }
}

</style>
