<template>
  <div class="lyrics">
    <div v-if="synced" class="synced">
      <div v-for="(line, index) in lines" :key="index" class="line" :class="{ 'current': line == current }" :data-position="line.position">
        {{ line.content == '' ? empty : line.content }}
      </div>
    </div>
    <div v-else>
      <div v-for="(line, index) in lines" :key="index" class="line">
        {{ line.content == '' ? '&nbsp;' : line.content }}
      </div>
    </div>
  </div>
</template>

<script>

import media from '../../js/model/media.js'
import utils from '../../js/utils/utils.js'

export default {
  props: [ 'lyrics' ],
  data: function() {
    return {
      active: false,
      status: null,
      cache: null,
      synced: false,
      current: {},
    }
  },
  computed: {
    empty() {
      return '•   •   •'
    },
    lines() {
      if (this.cache == null) {
        return []
      }
    
      // add waiting 1st line
      if (this.synced && this.cache[0].position > 0) {
        return [{
          timestamp: 0,
          position: 0,
          content: this.empty
        }, ...this.cache]
      } else {
        return this.cache
      }
    }
  },
  mounted: function() {

    // cannot reload
    if (this.lyrics == null) {
      this.$router.replace({ name: 'browser', params: { id: media.getRoots()['audio'].id }})
      return
    }

    // process lyrics
    this.active = true
    this.buildCache(this.lyrics)

    // init scrolling
    document.getElementById('app-content')?.scrollTo(0, 0)
    requestAnimationFrame(() => this.step())
    this.processStatus(this.$audio.status())

    // update with status
    this.$audio.on('status', (status) => this.processStatus(status))
  
  },
  deactivated: function() {
    this.active = false
  },
  beforeDestroy: function() {
    this.active = false
  },
  destroyed: function() {
    this.active = false
  },
  methods: {
    isCurrenTrack() {
      return (this.status?.track().id == this.lyrics.id || this.status?.track().tidal_id == this.lyrics.id)
    },
    trimLine(line) {
      return line.trim().replace(/^\s+|\s+$/g, '')
    },
    isSynced() {
      return utils.isSyncedLyrics(this.lyrics.lyrics)
    },
    async reload(track) {
      let lyrics = await this.$http.getLyrics(track)
      this.$router.replace({ name: 'lyrics', params: { slug: track.slug, lyrics: lyrics }})
    },
    buildCache(lyrics) {
      this.cache = lyrics.lyrics.split('\n').map((line) => {
        if (!this.isSynced()) {
          return {
            content: this.trimLine(line),
          }
        } else {
          let minutes = parseInt(line.substr(1,2))
          let seconds = parseInt(line.substr(4,2))
          let millis = -1;//parseInt(line.substr(7,2)) * 10
          return {
            timestamp: line.substr(1,8).trim(),
            position: (60*minutes + seconds) * 1000 + millis,
            content: this.trimLine(line.substr(10)),
          }
        }
      })
    },
    processStatus(status) {
      
      // only if active
      if (!this.active) {
        return
      }

      // reload?
      this.status = status
      if (this.isCurrenTrack() == false) {
        this.active = false
        this.reload(this.status?.track())
        return
      }

      // step anyway
      this.step()
    },
    step() {

      // check if still active
      if (this.active == false) {
        return
      }

      // check status
      this.status = this.$audio.status()
      this.synced = this.isSynced() && this.isCurrenTrack()
      if (!this.synced) {
        this.current = null
        return
      }

      // now track lyrics
      if (this.status != null && this.status.position > 0) {

        // find current line
        let current = null
        for (let line of this.lines) {
          if (line.position > this.status.position * 1000) {
            break
          }
          current = line
        }

        // if changed
        if (current != this.current) {
          
          // store
          this.current = current

          // scroll to line
          const viewport = document.getElementById('app-content')
          const el = document.querySelector(`[data-position="${this.current.position}"]`)
          viewport?.scrollTo({
            top: el?.offsetTop - viewport?.offsetHeight/2,
            behavior: 'smooth',
          })

        }
      }

      // update as soon as possible
      requestAnimationFrame(() => this.step())
    }
  }
}

</script>

<style lang="scss" scoped>
@import '/css/mixins';

.app-content {
  padding-top: 16px;
  padding-bottom: 48px;
}

.lyrics {

  text-align: center;
  @include user-select-none;

  .line {
    font-size: 14pt;
    line-height: 32pt;
  }

  .synced {

    .line {
      color: var(--disabled-text-color);
      font-weight: 900;
      font-size: 26pt;
      line-height: 74pt;
      letter-spacing: 1px;

      -webkit-transition: all 500ms;
        -moz-transition: all 500ms;
          -o-transition: all 500ms;
              transition: all 500ms;

      &.current {
        font-size: 26pt;
        color: var(--text-color);

        -webkit-transition: all 250ms;
          -moz-transition: all 250ms;
            -o-transition: all 250ms;
                transition: all 250ms;

      }
    }
  }
}

</style>
