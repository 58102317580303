import AudioPlayer  from './player.js'

const kScrobbleTime = 60*1000

export default class {

  constructor(options) {
    this._options = options
    this._timer = -1
    this._track = null
    this._playlist = null
    this._start = null
    this._total = 0
  }

  process(state, track, playlist) {

    // stopped
    if (state == AudioPlayer.STATE_STOPPED || track == null) {
      this._clear()
      return
    }

    // needed
    let now = new Date().getTime()

    // get current track id
    if (this._track?.id != track.id || this._playlist?.id != playlist?.id) {
      this._clear()
      this._timer = setTimeout(() => this._emit(), kScrobbleTime)
      this._track = track
      this._playlist = playlist
      this._start = now
      this._total = 0
    } else {
      if (state == AudioPlayer.STATE_PLAYING) {
        if (this._timer == -1) {
          this._timer = setTimeout(() => this._emit(), kScrobbleTime - this._total)
          this._start = now
        }
      } else {
        if (this._timer != -1) {
          clearTimeout(this._timer)
          this._timer = -1
          this._total += now - this._start
        }          
      } 
    }

  }

  _emit() {
    console.log(`Emmiting scrobble for ${this._track.id} (${this._track.title})`)
    this._options.http.request({
      method: 'POST',
      url: `/utils/scrobble?id=${this._track.id}&playlist_id=${this._playlist?.id||''}`,
    })
  }

  _clear() {
    if (this._timer != -1) {
      clearTimeout(this._timer)
      this._timer = -1
      this._track = null
      this._start = null
      this._total = 0
    }
  }  

}