<template>
  <div>
    <div id="fullscreen" :class="computedClass" @click="next" @dblclick="close">
      <img :src="image" @load="loading = false;" />
      <Icon class="control close" name="x" :size=24 @click.native="close" />
      <Icon class="control prev" name="chevron-left" :size=24 v-if="!slideshowTimer" @click.native="prev" />
      <Icon class="control next" name="chevron-right" :size=24 v-if="!slideshowTimer" @click.native="next" />
      <Icon class="control wait" name="clock-history" :size=12 v-if="loading" />
    </div>
  </div>
</template>

<script>

import http from '../../js/http.js'

export default {
  props: [ 'images', 'initialIndex', 'slideshow', ],
  data: function() {
    return {
      index: this.initialIndex,
      starting: false,
      loading: false,
      hovered: false,
      hoverTimer: null,
      slideshowTimer: null,
      rand: null,
    }
  },
  computed: {
    image() {
      if (this.images == null || this.images.length == 0) {
        return `${http.defaults.baseURL || ''}/utils/randpic?_=${this.rand}`
      } else {
        return this.images[this.index].url
      }
    },
    computedClass() {
      let classes = []
      if (this.hovered) {
        classes.push('hovered')
      }
      if (this.slideshowTimer) {
        classes.push('slideshow')
      }
      return classes
    },
  },
  mounted: function() {
    
    // listsners
    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('keydown', this.onKeyDown)

    // leave fullscreen
    document.addEventListener('fullscreenchange', this.exitFullscreen, false)
    document.addEventListener('mozfullscreenchange', this.exitFullscreen, false)
    document.addEventListener('MSFullscreenChange', this.exitFullscreen, false)
    document.addEventListener('webkitfullscreenchange', this.exitFullscreen, false)
    
    // mousemove triggered when entering fullscreen
    // so disable this for sometime...
    this.starting = true
    setTimeout(() => {
      this.starting = false
    }, 1000)

    // init
    this.hovered = false
    this.enterFullscreen()

    // automatic slideshow
    if (this.slideshow == true) {
      this.tick()
    }
    
  },
  methods: {

    close() {

      // stop timer
      clearTimeout(this.slideshowTimer)
      this.slideshowTimer = null

      // remove all listeners
      document.removeEventListener('mousemove', this.onMouseMove)
      document.removeEventListener('keydown', this.onKeyDown)
      document.removeEventListener('fullscreenchange', this.exitFullscreen)
      document.removeEventListener('mozfullscreenchange', this.exitFullscreen)
      document.removeEventListener('MSFullscreenChange', this.exitFullscreen)
      document.removeEventListener('webkitfullscreenchange', this.exitFullscreen)

      // now exit
      this.leaveFullscren()
      this.$router.back()

    },

    prev() {
      if (this.slideshowTimer != null) {
        this.tick()
      } else {
        this.loading = true
        this.index = (this.index == 0 ? this.images.length - 1 : this.index - 1)
      }
    },

    next() {
      if (this.slideshowTimer != null) {
        this.tick()
      } else {
        this.loading = true
        this.index = (this.index + 1) % this.images.length
      }
    },

    tick() {
      clearTimeout(this.slideshowTimer)
      this.loading = true
      this.rand = Math.random()
      this.slideshowTimer = setTimeout(this.tick, 5000)
    },

    enterFullscreen() {
      let el = document.getElementById('fullscreen')
      if (el.requestFullScreen) {
        el.requestFullScreen()
      } else if (el.webkitRequestFullScreen) {
        el.webkitRequestFullScreen()
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen()
      }
    },

    exitFullscreen() {
      if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        this.close()
      }
    },

    leaveFullscren() {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.msCancelFullScreen) {
        document.msCancelFullScreen()
      }
    },

    onKeyDown(ev) {
      switch (ev.keyCode) {
        case 13:
        case 27:
        case 32:
          this.close()
          break
        case 37:
          this.prev()
          break
        case 39:
          this.next()
          break
      }
    },

    onMouseMove() {
      if (!this.starting) {
        this.hovered = true
        clearTimeout(this.hoverTimer)
        this.hoverTimer = setTimeout(() => {
          this.hovered = false
        }, 3000)
      }
    },

  }

}

</script>

<style scoped>

#fullscreen {
  background-color: black;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

#fullscreen .control {
  background-color: black;
  border-radius: 8px;
  padding: 8px;
  opacity: 0;
  cursor: pointer;
}

#fullscreen.hovered .control {
  opacity: 0.5;
}

#fullscreen .control:hover {
  opacity: 0.7;
}

#fullscreen .close {
  position: absolute;
  top: 32px;
  right: 32px;
}

#fullscreen .wait {
  opacity: 0.5 !important;
  position: absolute;
  bottom: 32px;
  right: 32px;
}

#fullscreen .prev {
  position: absolute;
  top: 50%;
  left: 32px;
}

#fullscreen .next {
  position: absolute;
  top: 50%;
  right: 32px;
}

#fullscreen img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

</style>
