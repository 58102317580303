<template>
  <div id="lyrics" class="lyrics">
    <Icon name="chat-square-quote" class="control" :class="computedClassIcon" :size="size" />
    <b-popover target="lyrics" container="lyrics" triggers="hover" placement="top" v-if="track" @show="show">
      <template #title>
        <span>{{ title }}</span>
        <div class="menu">
          <Icon id="save" name="save2" color="white" :size=12 v-if="canSave" @click.native="save" />
          <Icon id="time" :name="hideTime ? 'clock' : 'clock-fill'" color="white" :size=12 v-if="isSynced" @click.native="toggleTimestamps" />
          <Icon id="menu" name="arrows-fullscreen" color="white" :size=12 v-if="lyrics" @click.native="fullscreen" />
        </div>
      </template>
      <div class="info loading" v-if="loading">
        <div class="text-center"><Icon name="chat-square-dots" :size=12 />&nbsp;{{ $t('loading') }}</div>
      </div>
      <textarea class="body" v-else v-model="display" :disabled="!editable" @input="changed"/>
    </b-popover>
  </div>
</template>

<script>

import media from '../../js/model/media.js'
import utils from '../../js/utils/utils.js'

export default {
  props: [ 'size' ],
  data: function() {
    return {
      track: null,
      loading: true,
      lyrics: null,
      text: null,
      display: null,
      updated: false,
      hideTime: false,
    }
  },
  computed: {
    title() {
      return this.lyrics?.title || this.track?.title
    },
    computedClassIcon() {
      return (media.getMediaType(this.track?.id) == 'audio' || this.track?.tidal_id != null) ? 'enabled' : 'disabled'
    },
    isSynced() {
      return utils.isSyncedLyrics(this.lyrics?.lyrics)
    },
    editable() {
      return !this.hideTime && this.lyrics?.editable
    },
    canSave() {
      return !this.hideTime && this.lyrics?.editable && this.display != this.lyrics?.lyrics
    },
  },
  mounted: function() {
    this.$audio.on('status', (status) => {
      this.track = status?.track()
    })
  },
  methods: {
    async show() {

      // check if we need to reload
      let load = false
      if (this.track?.tidal_id != null) {
        load = (this.lyrics == null || this.lyrics?.id != this.track?.tidal_id)
      } else {
        load = (this.lyrics == null || this.lyrics?.id != this.track?.id)
      }

      // do it
      if (load) {
        this.lyrics = null
        this.loading = true
        this.lyrics = await this.$http.getLyrics(this.track)
        if (this.lyrics != null) {
          this.updated = this.lyrics.modified
          if (this.lyrics.lyrics.length == 0) {
            this.lyrics.lyrics = this.$i18n.t('lyrics_not_found')
          }
        }
        this.loading = false
      }

      // save
      this.text = this.lyrics?.lyrics || ''
      this.display = this.text
      this.updateDisplay()

    },
    changed() {
      this.updated = true
      this.$forceUpdate()
    },
    toggleTimestamps() {
      this.hideTime = !this.hideTime
      this.updateDisplay()
      this.$forceUpdate()
    },
    updateDisplay() {
      if (this.hideTime) {
        this.text = this.display
        this.display = this.text.replace(/\[\d+:\d+\.\d+\][ ]*/g, '')
      } else {
        this.display = this.text
      }
    },
    fullscreen() {
      if (this.lyrics != null) {
        this.$root.$emit('bv::hide::popover', 'lyrics')
        let destination = { name: 'lyrics', params: { slug: this.track.slug, lyrics: this.lyrics }}
        if (this.$route.name != 'lyrics') {
          this.$router.push(destination)
        } else if (this.$route.params.slug != slug) {
          this.$router.replace(destination)
        }
      }
    },
    async save() {
      try {
        await this.$http.put(`/utils/lyrics?id=${this.lyrics.id}`, this.display)
        this.lyrics.lyrics = this.display
        this.updated = false
        this.$forceUpdate()
      } catch (err) {
        console.error(err)
      }
    }
  }

}

</script>

<style scoped>

.lyrics {
  display: inline-block;
}

.lyrics .control.enabled {
  color: var(--text-color) !important;
}

.lyrics .control.disabled {
  color: var(--disabled-text-color) !important;
}

.lyrics .popover  {
  max-width: 420px;
  width: 420px
}

.lyrics .popover-body > * {
  --padding: 8px;
  width: 100%;
  height: calc(var(--popover-height) - 2*var(--padding));
  padding: var(--padding);
}

.lyrics .popover-body .info {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.lyrics .popover-body .info i.icon {
  margin-right: 8px;
}

.lyrics .popover-body .body {
  white-space: pre;
  background-color: transparent;
  color: var(--text-color);
}

.lyrics .popover-body textarea, .lyrics .popover-body textarea:focus {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}

</style>