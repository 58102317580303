<template>
  <div v-if="nodes">
    <div class="tree">
      <div class="explanation">
        <h4>{{ $t(`cfg_${type}_tree`) }}</h4>
        <div>{{ $t(`cfg_tree_hint_general`) }}</div>
        <div>{{ $t(`cfg_tree_hint_arrange`) }}</div>
        <div>{{ $t(`cfg_tree_hint_grouped`) }}</div>
      </div>
      <div class="editor">
        <draggable v-model="nodes">
          <div v-for="node in nodes" :key="node.type">
            <div class="node">
              <b-checkbox v-model="node.selected" class="d-inline-block" /> <span class="name">{{ $t(node.text) }}</span>
              <span v-if="node.grouped !== null">
                (<a class="group" @click="node.grouped = !node.grouped">{{ node.grouped ? $t('cfg_node_grouped') : $t('cfg_node_ungrouped') }}</a>)
              </span>
            </div>
          </div>
        </draggable>
      </div>
    </div>
    <div class="buttons">
      <b-button @click="save" variant="primary"><img alt="" src="../../icons/tick.png" /><span>{{ $t('save') }}</span></b-button>
    </div>
  </div>
</template>

<script>

import Config from '../../js/model/config.js'
import Tree from '../../js/model/tree.js'
import draggable from 'vuedraggable'

export default {
  components: { draggable, },
  props: [ 'type', ],
  data: function() {
    return {
      nodes: null,
    }
  },
  methods: {
    async refresh() {
      
      // load config
      let config = new Config({ http: this.$http })
      let json = await config.get()

      // load and process tree
      let tree = new Tree({ http: this.$http })
      this.nodes = tree.get(this.type)
      tree.process(this.nodes, json, this.type)

    },
    async save() {
      try {
        let tree = new Tree({ http: this.$http })
        tree.save(this.nodes, this.type)
        this.$store.commit('clear_browser_cache')
        this.$bus.emit('notification:success', 'exec_ok')
      } catch (err) {
        console.error(err)
        this.$bus.emit('notification:error', 'exec_ko')
      }
    }
  }

}
</script>

<style scoped>

.tree {
  display: flex;
  height: 250px;
}

.tree .explanation {
  flex: 0 0 55%;
  padding-right: 8px;
  padding-top: 4px;
  border-right: 1px solid var(--highlight-color);
  padding-right: 32px;
  text-align: right;
}

.tree .explanation h4 {
  font-weight: bold;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: var(--highlight-color);
}

.tree .explanation div {
  margin: 16px 0;
}

.tree .editor {
  flex: 0 0 45%;
  padding-top: 8px;
  padding-left: 32px;
}

.tree .editor span.name {
  cursor: move;
}

.tree .editor a.group {
  cursor: pointer;
}

</style>
