<template>
  <div class="app-config">

    <!--
            Refresh tab on activate: Status tabs should have:
              - id set on the b-tab
              - same value set as ref on the ThumbnailGallery component
    -->

    <div class="app-config-content" v-if="!authing">
      <div class="login" v-if="!config">
        <b-alert show variant="danger">{{ $t('control_connection_lost') }}</b-alert>
        <div class="buttons">
          <b-button id="refresh" variant="primary" @click="refresh"><img alt="" src="../../icons/refresh.png" /><span>{{ $t('retry') }}</span></b-button>
        </div>
      </div>
      <b-tabs align="center" @activate-tab="activate" v-else>
        <b-tab :title="$t('cfg_status')" id="status">
          <Status ref="status" />
        </b-tab>
        <b-tab :title="$t('cfg_options')" id="options">
          <Options ref="options" />
        </b-tab>
        <b-tab :title="$t('cfg_security')" id="security">
          <Security ref="security" />
        </b-tab>
        <b-tab :title="$t('cfg_shares')" id="shares">
          <Shares ref="shares" />
        </b-tab>
        <b-tab :title="$t('cfg_photo_tree')" id="photo">
          <TreeEditor type="photo" ref="photo" />
        </b-tab>
        <b-tab :title="$t('cfg_music_tree')" id="music">
          <TreeEditor type="music" ref="music" />
        </b-tab>
        <b-tab :title="$t('cfg_video_tree')" id="video">
          <TreeEditor type="video" ref="video" />
        </b-tab>
        <b-tab :title="$t('cfg_maintenance')" id="maintenance">
          <Maintenance ref="maintenance" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>

import Config from '../../js/model/config.js'
import TreeEditor from '../components/TreeEditor.vue'
import Maintenance from './Maintenance.vue'
import Options from './Options.vue'
import Security from './Security.vue'
import Shares from './Shares.vue'
import Status from './Status.vue'

export default {
  components: { TreeEditor, Maintenance, Options, Security, Shares, Status, },
  data: function() {
    return {
      authing: true,
      config: null,
    }
  },
  created() {
    this.refresh()
  },
  methods: {

    async refresh() {

      try {

        // no show
        this.authing = true

        // try to download configuration
        let config = new Config({ http: this.$http })
        this.config = await config.get()
        this.authing = false

        // refresh status
        this.$nextTick(() => {
          this.$refs.status.refresh()
        })

      } catch (err) {
        console.error(err)
        this.authing = false
      }

    },

    activate(newTabIndex, _, bvEvent) {
      // get the tab by index in the event and the find it by id in the ref...
      let tab = bvEvent?.vueTarget?.getTabs()?.[newTabIndex]
      let ref = this.$refs[tab?.id]
      ref?.refresh()
    },

  }
}

</script>

<style lang="scss">
@import '../../css/config.scss';
</style>

<style scoped>

.app-config .app-config-content {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 4em;
}

.app-config .app-config-content .tab-pane {
  margin: 0 auto;
  margin-top: 32px;
  width: 900px;
}

.app-config .app-config-content .login {
  width: 400px;
  margin: 128px auto;
}

.app-config .app-config-content .login form {
  margin-top: 64px;
}

</style>
