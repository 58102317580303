
import LocalPlayer from './local.js'
import PodPlayer from './pod.js'
import UpnpPlayer from './upnp.js'
import SonosPlayer from './sonos.js'
import TidalPlayer from './tidal.js'
import utils from '../utils/utils.js'

const DEVICE_REFRESH_INTERVAL = 10000

export default class {

  constructor(bus, http, options) {
    this.$bus = bus
    this.$http = http
    this._options = options
    this._init()
  }

  _init() {

    // list of device and refresh
    this._refresh()

    // invalid device
    this.$bus.on('audio:device:invalid', () => {
      this.$bus.emit('notification:error', 'Error restoring previous audio player. Switching to local playback.')
      this.switch(utils.getLocalPlaybackDevice())
    })

  }

  device() {
    return utils.getPlaybackDevice()
  }

  devices() {
    return this._devices
  }

  switch(device) {
    utils.setPlaybackDevice(device)
    this.$bus.emit('audio:device', device)
  }

  //  0: requested device was already selected
  //  1: requested device is available and switched to
  // -1: requested device is not available
  request(type) {

    // normal device requested
    if (type == null) {
      if (utils.serviceSpecificDevices().includes(this.device().type)) {
        this.switch(utils.getPreviousPlaybackDevice())
        return 1
      }
      return 0
    }

    // if device already right type
    if (this.device().type == type) {
      return 0
    }

    // specific device requested
    for (let device of this._devices) {
      if (device.type == type && device.description == this.device().description) {
        this.switch(device)
        return 1
      }
    }

    // too bad
    return -1
  
  }

  loadPlayer() {
    let device = utils.getPlaybackDevice()
    if (device.type == 'pod') {
      return this._initPod(device)
    } else if (device.type == 'upnp') {
      return this._initUpnp(device)
    } else if (device.type == 'sonos') {
      return this._initSonos(device)
    } else if (device.type == 'tidal') {
      return this._initTidal(device)
    } else {
      return this._initLocal(device)
    }
  }

  _initLocal(device) {
    console.log(`Setting up Local player`)
    return new LocalPlayer({
      bus: this.$bus,
      http: this.$http,
      device: device,
      ...this._options,
    })
  }

  _initPod(device) {
    console.log(`Setting up Pod device "${device.description}"`)
    return new PodPlayer({
      bus: this.$bus,
      http: this.$http,
      device: device,
      ...this._options,
    })
  }

  _initUpnp(device) {
    console.log(`Setting up UPnP device "${device.description}"`)
    return new UpnpPlayer({
      bus: this.$bus,
      http: this.$http,
      device: device,
      ...this._options,
    })
  }

  _initSonos(device) {
    console.log(`Setting up Sonos device "${device.description}"`)
    return new SonosPlayer({
      bus: this.$bus,
      http: this.$http,
      device: device,
      ...this._options,
    })
  }

  _initTidal(device) {
    console.log(`Setting up TIDAL device "${device.description}"`)
    return new TidalPlayer({
      bus: this.$bus,
      http: this.$http,
      device: device,
      ...this._options,
    })
  }

  async _refresh() {

    // reset
    this._devices = [ utils.getLocalPlaybackDevice(), ]

    // pods
    try {
      let devices = await this.$http.getWs('/player/list')
      for (const device of devices) {
        this._devices.push({
          type: 'pod',
          uuid: device.uuid,
          description: device.name,
        })
      }
    } catch (err) {
      //console.error(err)
    }

    // upnp
    try {
      let devices = await this.$http.getWs('/upnp/list')
      for (const device of devices) {
        this._devices.push({
          type: 'upnp',
          uuid: device.host,
          description: device.name,
        })
      }
    } catch (err) {
      //console.error(err)
    }

    // sonos
    try {
      let devices = await this.$http.getWs('/sonos/list')
      for (const device of devices) {
        this._devices.push({
          type: 'sonos',
          uuid: device.coordinator.uuid,
          name: device.coordinator.roomName,
          description: device.coordinator.roomName
        })
      }
    } catch (err) {
      //console.error(err)
    }

    // tidal
    try {
      let devices = await this.$http.getWs('/tidal/list')
      for (const device of devices) {
        this._devices.push({
          type: 'tidal',
          uuid: device.uuid,
          name: device.name,
          description: device.name
        })
      }
    } catch (err) {
      //console.error(err)
    }

    // sort
    this._devices = this._devices.sort((a, b) => {
      if (a.type == utils.getLocalPlaybackDevice().type) return -1
      if (b.type == utils.getLocalPlaybackDevice().type) return 1
      return a.description.localeCompare(b.description)
    })

    // broadcast
    this.$bus.emit('audio:devices', this._devices)

    // schedule next update
    setTimeout(() => this._refresh(), DEVICE_REFRESH_INTERVAL)

  }

}
