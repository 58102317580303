
import AudioPlayer from './player.js'
import DeviceController from './device.js'
import utils from '../utils/utils.js'
import { areSamePlaylists } from '../model/playlist.js'

const SWITCH_AVAILABILITY_CHECK_TIMEOUT = 5000;

export default class {

  constructor(bus, http) {
    this.$bus = bus
    this.$http = http
    this._init()
  }

  _init() {
    
    // init other members
    this._controller = new DeviceController(this.$bus, this.$http, {
      onstatus: () => this.emitStatus(),
      onerror: (e) => this.$bus.emit('notification:error', e),
    })

    // now load player
    this._initPlayer()

  }

  async _initPlayer() {

    // new player
    let player = this._controller.loadPlayer()

    // cleanup previous
    if (this._player != null) {

      // if same name
      if (this._player.description() == player.description()) {

        // stop playback
        let stoppedCurrentDevice = false
        if (this.status()?.state != AudioPlayer.STATE_STOPPED) {
          stoppedCurrentDevice = true
          await this.stop(false)
        }

        // check if new player available
        if (stoppedCurrentDevice) {
          this.$bus.emit('audio:device:switch', true)
          let available = await player.checkAvailability(SWITCH_AVAILABILITY_CHECK_TIMEOUT)
          this.$bus.emit('audio:device:switch', false)
          if (!available) {
            console.log('Target device not available. Cancelling switch')
            player.cleanup()
            return
          }
        }
      }

      // now cleanup
      this._player.cleanup?.()

    }

    // save
    this._player = player
    this.$bus.emit('audio:status', this.status())
  }

  device() {
    return this._controller.device()
  }

  devices() {
    return this._controller.devices()
  }

  async setDevice(device) {
    this._controller.switch(device)
    return this._initPlayer()
  }

  //  0: requested device was already selected
  //  1: requested device is available and switched to
  // -1: requested device is not available
  async requestDevice(type) {
    let switched = await this._controller.request(type)
    if (switched == 1) {
      await this._initPlayer()
    }
    return switched
  }

  emitStatus() {
    this.$bus.emit('audio:status', this.status())
  }

  ready() {
    return this._player != null && this._player.ready()
  }

  status() {
    return this?._player?.status()
  }

  on(event, callback) {
    if (event == 'devices') {
      this.$bus.on('audio:devices', callback)
      callback(this.devices())
    }
    if (event == 'device') {
      this.$bus.on('audio:device', callback)
      callback(this.device())
    }
    if (event == 'device:switch') {
      this.$bus.on('audio:device:switch', callback)
    }
    if (event == 'status') {
      this.$bus.on('audio:status', callback)
      callback(this.status())
    }
  }

  async play(info) {

    // automatic switching
    let required = true
    let track = info?.tracks?.[0]
    let service = track?.service
    if (utils.serviceSpecificDevices().includes(service) == false) {
      if (track?.extra?.tidal_match != null) {
        required = false
        service = 'tidal'
      } else {
        service = null
      }
    }

    // now request
    if (service != null) {
      console.log(`Requesting a "${service}" device`)
      let switched = await this.requestDevice(service)
      if (required && switched == -1) {
        this.$bus.emit('notification:error', `${service}_no_device_available`)
        return
      }
    } else {
      console.log(`Requesting a "normal" device`)
      await this.requestDevice()
    }

    // if same playlist then do a seek
    let playlist = this._player?.status()?.playlist
    if (areSamePlaylists(playlist, info)) {
      this.trackSeek(info.start)
      return
    }

    // stop
    if (this._player != null) {
      await this._player.stop()
    } else {
      await this.loadDevice()
    }

    // load playlist
    await this._player.playlist(utils.clone(info))

    // now play
    if (info.autostart != false) {
      await this.trackSeek(info.start || 0)
    } else {
      this.emitStatus()
    }

  }
  
  async stop(doNotSwitchDevice) {
    if (this._player != null) {
      await this._player.stop()
      if (doNotSwitchDevice !== false) {
        this.requestDevice()
      }
      this.emitStatus()
    }
  }
  
  async pause() {
    if (this._player != null && this._player.playing()) {
      await this._player.pause()
      this.emitStatus()
    }
  }
  
  playPause() {
    if (this._player != null) {
      if (this._player.playing()) {
        this._player.pause()
      } else {
        this._player.play()
      }
      this.emitStatus()
    }
  }

  trackSeek(idx) {
    if (this._player != null) {
      this._player.goto(idx)
      this.emitStatus()
    }
  }

  previous() {
    if (this._player != null) {
      this._player.previous()
      this.emitStatus()
    }
  }

  next() {
    if (this._player != null) {
      this._player.next()
      this.emitStatus()
    }
  }

  seek(pct) {
    if (this._player != null) {
      let duration = this._player.duration()
      let position = duration * pct
      this._player.seek(position)
      this.emitStatus()
    }
  }

  volume(pct) {
    if (typeof pct == 'undefined') {
      return this._player?.volume()
    } else  {
      this._player?.volume(pct)
      this.emitStatus()
    }
  }

  volumeDown() {
    return this._player?.volumeDown()
  }

  volumeUp() {
    return this._player?.volumeUp()
  }

  playlistInfo(id, title) {
    this._player.playlist_info(id, title)
  }

  async playNext(tracks) {
    if (Array.isArray(tracks) == false) tracks = [ tracks ]
    if (this._player != null && this._player.tracks() != null && this._player.tracks().length > 0) {
      for (let track of tracks.reverse()) {
        await this._player.playNext(track)
      }
    } else {
      await this.play({
        autostart: true,
        tracks: tracks,
      })
    }
  }

  async enqueue(tracks) {
    if (Array.isArray(tracks) == false) tracks = [ tracks ]
    if (this._player != null && this._player.tracks() != null) {
      for (let track of tracks) {
        await this._player.enqueue(track)
      }
    } else {
      await this.play({
        autostart: false,
        tracks: tracks,
      })
    }
  }

  dequeue(track) {
    if (this._player != null && this._player.tracks() != null) {
      this._player.dequeue(track)
    }
  }

  requeue(info) {
    if (this._player != null && this._player.tracks() != null) {
      this._player.requeue(info.from, info.to)
    }
  }

  empty() {
    if (this._player != null && this._player.tracks() != null) {
      this._player.empty()
    }
  }

}
