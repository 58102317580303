
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    breadcrumbs: [],
    scrollPositions: {},
    browserCache: {},
    searchResults: null,
  },
  mutations: {

    // breadcrumbs
    reset_breadcrumbs(state, item) {
      state.breadcrumbs = [ item ]
    },
    add_breadcrumb(state, breadcrumb) {
      state.breadcrumbs.push(breadcrumb)
    },
    pop_breadcrumb(state) {
      state.breadcrumbs.pop()
    },
    slice_breadcrumbs(state, index) {
      state.breadcrumbs = state.breadcrumbs.slice(0, index)
    },

    // browse
    cache_browser_results(state, info) {

      // evict current and children
      for (let key in state.browserCache) {
        if (key.startsWith(info.id)) {
          delete state.browserCache[key]
        }
      }

      // save
      state.browserCache[info.id] = info
    },
    clear_browser_cache(state) {
      state.browserCache = {}
    },
    
    // scroll
    save_scroll_position(state, payload) {
      state.scrollPositions[payload.id] = payload.pos
    },

    // search
    save_search_results(state, results) {
      state.searchResults = results
    },
  }
})
