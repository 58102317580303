import AudioPlayerRemote  from './remote.js'

export default class extends AudioPlayerRemote {

  type() {
    return 'UPnP'
  }

  actionCommand(action) {
    return `/upnp/${this._options.device.uuid}/${action}`
  }

}
