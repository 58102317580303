import axios from 'axios'
import media from './model/media.js'

// git update-index --assume-unchanged js/http.js
// git update-index --no-assume-unchanged js/http.js
let http = axios.create({
  //baseURL: 'http://localhost:8000',
  //baseURL: 'http://192.168.1.2:8000',
  //baseURL: 'https://mediastation.bonamy.fr',
})

http.getWs = function(url) {
  return new Promise((resolve, reject) => {
    this.get(url).then((response) => {
      if (response == null) {
        reject()
      } else {
        resolve(response.data)
      }
    }).catch((error) => {
      reject(error)
    })
  })
}

http.getItem = async function(id) {
  let item = await this.getWs(`/item?id=${encodeURIComponent(id)}`)
  return media.augment(item)
}

http.getItems = async function(id) {
  let items = await this.getWs(`/browse?id=${encodeURIComponent(id)}`)
  return items.map((item) => media.augment(item))
}

http.getPlaylist = async function(id) {
  let playlist = await this.getWs(`/playlist?id=${encodeURIComponent(id)}`)
  playlist.tracks = playlist.tracks.map((item) => media.augment(item))
  return playlist
}

http.getHistory = async function(mediaType, grouped=1) {
  let items = await this.getWs(`/browse/history/${mediaType}?grouped=${grouped}`)
  return items.map((item) => media.augment(item))
}

http.getLatest = async function(mediaType, grouped=1) {
  let items = await this.getWs(`/browse/latest/${mediaType}?grouped=${grouped}`)
  return items.map((item) => media.augment(item))
}

http.getYearAlbums = async function(year) {
  let albums = await this.getWs(`/search/year/albums?year=${encodeURIComponent(year)}`)
  return albums.map((track) => media.augment(track))
}

http.getArtistAlbums = async function(artist) {
  let albums = await this.getWs(`/search/artist/albums?artist=${encodeURIComponent(artist)}`)
  return albums.map((track) => media.augment(track))
}

http.getAlbumTracks = async function(artist, album) {
  let tracks = await this.getWs(`/search/album/tracks?artist=${encodeURIComponent(artist)}&album=${encodeURIComponent(album)}`)
  return tracks.map((track) => media.augment(track))
}

http.getLyrics = async function(track) {
  if (this.track?.tidal_id != null) {
    return await this.getWs(`/tidal/lyrics?id=${track?.tidal_id}`)
  } else {
    return await this.getWs(`/utils/lyrics?id=${track?.id}`)
  }
}

http.search = async function(query, store) {
  let results = await this.getWs(`/search?type=audio&find=${query}`)
  if (results?.tracks?.length == 0) {
    let starred = query.split(' ').map(term => term + '*').join(' ')
    results = await this.getWs(`/search?type=audio&find=${starred}`)
  }
  store?.commit('save_search_results', { query: query, results: results })
  return results
}

let add_base_url = function(data) {
  if (Array.isArray(data)) {
    for (let el of data) {
      add_base_url(el)
    }
  } else if (typeof data == 'object') {
    // check if it looks like a media item
    if (typeof data.container == 'boolean') {
      if (data.url != null) {
        data.url = http.defaults.baseURL + data.url
      }
      if (data.thumbnail != null) {
        data.thumbnail = http.defaults.baseURL + data.thumbnail
      }
    }
  }
}

// add base url to media items
http.interceptors.response.use(function (response) {
  if (http.defaults.baseURL != null && response?.status == 200 && response?.data != null) {
    if (response.headers['content-type']?.includes('application/json')) {
      add_base_url(response.data)
    }
  }
  return response
}, function (error) {
  return Promise.reject(error)
})

export default http
