<template>
  <div class="audio-controls">
    <PlaylistIcon class="control" :size="computedIconSize" />
    <Lyrics class="control" :size="computedIconSize" />
    <PlaybackDevice class="control" :size="computedIconSize" />
    <VolumeIcon class="control" mode="less" :size="computedIconSize" />
    <VolumeBar class="control" />
    <VolumeIcon class="control" mode="more" :size="computedIconSize" />
  </div>
</template>

<script>

import Lyrics from './Lyrics.vue'
import VolumeBar from './VolumeBar.vue'
import VolumeIcon from './VolumeIcon.vue'
import PlaylistIcon from '../playlist/PlaylistIcon.vue'
import PlaybackDevice from './PlaybackDevice.vue'

export default {
  components: { PlaylistIcon, Lyrics, PlaybackDevice, VolumeBar, VolumeIcon },
  computed: {
    computedIconSize() {
      return 14
    },
  },
}

</script>

<style lang="scss" scoped>
@import '/css/mixins';

.audio-controls {
  @include user-select-none;
  white-space: nowrap;

  .control {
    margin-left: 8px;
    margin-right: 8px;
  }

  .volume {
    width: 100px;
  }

}

</style>
