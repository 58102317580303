
import AudioPlayer  from './player.js'
import Scrobbler from './scrobble.js'
import { Howl } from 'howler'

const kAutoNext = false
const kAutoNextDelay = 250

export default class extends AudioPlayer {

  description() {
    return 'local'
  }
  
  constructor(options) {
    super(options)
    this._scrobbler = new Scrobbler(options)
    this._sound = null
    this._presound = null
    this._preloaded = null
    this._postimer = null
    this._nextimer = null
    this._error = false
  }

  state() {
    if (this._sound == null) {
      return AudioPlayer.STATE_STOPPED
    } else if (this._buffering()) {
      return AudioPlayer.STATE_BUFFERING
    } else if (this._playing()) {
      return AudioPlayer.STATE_PLAYING
    } else {
      return AudioPlayer.STATE_PAUSED
    }
  }

  error() {
    return this._error
  }

  ready() {
    return this._playlist?.tracks?.length > 0
  }

  play() {
    this._sound?.play()
  }

  pause() {
    this._sound?.pause()
  }

  stop() {
    this._reset()
    clearInterval(this._postimer)
    clearTimeout(this._nextimer)
    this._sound?.unload()
    this._sound = null
    this._presound?.unload()
    this._presound = null
    this._preloaded = null
    this._error = false
  }

  position() {
    return this._sound?.seek()
  }

  duration() {
    return this._sound?.duration()
  }

  volume(vol) {
    if (typeof vol == 'undefined') {
      return this._sound?.volume()
    } else if (this._sound != null) {
      this._sound.volume(vol)
      localStorage.localVolume = vol
    }
  }

  seek(pos) {
    this._sound?.seek(pos)
  }

  goto(index) {
    this._index = index || 0
    this._play(false)
  }
  
  _buffering() {
    return ['unloaded', 'loading'].includes(this._sound?.state())
  }

  _playing() {
    return this._sound?.playing()
  }

  _play(fallback = false) {

    // stop timer
    clearInterval(this._postimer)
    clearTimeout(this._nextimer)

    // we need this
    let url = this.track().url

    // check preloaded
    if (this._preloaded == url && this._presound != null) {
      console.log(`Preloaded track match! Swapping sounds`)
      let sound = this._sound
      this._sound = this._presound
      this._sound.play()
      sound?.unload()
      this._presound = null
      this._preload(fallback)
      return
    }

    // load a new one
    this._sound?.unload()
    this._sound = this._load({
      url: url,
      fallback: fallback,
      onload: () => {
        this._sound.play()
        this._preload(fallback)
      },
      onloaderror: (e) => {
        if (fallback == false) {
          console.log(`Error loading ${url}. Trying conversion as fallback.`)
          this._play(true)
        } else {
          console.error(`Error loading ${url}: ${e}`)
          this._processError(e)
        }
      }
    })
  }

  _load(options) {
    return new Howl({
      html5: true,
      autoplay: false,
      src: `${options.url}${options.fallback ? '&quality=hifi&convert=1' : ''}`,
      volume: parseFloat(localStorage.localVolume) || 1.0,
      onload: () => {
        this._error = false
        this._scrobble()
        this._emit('status')
        options.onload()
      },
      onloaderror: (_, e) => options.onloaderror(e),
      onplayerror: (_, e) => {
        console.error(`Playback error ${options.url}: ${e}`)
        this._processError(e)
      },
      onplay: (e) => {
        clearInterval(this._postimer)
        this._postimer = setInterval(() => this._checkPosition(), 500)
        this._scrobble()
        this._emit('status')
        this._emit('play', e)
      },
      onpause: (_) => {
        clearInterval(this._postimer)
        this._postimer = null
        this._scrobble()
        this._emit('status')
      },
      onstop: (_) => {
        this._scrobble()
      },
      onend: (e) => {
        if (!kAutoNext) this.next()
        this._emit('end', e)
      },
    })
  }

  _preload(fallback) {
    
    // clear
    //console.log('Clearing preload')
    this._presound?.unload()
    this._presound = null
    this._preloaded = null

    // index manip
    let nextIndex = this._nextIndex()
    if (nextIndex == 0) {
      return;
    }

    // get url
    let url = this.tracks()?.[nextIndex].url
    //console.log(`Preloading ${url}`)
    this._presound = this._load({
      url: url,
      fallback: fallback,
      onload: () => {
        console.log(`Preloaded ${url}`)
        this._preloaded = url
      },
      onloaderror: (e) => {
        this._presound = null
        if (fallback == false) {
          this._preload(true)
        } else {
          console.error(`Error preloading ${url}: ${e}`)
        }
      }
    })

  }

  _checkPosition() {
    if (kAutoNext && this._sound != null) {
      let pos = this._sound.seek() * 1000
      let dur = this._sound.duration() * 1000
      clearTimeout(this._nextimer)
      this._nextimer = setTimeout(() => {
        console.log(`Automatic next`)
        this.next()
      }, dur - pos - kAutoNextDelay)
    }
  }

  _processError(e) {

    // notify invalid format
    if (e == 4) {
      this._emit('error', 'playback_format_error')
    }

    // rest is generic
    this._error = true
    this._emit('status')
}

  _isPlaylistEditable() {
    return true
  }

  _scrobble() {
    this._scrobbler.process(this.state(), this.track(), this._playlist)
  }

}
