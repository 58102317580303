<template>
  <div id="playlist-modals">
    <b-modal v-model="pick" id="playlist-picker" class="playlist-picker" centered :title="$t('pl_select_load')" :okOnly=true :okTitle="$t('cancel')">
      <b-container class="list-picker" v-for="playlist in playlists" :key="playlist.id">
        <div class="user-select-none hover-highlight cursor-pointer" @click="do_load(playlist)">{{ playlist.title }}</div>
      </b-container>
    </b-modal>
    <b-modal v-model="prompt" id="playlist-name-prompt" class="playlist-name-prompt" centered :title="$t('pl_rename_prompt')" @ok="renamed">
      <b-container>
        <b-form-input v-model="title" />
      </b-container>
    </b-modal>
  </div>
</template>

<script>

export default {
  data: function() {
    return {
      playlist: null,
      pick: false,
      prompt: false,
      playlists: null,
      action: null,
      new_title: null,
    }
  },
  computed: {
    title: {
      get() {
        return this.playlist?.title
      },
      set(title) {
        this.new_title = title
      }
    },
  },
  mounted: function() {

    // actions
    this.$bus.on('playlist:load', this.load)
    this.$bus.on('playlist:save', this.save)
    this.$bus.on('playlist:rename', this.rename)
    this.$bus.on('playlist:delete', this.suppress)
    this.$bus.on('playlist:append', this.append)

    // playlist
    this.$audio.on('status', (status) => {
      this.playlist = status?.playlist
    })
  },
  methods: {

    //
    // load
    //
    load() {
      this.$http.getWs(`/playlists`).then((playlists) => {
        if (playlists?.length > 0) {
          this.playlists = playlists
          this.pick = true
        } else {
          //TODO
        }
      })
    },
    do_load(playlist) {
      this.$bvModal.hide('playlist-picker')
      this.$http.getWs(`/playlist?id=${encodeURIComponent(playlist.id)}`).then((playlist) => {
        this.$audio.play({ ...playlist, start: 0 })
      })
    },

    //
    // save/rename
    //

    save() {
      if (this.playlist.title == null) {
        this.action = 'save'
        this.prompt = true
      } else if (this.playlist.id == null) {
        this.$bus.emit('notification:error', 'Inconsistent playlist state. Please reload it.')
      } else {
        this.do_save()
      }
    },
    async do_save(title) {
      this.playlist.title = title || this.playlist.title
      let pl = await this.$http.put('/playlist', this.playlist)
      this.$audio.playlistInfo(pl.data.id, this.playlist.title)
    },

    //
    // rename
    //

    rename() {
      if (this.playlist.id == null) {
        this.$bus.emit('notification:error', 'Inconsistent playlist state. Please reload it.')
      } else {
        this.action = 'rename'
        this.prompt = true
      }
    },
    async do_rename(title) {
      await this.$http.patch(`/playlist?id=${encodeURIComponent(this.playlist.id)}&patch=rename&title=${encodeURIComponent(title)}`)
      this.$audio.playlistInfo(this.playlist.id, title)
    },

    //
    // delete
    //
    
    async suppress() {
      if (this.playlist.id == null) {
        this.$bus.emit('notification:error', 'Inconsistent playlist state. Please reload it.')
      } else {
        let value = await this.$bvModal.msgBoxConfirm(this.$i18n.t('pl_delete_confirm'), {
          okVariant: 'danger',
          okTitle: 'Yes',
        })
        if (value) {
          this.do_suppress()
        }
      }
    },
    async do_suppress() {
      await this.$http.delete(`/playlist?id=${encodeURIComponent(this.playlist.id)}`)
      this.$audio.playlistInfo(null, null)
    },

    //
    // append
    //
    async append(info) {
      let playlist_id = info.id
      let track = info.track
      if (playlist_id == this.playlist?.id) {
        this.$audio.enqueue(track)
      } else {
        let playlist = await this.$http.getWs(`/playlist?id=${encodeURIComponent(playlist_id)}`)
        playlist.tracks.push(track)
        await this.$http.put('/playlist', playlist)
        this.$bus.emit('notification:success', 'exec_ok')
      }
    },

    //
    // helpers
    //
    
    async renamed() {
      if (this.new_title == null || this.new_title == this.playlist.title) {
      } else if (await this.check_title_used(this.new_title)) {
        this.$bus.emit('notification:error', 'pl_name_conflict')
      } else  if (this.action == 'save') {
        this.do_save(this.new_title)
      } else if (this.action == 'rename') {
        this.do_rename(this.new_title)
      }
    },
    async check_title_used(title) {
      let playlists = await this.$http.getWs('/playlists')
      return playlists.find((p) => p.title == title) != null 
    },

  }
}

</script>

<style scoped>

</style>
