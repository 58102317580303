<template>
  <div :id="id" class="playback-device">

    <Icon name="speaker-fill" class="control" :size="size" @click.native="toggle" />
    <div class="indicator">
      <div class="remote" :class="computedClassRemote" :style="computedStyleRemote" />
      <div v-if="switching" class="remote switching" :class="computedClassRemote" :style="computedStyleRemote"></div>
    </div> 

    <b-popover :target="id" :container="id" triggers="hover" :placement="placement">
      <template #title>{{ $t('playback_device') }}</template>
      <div class="device" v-for="dev in devices" :key="dev.uuid" @click="setDevice(dev, false)">
        <div class="active" v-if="dev.uuid == device.uuid" />
        <img :src="'/icons/tidal_small.png'" v-if="dev.type == 'tidal'" />
        {{ dev.type == 'local' ? $t(dev.description) : dev.description }}
      </div>
    </b-popover>

  </div>
</template>

<script>

export default {
  props: { size: Number, placement: { default: 'top' }, toggleOnClick: { default: true } },
  data: function() {
    return {
      id: `playback-device-${Math.floor(Math.random() * 100)}`,
      devices: this.$audio.devices(),
      device: this.$audio.device(),
      switching: false,
      error: false,
    }
  },
  computed: {
    computedStyleRemote() {
      return {
        'visibility': (this.device.type == 'local' && this.error == false ? 'hidden' : 'visible'),
      }
    },
    computedClassRemote() {
      return this.error ? 'error' : this.device.type
    }
  },
  mounted: function() {

    // device switch
    this.$audio.on('devices', (devices) => this.devices = devices)
    this.$audio.on('device', (device) => this.device = device)
    this.$audio.on('device:switch', (switching) => this.switching = switching)

    // device error
    this.$audio.on('status', (status) =>  this.error = status.error)

  },
  methods: {

    toggle() {
      if (this.toggleOnClick && this.devices?.length > 1) {
        let index = this.devices.findIndex((device) => device.uuid == this.device.uuid)
        index = (index+1) % this.devices.length
        this.setDevice(this.devices[index], true)
      }
    },

    setDevice(device, toggling) {
      this.$audio.setDevice(device)
      if (!toggling) {
        this.$root.$emit('bv::hide::popover', this.id)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.playback-device {
	
  display: inline-block;

  .indicator {

    position: relative;
	
    .remote {
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background-color: var(--highlight-color);

      &.tidal {
        background-color: #ECBA82;
      }

      &.error {
        background-color: rgb(208, 0, 0);
      }
    }

    .switching {

      position: absolute;
      top: 0;

      background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 4px,
        rgba(0, 0, 0, 50%) 4px,
        rgba(0, 0, 0, 50%) 8px
      );
      background-color: transparent;
      background-size: 200% 200%;
      background-position: 100% 100%;
      animation: barberpole 2.5s linear infinite;

      @keyframes barberpole {
        100% {
          background-position: 0% 0%;
        }
      }
    
    }

	}
	
  .popover-body {
		padding-top: 0px;
		padding-left: 0px;
		padding-right: 0px;
		
    .device {
			padding-top: .5rem;
			padding-bottom: .5rem;
			border-bottom: 1px solid #444;
			cursor: pointer;
			text-align: center;
			
			&:first-child {
				padding-top: 0;
			}

      &:last-child {
				border-bottom: 0;
				padding-bottom: 0;
			}

      img {
        height: 12px;
        margin-right: 4px;
      }

      .active {
				display: inline-block;
				background-color: var(--highlight-color);
				--size: 8px;
				width: var(--size);
				height: var(--size);
				border-radius: calc(var(--size)/2);
				margin-right: 4px;
			}
		}
	}
}

</style>
