
import utils from '../utils/utils.js'
import AudioPlayerRemote  from './remote.js'

const AVAILABILITY_CHECK_MAX_DELAY = 2000;
const AVAILABILITY_CHECK_REPEAT_DELAY = 250;

export default class extends AudioPlayerRemote {

  type() {
    return 'TIDAL'
  }

  actionCommand(action) {
    return `/tidal/${action}?uuid=${this._options.device.uuid}`
  }
  
  async checkAvailability(timeoutMs) {
    console.log(`Checking availability of ${this.type()} device ${this.description()}`)
    let command = this.actionCommand('ping')
    let available = null
    let start = Date.now()
    while (true) {

      // check elapsed
      let now = Date.now()

      // if more than allowed timeout
      if (now - start > timeoutMs) {
        return false
      }

      // if still true after all this time then stop waiting
      if (available === true && now - start > AVAILABILITY_CHECK_MAX_DELAY) {
        return true
      }

      // now try to reach
      try {
        console.log(`GET ${command}`)
        let response = await this._http.get(command)
        //console.log(`${now-start} ${response.data}`)
        if (response?.data == 'pong' && available === false) {
          // we switched from !available to available so we are good!
          return true
        }
      } catch (_) {
        available = false
      }

      // wait a cycle
      await utils.wait(AVAILABILITY_CHECK_REPEAT_DELAY)
    }
  }

}
