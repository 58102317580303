<template>
  <div id="playlist-icon" class="playlist-icon">
    <Icon name="music-note-list" class="control" :class="computedClassIcon" :size="size" />
    <b-popover target="playlist-icon" container="playlist-icon" triggers="hover" placement="top" :custom-class="customClassPopover">
      <template #title>
        <span class="title">{{ title || $t('browser_pl_editor') }}</span>
        <span class="menu">
          <Icon name="arrow-up-right-square" :size=12 @click.native="show" />
          <PlaylistMenu :playlist="playlist" container="playlist-icon" />
        </span>
      </template>
      <PlaylistTracks :playlist="playlist" />
    </b-popover>
  </div>
</template>

<script>

import PlaylistMenu from './PlaylistMenu.vue'
import PlaylistTracks from './PlaylistTracks.vue'

export default {
  components: { PlaylistMenu, PlaylistTracks, },
  props: [ 'size' ],
  data: function() {
    return {
      playlist: null,
    }
  },
  computed: {
    computedClassIcon() {
      return this.playlist?.tracks != null ? 'enabled' : 'disabled'
    },
    customClassPopover() {
      return this.playlist?.tracks != null ? 'large' : ''
    },
    title() {
      return this.playlist?.title
    },
  },
  mounted: function() {
    this.$audio.on('status', (status) => {
      this.playlist = status?.playlist
    })
  },
  methods: {
    show() {
      this.$root.$emit('bv::hide::popover')
      this.$bus.emit('playlist:show')
    }
  }
}

</script>

<style lang="scss" scoped>

.playlist-icon {
  display: inline-block;
}

</style>
