<template>
  <div class="security" v-if="config">
    <!--div class="group" path="security">
      <div class="section">{{ $t('cfg_upnp_server') }}</div>
      <div class="field">
        <div class="label">{{ $t('cfg_allowed_ips') }}</div>
        <div class="value"><b-input size="sm" type="text" v-model="config.security.upnp_allowed_ips" /><img src="../../icons/information.png" class="info ip_info" /></div>
      </div>
    </div-->
    <div class="group" path="security">
      <div class="section">{{ $t('cfg_config_pages') }}</div>
      <div class="field">
        <div class="label">{{ $t('cfg_allowed_ips') }}<img src="../../icons/information.png" :title="$t('cfg_ip_tip')" class="info" /></div>
        <div class="value"><array-input v-model="config.security.config_allowed_ips" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_passwd_protect') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.security.config_protected">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label">{{ $t('username') }}</div>
        <div class="value"><b-input size="sm" type="text" v-model="config.security.config_username" :disabled="config.security.config_protected == false" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('password') }}</div>
        <div class="value"><b-input size="sm" type="password" v-model="config.security.config_password" :disabled="config.security.config_protected == false" /></div>
      </div>
    </div>
    <div class="group" path="security">
      <div class="section">{{ $t('cfg_browsing') }}</div>
      <div class="field">
        <div class="label">{{ $t('enabled') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.security.browsing_enabled">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_allowed_ips') }}<img src="../../icons/information.png" :title="$t('cfg_ip_tip')" class="info" /></div>
        <div class="value"><array-input v-model="config.security.browsing_allowed_ips" :disabled="config.security.browsing_enabled == false" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_passwd_protect') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.security.browsing_protected" :disabled="config.security.browsing_enabled == false">
            <option value="never">{{ $t('cfg_passwd_never') }}</option>
            <option value="outside">{{ $t('cfg_passwd_out_subnet') }}</option>
            <option value="always">{{ $t('cfg_passwd_always') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label">{{ $t('username') }}</div>
        <div class="value"><b-input size="sm" type="text" v-model="config.security.browsing_username" :disabled="config.security.browsing_enabled == false || config.security.browsing_protected == 'never'" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('password') }}</div>
        <div class="value"><b-input size="sm" type="password" v-model="config.security.browsing_password" :disabled="config.security.browsing_enabled == false || config.security.browsing_protected == 'never'" /></div>
      </div>
    </div>
    <div class="buttons">
      <b-button @click="save" variant="primary"><img alt="" src="../../icons/tick.png" /><span>{{ $t('save') }}</span></b-button>
    </div>
  </div>
</template>

<script>

import Config from '../../js/model/config.js'
import ArrayInput from '../components/ArrayInput.vue'

export default {
  components: { ArrayInput, },
  data: function() {
    return {
      config: null,
      locales: null,
    }
  },
  methods: {
    async refresh() {
      let config = new Config({ http: this.$http })
      this.config = await config.get()
    },
    async save() {
      // normal save
      try {
        let config = new Config({ http: this.$http })
        await config.save(this.config)
        this.$bus.emit('notification:success', 'exec_ok')
      } catch (err) {
        console.error(err)
        this.$bus.emit('notification:error', 'exec_ko')
      }
    }
  }
}

</script>

<style scoped>

</style>
