<template>
  <div class="app-browser-list" :class="{ 'single-line': singleLine }" @mousedown="scrollStart" @mousemove="scrollMove" @mouseup="scrollEnd">
    <LibraryItem class="app-browser-list-item" v-for="item in _visibleItems" :item="item" :items="items" :key="item.id" /> 
  </div>
</template>

<script>

import LibraryItem from './LibraryItem.vue'

export default {
  components: { LibraryItem, },
  props: [ 'items', 'visibleItems', 'singleLine' ],
  computed: {
    _visibleItems() {
      return this.visibleItems || this.items
    }
  },
  data: function() {
    return {
      scrolling: null,
    }
  },
  mounted: function() {
    this.$bus.on('item:thumbnail', this.refresh)
  },
  methods: {
    async refresh(item) {
      let index = this.items.findIndex(element => element.id === item.id)
      if (index != -1) {
        let updated = await this.$http.getItem(item.id)
        this.$set(this.items, index, updated)
      }
    },
    scrollStart(e) {
      let scrolled = e.target.closest('.single-line')
      if (scrolled == null) return
      this.scrolling = {
        target: e.target,
        scrolled: scrolled,
        pageX: e.pageX
      }
      this.scrolling.scrolled.classList.add('scrolling')
    },
    scrollMove(e) {
      if (this.scrolling == null) return;
      let delta = this.scrolling.pageX - e.pageX
      this.scrolling.target.classList.add('no-next-click')
      this.scrolling.scrolled.scrollBy(delta, 0)
      this.scrolling.pageX = e.pageX
    },
    scrollEnd(e) {
      if (this.scrolling == null) return
      this.scrolling.scrolled.classList.remove('scrolling')
      this.scrolling = null
    },
  }
}
</script>

<style lang="scss" scoped>
@import '/css/mixins';

.app-browser-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  align-content: start;
  padding-bottom: var(--library-item-space);
  gap: calc(var(--library-item-space)*2);

  &.single-line {
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 0;
    @include user-select-none;
  }

  &.scrolling {
    cursor: grab;
  }

  .app-browser-list-item {
    flex: 0 0 auto;
    width: var(--library-item-size);
    //margin: var(--library-item-space);
  }
}

</style>
