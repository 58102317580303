
export default class {

  constructor(options) {
    this._http = options.http
  }

  get(type) {

    if (type == 'photo') {
      return [
        { type: 'year', label: '{node_year}', text: 'cfg_node_year', selected: false, grouped: null },
        { type: 'year_month', label: '{node_month}', text: 'cfg_node_year_month', selected: false, grouped: null },
        { type: 'folder', label: '{node_folder}', text: 'cfg_node_folder', selected: false, grouped: null },
      ]
    }

    if (type == 'music') {
      return [
        { type: 'artist_album', label: '{node_artist}', text: 'cfg_node_artist_album', selected: false, grouped: false },
        { type: 'compilation', label: '{node_compilation}', text: 'cfg_node_compilation', selected: false, grouped: null },
        { type: 'album', label: '{node_album}', text: 'cfg_node_album', selected: false, grouped: false },
        { type: 'genre_album', label: '{node_genre_album}', text: 'cfg_node_genre_album', selected: false, grouped: null },
        { type: 'playlist', label: '{node_playlist}', text: 'cfg_node_playlist', selected: false, grouped: null },
        { type: 'genre_artist_album', label: '{node_genre_artist}', text: 'cfg_node_genre_artist_album', selected: false, grouped: null },
        { type: 'year', label: '{node_year}', text: 'cfg_node_year', selected: false, grouped: null },
        { type: 'folder', label: '{node_folder}', text: 'cfg_node_folder', selected: false, grouped: null },
      ]
    }

    if (type == 'video') {
      return [
        { type: 'folder', label: '{node_folder}', text: 'cfg_node_folder', selected: false, grouped: null },
      ]
    }


  }

  process(nodes, config, type) {

    // select the tree
    let tree = config.navigation.trees[type]

    // iterate reverse: we sort items at 1st position
    // so doing this reverse yields the right final order
    for (const node of tree.nodes.reverse()) {

      // 1st find the node
      let n = nodes.find(e => e.label == node.label)
      if (n == null) {
        continue
      }

      // move it to 1st position
      nodes.sort((x,y) => {
        return x == n ? -1 : y == n ? 1 : 0
      })

      // group if can be grouped
      if (n.grouped != null) {
        n.grouped = node.grouped
      }

      // activate it
      n.selected = true

    }

  }

  async save(nodes, type) {

    // build json
    let json_nodes = []
    for (const node of nodes) {
      if (node.selected) {
        let json_node = { label: node.label, levels: [], grouped: node.grouped }
        let types = node.type.split('_')
        for (let i=0; i<types.length; i++) {
          let json_level = { type: types[i] }
          json_node.levels.push(json_level)
        }
        json_nodes.push(json_node)
      }
    }

    // done
    //console.log(json_nodes)

    // save
    await this.submit({ nodes: json_nodes }, type)

  }

  async submit(json, type) {
    await this._http.put(`/control/tree/${type}`, json)
  }

}
