<template>
  <div class="search-bar" :class="promptClass">
    <Icon name="search" :size="12" />
    <b-input size="sm" type="text" v-model="query" placeholder="Search" autocomplete="off" autocorrect="off" spellcheck="false" @keyup="onKeyUp"/>
  </div>
</template>

<script>

import media from '../../js/model/media.js'

export default {
  data: function () {
    return {
      query: '',
      timer: null,
    }
  },
  computed: {
    promptClass() {
      return ''
    },
  },
  mounted: function () {
    this.$bus.on('search:clear', () => this.query = '')
    this.$bus.on('search:set', (query) => this.query = query)
  },
  methods: {
    onKeyUp(event) {
      clearTimeout(this.timer)
      if (event.charCode == 13) this.search()
      if (this.query.length == 0) {
        if (this.$route.name == 'search') {
          this.$router.back()
        }
        return
      } else {
        this.timer = setTimeout(() => this.search(), 500)
      }
    },
    async search() {

      // get result
      let results = await this.$http.search(this.query, this.$store)
      if (results.length == 0) {
        return
      }

      // build new destination and go there
      let destination = { name: 'search', params: { query: this.query } }
      if (this.$route.name != 'search') {
        this.$router.push(destination)
      } else if (this.$route.params.query != this.query) {
        this.$router.replace(destination)
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.search-bar {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.3);
  height: 32px;
  padding-left: 16px;
  padding-right: 8px;
  border-radius: 16px;

  &:focus-within {
    background-color: white;
    i {
      color: black !important;
    }
  }

  i {
    margin-top: 3px;
    margin-right: 8px;
  }

  input {
    width: 128px;
    transition: all 250ms ease;
    color: white;
    background-color: transparent;
    outline: none;
    border: none;
    &:focus {
      width: 256px;
      transition: all 250ms ease;
      outline: none;
      border: none;
      box-shadow: none;
      color: black
    }
  }
}
</style>