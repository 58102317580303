<template>
  <div class="playbar">
    <b-container class="text-center">
      <b-row class="align-items-center controls">
        <b-col class="text-nowrap">
          <Icon name="stop-fill" class="control spacer" :class="classSkip" :size=18 />
          <Icon name="skip-start-fill" class="control" :class="classSkip" :size=24 @click.native="skipPrevious" />
          <div class="control playpause" :class="classPlayPause">
            <div class="circle"></div>
            <Icon :name="iconPlayPause" :size=17 :class="iconPlayPause.split('-')[0]" @click.native="playPause" />
          </div>  
          <Icon name="skip-end-fill" class="control" :class="classSkip" :size=24 @click.native="skipNext" />
          <Icon name="stop-fill" class="control stop" :class="classStop" :size=18 @click.native="stop" />
        </b-col>
      </b-row>
      <b-row class="align-items-center">
        <b-col cols="12" class="text-center text-nowrap">
          <ThumbBar class="seek-bar-item" :enabled="canCommand" :value="position" :callback="seek" :continuous="false" :thumb-hidden="true" />
        </b-col>
      </b-row>
      <b-row class="align-items-center seek-bar-item seek-bar-info mx-auto">
        <b-col cols="4" class="time text-left text-nowrap">
          <span>{{ positionStr }}</span>
        </b-col>
        <b-col cols="4" class="quality text-center text-nowrap">
          <div v-if="qualityStr != ''">
            <img :src="`/icons/audio_${quality}.png`" />
            <span>{{ qualityStr }}</span>
          </div>
          <div v-else>&nbsp;</div>
        </b-col>
        <b-col cols="4" class="time text-right text-nowrap">
          <span>{{ durationStr }}</span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import ThumbBar from '../components/ThumbBar.vue'
import utils from '../../js/utils/utils.js'
import media from '../../js/model/media.js'

export default {
  components: { ThumbBar, },
  data: function() {
    return {
      status: null,
    }
  },
  computed: {
    canSkip() {
      return this.status?.error == false && this.status?.playlist?.tracks?.length > 1
    },
    canCommand() {
      return this.status?.error == false && this.status?.ready == true && this.status?.playlist?.tracks?.length > 0
    },
    mediaItem() {
      let track = this.status?.track()
      return track == null ? null : media.augment(track)
    },
    positionStr() {
      return utils.formatTime(this.status?.position)
    },
    durationStr() {
      return utils.formatTime(this.status?.track()?.duration_raw)
    },
    quality() {
      return this.mediaItem?.quality
    },
    qualityStr() {
      if (['', 'undefined', 'lossy'].includes(this.quality)) {
        return ''
      } else {
        return media.getAudioQualityDesc(this.quality)
      }
    },
    iconPlayPause() {
      if (this.status?.playing == null) { return 'play-fill'}
      return this.status?.playing ? 'pause-fill' : 'play-fill'
    },
    classPlayPause: function() {
      return {
        'enabled': this.canCommand,
        'disabled': !this.canCommand,
        'buffering': !this.status?.error && this.status?.buffering
      }
    },
    classSkip() {
      return this.canSkip ? 'enabled' : 'disabled'
    },
    classStop() {
      return this.status?.error || this.canCommand ? 'enabled' : 'disabled'
    },
    position() {
      return (this.status?.position / this.status?.track()?.duration_raw)
    }
  },
  mounted: function() {
    requestAnimationFrame(() => this.step())
  },
  methods: {
    stop() {
      this.$audio.stop()
    },
    playPause() {
      if (this.canCommand) {
        this.$audio.playPause()
      }
    },
    skipPrevious() {
      if (this.canSkip) {
        this.$audio.previous()
      }
    },
    skipNext() {
      if (this.canSkip) {
        this.$audio.next()
      }
    },
    seek(pct) {
      if (this.canCommand) {
        this.$audio.seek(pct)
      }
    },
    step() {
      this.status = this.$audio.status()
      requestAnimationFrame(() => this.step())
    }
  }
}

</script>

<style lang="scss" scoped>
@import '/css/mixins';

.playbar {
  @include user-select-none;

	.controls {

    position: relative;
    top: 6px;

    .control {
      margin-left: 12px;
      margin-right: 12px;
      cursor: pointer;
    
      &.spacer {
        visibility: hidden;
      }
      
      &.enabled {
        color: var(--text-color) !important;
      }
      
      &.disabled {
        color: var(--disabled-text-color) !important;
      }

      &.playpause {

        position: relative;
        display: inline-block;
        margin-left: 20px;
        margin-right: 20px;

        .circle {
          position: absolute;
          left: -7px;
          top: -2px;
          width: 37px;
          height: 37px;
          border-radius: 50%;
          border: 2px solid var(--text-color);
        }
        
        i {
          position: relative;
          top: -1px;
          &.play {
            left: 1px;
          }
        }

        &.buffering {
          i {
            color: var(--faded-text-color) !important;
          }
          .circle {
            border: none;
            border-top: 2px solid var(--faded-text-color);
            animation: spin 2s linear infinite;
          }
        }

        &.disabled {
          i {
            color: var(--disabled-text-color) !important;
          }
          .circle {
            border-color: var(--disabled-text-color)
          }
        }

        @keyframes spin { 
          from { 
            transform: rotate(0deg); 
          } to { 
            transform: rotate(360deg); 
          }
        }

      }

    }

  }

  .seek-bar-item {
    width: 100%;
    min-width: 50px;
    max-width: 440px;
    margin-left: 8px;
    margin-right: 8px;

    &.seek-bar-info {
      position: relative;
      top: -6px;
    }
  }

  .time, .quality {
    padding: 0;
    span {
      color: var(--disabled-text-color);
      font-variant-numeric: tabular-nums;
      font-size: 9pt;
    }
  }

  .quality {
    img {
      height: 10px;
      margin-right: 4px;
      opacity: var(--faded-opacity);
    }

  }
}

@import 'css/mixins';
@include media-phone() {
  .playbar {
    .controls {
      .time {
        display: none;
      }
    }
  }
}

</style>
