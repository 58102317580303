<template>
  <i class="icon" :class="computedClass" :style="computedStyle" />
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'white',
    },
    size: {
      type: Number,
      default: 32
    }
  },
  computed: {
    computedClass() {
      return `bi-${this.name}`
    },
    computedStyle() {
      return {
        'color': this.color,
        'font-size': this.size + 'pt',
      }
    }
  }
}
</script>

<style scoped>
.icon {
  vertical-align: middle;
  width: auto;
  height: auto;
}
</style>
