<template>
  <div class="video-player" v-if="movie">
    <video :src="movie.url" :width="size.width" :height="size.height" autoplay controls @error="onError" />
  </div>
</template>

<script>
export default {
  props: [ 'movie' ],
  computed: {
    size() {
      let hratio = this.movie.width / (window.innerWidth - 64)
      let vratio = this.movie.height / (window.innerHeight - 64)
      let ratio = Math.max(hratio, vratio)
      return {
        width: this.movie.width / ratio,
        height: this.movie.height / ratio
      }
    }
  },
  methods: {
    onError() {
      this.$router.back()
      this.$bus.emit('notification:error', 'An error occured during playback')
    }
  }
}
</script>

<style lang="scss" scoped>

.video-player {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

</style>
