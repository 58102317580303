
import Vue from 'vue'
import VueRouter from 'vue-router'
import Browser from '../src/browser/Browser.vue'
import SearchResults from '../src/browser/SearchResults.vue'
import ThumbnailEditor from '../src/browser/ThumbnailEditor.vue'
import LyricsViewer from '../src/browser/LyricsViewer.vue'
import MusicAlbum from '../src/browser/MusicAlbum.vue'
import Movie from '../src/video/Movie.vue'
import VideoPlayer from '../src/video/VideoPlayer.vue'
import ImageViewer from '../src/browser/ImageViewer.vue'
import Import from '../src/import/Import.vue'
import Configuration from '../src/configuration/Configuration.vue'
import media from './model/media.js'

Vue.use(VueRouter)

let router = new VueRouter({

  // browser at the end so it acts as a catch-all route

  routes: [
    {
      path: '/',
      redirect: {
        name: 'browser',
        params: {
          type: 'music',
          id: media.getRoots()['audio'].id
        }
      }
    },
    {
      name: 'artist_albums',
      path: '/artist/:artist',
      component: Browser,
      props: true
    },
    {
      name: 'year_albums',
      path: '/year/:year',
      component: Browser,
      props: true
    },
    {
      name: 'search',
      path: '/search/:query',
      component: SearchResults,
      props: true 
    },
    {
      name: 'album_by_artist',
      path: '/album/:artist([^\/]*)/:album',
      component: MusicAlbum,
      props: true
    },
    {
      name: 'album_by_id',
      path: '/album/:id',
      component: MusicAlbum,
      props: true
    },
    {
      name: 'playlist',
      path: '/playlist/:id/:slug',
      component: MusicAlbum,
      props: true
    },
    {
      name: 'movie',
      path: '/movie/:id/:slug',
      component: Movie,
      props: true
    },
    {
      name: 'video_player',
      path: '/video/play/:slug',
      components: {
        fullscreen: VideoPlayer,
      },
      props: {
        fullscreen: true,
      }
    },
    {
      name: 'image',
      path: '/image',
      component: ImageViewer,
      props: true,
    },
    {
      name: 'lyrics',
      path: '/lyrics/:slug',
      component: LyricsViewer,
      props: true,
    },
    {
      name: 'slideshow',
      path: '/slideshow',
      component: ImageViewer,
      props: true,
    },
    {
      name: 'edit_thumbnail',
      path: '/edit/:id',
      component: ThumbnailEditor,
      props: true,
    },
    {
      name: 'import',
      path: '/import',
      component: Import,
    },
    {
      name: 'configuration',
      path: '/settings',
      component: Configuration,
    },
    {
      name: 'browser',
      path: '/:type/:id',
      component: Browser,
      props: true
    },
  ]
})

router.beforeEach((to, from, next) => {
  //console.log(to.path)
  next()
})

export default router
