<template>
  <div class="app-browser">
    <div v-if="error">
      {{ error }}
    </div>
    <div v-else>
      <div class="filter" v-if="showFilter">
        <Icon name="funnel" :size="12" />
        <b-input size="sm" autocomplete="off" autocorrect="off" v-model="filter" ref="filterbar" />
      </div>
      <MediaSection ref="history" :id="id" title="browser_section_history" :items="history" storage_key="history" />
      <MediaSection ref="latest" :id="id" title="browser_section_latest" :items="latest" storage_key="latest" />
      <div class="section">
        <div class="title" v-if="hasUpperSection">
          <span><Icon name="square-fill" :size="10"/></span>
          <span>{{ $t('browser_section_library') }}</span>
        </div>
        <LibraryItemList :items="items" :visibleItems="filteredItems" />
      </div>
    </div>
  </div>
</template>

<script>

import LibraryItemList from './LibraryItemList.vue'
import MediaSection from './MediaSection.vue'
import media from '../../js/model/media'

export default {
  components: { LibraryItemList, MediaSection },
  props: [ 'id', 'item' ],
  data: function() {
    return {
      items: null,
      history: null,
      latest: null,
      showFilter: false,
      filter: null,
      error: null,
    }
  },
  computed: {
    filteredItems() {
      return this.filter == null ? this.items : this.items?.filter((e) => e.title.toLowerCase().includes(this.filter.toLowerCase()))
    },
    hasUpperSection() {
      return (this.history && this.history.length > 0) || (this.latest && this.latest.length > 0)
    }
  },
  beforeRouteEnter: function(to, from, next) {
    next()
  },
  beforeRouteLeave: function(to, from, next) {
    this.$store.commit('save_scroll_position', { id: this.id, pos: this._scrollLibrary() })
    this.$refs.history?.saveScroll()
    this.$refs.latest?.saveScroll()
    next()
  },
  beforeRouteUpdate: function(to, from, next) {
    if (to.name == 'browser' && from.name == 'browser' && to.path.includes(from.path) == false) {
      this.$store.commit('save_scroll_position', { id: this.id, pos: 0 })
      this.$refs.history?.saveScroll(0)
      this.$refs.latest?.saveScroll(0)
    } else {
      this.$store.commit('save_scroll_position', { id: this.id, pos: this._scrollLibrary() })
      this.$refs.history?.saveScroll()
      this.$refs.latest?.saveScroll()
    }
    next()
  },
  created: function() {
    this.load()
  },
  mounted: function() {
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy: function() {
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {

    async load() {
      if (this.$route.name == 'artist_albums') {
        this.loadArtistAlbums()
      } else if (this.$route.name == 'year_albums') {
        this.loadYearAlbums()
      } else {
        this.loadById()
      }
    },

    async loadArtistAlbums() {

      // get
      let artist = await this.$route.params.artist

      // update history
      this.$store.commit('add_breadcrumb', artist)

      // simple
      this.items = await this.$http.getArtistAlbums(artist)

    },

    async loadYearAlbums() {

      // get
      let year = await this.$route.params.year

      // update history
      this.$store.commit('add_breadcrumb', year)

      // simple
      this.items = await this.$http.getYearAlbums(year)

    },

    async loadById() {

      try {

        // need an id
        if (this.id == null) {
          throw 'Fatal error'
        }

        // get data from cache
        let cached = this.$store.state.browserCache[this.id]

        // if automatic start destination we need more info
        let item = this.item || cached?.item
        if (item == null) {
          item = await this.$http.getItem(this.id)
        }

        // update history
        if (item.isRoot()) {
          this.$store.commit('reset_breadcrumbs', item)
        } else {
          let breadcrumbs = this.$store.state.breadcrumbs
          let index = breadcrumbs.findIndex((e) => e.id == item.id)
          if (index == -1) {
            this.$store.commit('add_breadcrumb', item)
          } else {
            this.$store.commit('slice_breadcrumbs', index+1)
          }
        }

        // now load children
        this.items = cached?.items
        if (this.items == null) {
          this.items = await this.$http.getItems(this.id)
        }
        this.error = null

        // load history
        this.history = null
        if (this.id == media.getRoots().audio.id) {
          this.history = await this.$http.getHistory('music')
        }

        // and load latest
        this.latest = null
        if (this.id == media.getRoots().audio.id) {
          this.latest = await this.$http.getLatest('music')
        } else if (this.id == media.getRoots().video.id) {
          this.latest = await this.$http.getLatest('videos')
          for (let latest of this.latest) {
            latest.id = `${media.getRoots().video.id}${media.separator}${latest.id}`
          }
        }

        // restore scroll
        this.$refs.history?.restoreScroll()
        this.$refs.latest?.restoreScroll()
        this.$nextTick(() => {
          this._scrollLibrary(this.$store.state.scrollPositions[this.id])
        })

        // manage cache
        this.$store.commit('cache_browser_results', { id: this.id, item: item, items: this.items })

      } catch (error) {
        console.error(error)
        this.items = null
        this.error = error
      }
    
    },

    onKeyDown(ev) {
      if (ev.keyCode == 27) {
        this.filter = null
        this.showFilter = false
      } else if (ev.target.nodeName != 'INPUT' && ev.target.nodeName != 'TEXTAREA') {
        if (ev.keyCode >= 32 && ev.key.length == 1) {
          // do not activate if 1st char is space
          if (ev.keyCode != 32 || this.filer?.length) {
            if (this.showFilter == false) {
              this.showFilter = true
              this.$nextTick(() => {
                this.$refs.filterbar.focus()
              })
            }
          }
        }
      }
    },

    onKeyUp(ev) {
      if (!this.filter?.length) {
        this.showFilter = false
      }
    },
    
    _scrollLibrary(pos) {
      if (pos == null) {
        return document.getElementById('app-content')?.scrollTop
      } else {
        this.$nextTick(() => {
          document.getElementById('app-content')?.scrollTo(0, pos || 0)
        })
      }
    },

  }
}
</script>

<style lang="scss" scoped>
@import '/css/mixins';
.app-browser {

  .filter {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: center;

    width: 25%;
    height: 32px;
    min-width: 300px;
    padding-left: 16px;
    padding-right: 8px;
    border-radius: 16px;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    left: 30%;
    top: 16px;

    i {
      margin-top: 3px;
      margin-right: 8px;
    }
    
    input {
      background-color: transparent;
      color: white;
      outline: none;
      border: none;
      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }

  .section {
    margin-bottom: var(--library-item-space);
    .title {
      @include user-select-none;
      text-transform: uppercase;
      font-size: 14pt;
      font-weight: bold;
      color: var(--text-color);
      margin-bottom: var(--library-item-space);
      cursor: pointer;

      i {
        display: inline-block;
        width: 24px;
        position: relative;
        text-align: center;
        top: -2px;
        left: -6px;
      }

      .scroll {
        margin-right: 16px;
        float: right;
      }
    }

  }
} 

</style>
