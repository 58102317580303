
import AudioPlayer  from './player.js'
import { areSamePlaylists } from '../model/playlist.js'

export default class extends AudioPlayer {

  // to be overridden by implementation
  type() { throw 'not overridden' }
  actionCommand(_) { throw 'not overridden' }
  shouldPing() { return true; }

  constructor(options) {
    super(options)
    this._resetSelf()
    this._http = options.http
    let onSuccess = this.updateStatus.bind(this)
    if (this.shouldPing() == false) {
      onSuccess()
    } else {
      this._http.getWs(this.actionCommand('ping')).then(() => {
        onSuccess()
      }).catch(() => {
        console.log(`${this.description()} not available`)
        options.bus.emit('audio:device:invalid')
      })
    }
  }

  description() {
    return this._options.device.description
  }

  cleanup() {
    console.log(`Cleaning up ${this.type()} device "${this.description()}"`)
    this._cancelTick()
    this._cancelStatus()
  }

  _reset() {
    super._reset()
    this._resetSelf()
  }

  _resetSelf() {
    this._error = null
    this._ready = true
    this._state = AudioPlayer.STATE_STOPPED
    this._volume = null
    this._position = null
    this._quickStatuses = 0
  }

  errorMsg() {
    return `${this.description()} error. Please retry later.`
  }

  async playlist(playlist) {
    this._cancelStatus()
    super.playlist(playlist)
    if (playlist == null) await this.updateStatus()
    else await this._queuePlaylist();  
  }

  ready() {
    return this._ready
  }

  state() {
    return this._state
  }

  error() {
    return this._error != null
  }

  async play() {
    if (this.ready()) {
      if (await this.runRemoteAction('play')) {
        this._state = AudioPlayer.STATE_PLAYING
        this._ready = true
        this._installTick()
        //this._emit('status')
        this.updateStatus(true)
      } else {
        this._reset()
        this._display_error(this.errorMsg())
      }
    }
  }

  async pause() {
    if (this.ready()) {
      await this.runRemoteAction('pause')
      this._state = AudioPlayer.STATE_PAUSED
      this._emit('status')
      this.updateStatus(true)
    }
  }

  async stop() {
    this._cancelTick()
    //this._cancelState()
    await this.runRemoteAction('stop')
    this._reset()
    this._emit('status')
  }

  position() {
    return this._position
  }

  duration() {
    return this.track()?.duration_raw
  }

  volume(vol) {
    if (vol == null) {
      return this._volume
    } else {
      this._setVolume(vol)
    }
  }

  async volumeDown() {
    await this.runRemoteAction(`volume/down`)
  }

  async volumeUp() {
    await this.runRemoteAction(`volume/up`)
  }

  async seek(pos) {
    if (this.ready() && this.playing()) {
      this._cancelTick()
      this._position = Math.round(pos)
      await this.runRemoteAction(`timeseek/${this._position}`)
      this._installTick()
      this.updateStatus(true)
    }
  }

  async goto(idx) {
    if (this.ready()) {

      // track seek
      if (await this.runRemoteAction(`trackseek/${idx}`)) {
        this._index = idx
        this._position = 0
      } else {
        this._reset()
        this._display_error(this.errorMsg())
        return
      }

    }

  }

  async enqueue(track) {
    if (this.ready()) {
      if (super.enqueue(track)) {
        await this._queueTrack(track)
        this.updateStatus(true)
      }
    }
  }

  async dequeue(index) {
    if (this.ready()) {
      if (super.dequeue(index)) {
        await this.runRemoteAction(`dequeue/${index}`)
        this.updateStatus(true)
      }
    }
  }

  async requeue(from, to) {
    if (this.ready()) {
      if (super.requeue(from, to)) {
        this._cancelStatus()
        to = from > to ? to : to + 1
        await this.runRemoteAction(`reorderqueue/${from}/${to}`)
        this.updateStatus(true)
      }
    }
  }

  async playlist_info(id, title) {
    super.playlist_info(id, title)
    await this.runRemoteAction('renamequeue', {
      id: id,
      title: title,
    })
  }

  async playNext(track) {
    if (this.ready()) {
      if (super.playNext(track)) {
        let state = await this.getStatus()
        let index = state?.index || 0
        await this._queueTrack(track, index+1)
        this.updateStatus(true)
      }
    }
  }

  async _setVolume(vol) {
    this._cancelStatus()
    await this.runRemoteAction(`volume/${Math.round(vol*100)}`)
    this._volume = vol
    this._emit('status')
    this.updateStatus(true)
  }

  runRemoteAction(action, data) {
    return this.runRemoteCommand(this.actionCommand(action), data)
  }

  async runRemoteCommand(command, data) {
    try {
      let response = await this.executeRemoteCommand(command, data)
      if (response == null || response.data.status == 'error') {
        throw response.data
      }
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  executeRemoteCommand(command, data) {
    console.log(`POST ${command}`)
    return this._http.request({
      method: 'POST',
      url: command,
      data: data,
    })
  }

  async updateStatus(onAction, quickStatuses) {

    // clear previous
    this._cancelStatus()

    // if from action then wait a bit and run N quick refreshes
    if (onAction === true) {
      setTimeout(() => this.updateStatus(), 500)
      this._quickStatuses = quickStatuses ?? 3
      return
    }

    // do it (dedicated nethod to allow override)
    await this.getAndProcessStatus()

    // emit
    this._emit('status')

    // buffering requires fast update
    let period = --this._quickStatuses >= 0 ? 1000 : 5000
    if (this._state == AudioPlayer.STATE_BUFFERING) {
      period = 500
    }

    // next
    this._stateTimer = setTimeout(() => this.updateStatus(), period)

  }

  async getStatus() {
    return await this._http.getWs(this.actionCommand('status'))
  }

  async getAndProcessStatus() {

    // get status
    try {

      let status = await this.getStatus()
      if (status == null || status.playlist?.tracks == null || status.playlist.tracks.length == 0) {
        this._reset()
      } else {

        // get basic info
        this._ready = true
        this._state = status.state
        this._error = status.error
        this._index = parseInt(status.index)
        this._position = parseInt(status.position)

        // get tracks
        let playlist = status.playlist
        let samePlaylists = areSamePlaylists(this._playlist, playlist)

        // compare
        if (!samePlaylists) {
          this._playlist = {
            id: playlist.id,
            title: playlist.title,
            tracks: playlist.tracks,
            editable: this._isPlaylistEditable(),
            start: this._index
          }
        }

        // install tick
        if (this.ready() && this.playing()) {
          this._installTick()
        }

      }

      // always get volume
      this._volume = status.volume !== null ? parseInt(status.volume) / 100 : null


    } catch (err) {
      console.warn(err)
    }
  
  }

  async _queueTrack(track, position) {
    await this.runRemoteAction('enqueue', {
      base_url: window.location.origin,
      position: position || -1,
      track: track,
    })
  }

  async _queuePlaylist() {

    // reset
    this._ready = false
    this._state = AudioPlayer.STATE_STOPPED

    // check
    if (this.has_tracks() == false) {
      return
    }

    // queue full playlist
    if (await this.runRemoteAction('queue', {
      base_url: window.location.origin,
      playlist: {
        id: this._playlist.id,
        title: this._playlist.title,
        tracks: this._playlist.tracks,
      },
      index: this._playlist.start,
    }) == false) {
      return
    }

    // done
    this._ready = true

    // run state
    this._emit('status')
    this.updateStatus(true)

  }

  _tick() {
    if (this.playing()) {
      this._position++
      if (this.duration() && this._position >= this.duration() - 2) {
        this.updateStatus(true, 5)
      } else {
        this._emit('status')
      }
    }
  }

  _installTick() {
    this._cancelTick()
    this._tickTimer = setInterval(() => this._tick(), 1000)
  }

  _cancelTick() {
    clearInterval(this._tickTimer)
    this._tickTimer = null
  }

  _cancelStatus() {
    clearTimeout(this._stateTimer)
    this._stateTimer = null
  }

  _isPlaylistEditable() {
    return true
  }

}
