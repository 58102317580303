<template>
  <ThumbBar class="volume" :enabled="$audio.ready() && volume != null && volume >= 0" :value="volume" :callback="setVolume" />
</template>

<script>

import ThumbBar from '../components/ThumbBar.vue'

export default {
  components: { ThumbBar, },
  data: function() {
    return {
      volume: null,
    }
  },
  mounted: function() {
    this.$audio.on('status', (status) => {
      this.volume = status?.volume
    })
  },
  methods: {
    setVolume(pct) {
      this.volume = pct
      this.$audio.volume(pct)
      this.savedVolume = 0
    },
  }
}

</script>

<style scoped>

</style>
