<template>
  <div class="bread-crumb">
    <span @click="back()" v-if="showBack">
      {{ $t('back') }}
    </span>
    <span v-if="current">
      {{ typeof current == 'string' ? current : typeof current.title == 'function' ? $t(current.title()) : current.title }}
    </span>
  </div>
</template>

<script>

export default {
  computed: {
    showBack() {
      if (['configuration', 'import'].includes(this.$route.name)) {
        return false
      } else if (['lyrics', 'search', 'album'].includes(this.$route.name)) {
        return true
      } else {
        return this.$store.state.breadcrumbs.length > 1
      }
    },
    current() {
      if (this.stack.length == 0)  return null
      else return this.stack.slice(-1)[0]
    },
    stack() {
      if (this.$route.name == 'configuration') {
        return [ { title: this.$t('cfg_config_pages') } ]
      } else if (this.$route.name == 'import') {
        return [ { title: this.$t('import_title') } ]
      } else if (this.$route.name == 'lyrics') {
        return [ { title: this.$route.params.lyrics.title } ]
      } else if (this.$route.name == 'search') {
        return [ { title: this.$route.params.query } ]
      } else if (this.$route.name == 'album' && this.$route.params.id == '') {
        return [ { title: this.$route.params.item.album } ]
      } else {
        return this.$store.state.breadcrumbs
      }
    }
  },
  methods: {
    back(idx) {
      if (idx == null) this.$router.back()
      else if (idx != this.stack.length - 1) {
        this.$router.go(-this.stack.length + 1 + idx)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import '/css/mixins';

.bread-crumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bread-crumb span {
  text-transform: uppercase;
  font-size: 14pt;
  font-weight: bold;
  color: var(--text-color);
  @include user-select-none;
}

.bread-crumb span:not(:last-child) {
  cursor: pointer;
}

.bread-crumb span:not(:last-child)::after {
  content: '|';
  margin: 0px 16px;
  font-weight: normal;
  color: var(--disabled-text-color);
  border-bottom-width: 0px !important;
}

.bread-crumb span:not(:last-child) span:hover {
  color: var(--highlight-color);
}

</style>