<template>
  <div class="options" v-if="config">
    <div class="group" path="general">
      <div class="section">{{ $t('cfg_server') }}</div>
      <div class="field">
        <div class="label">{{ $t('cfg_friendly_name') }}</div>
        <div class="value"><b-input size="sm" type="text" v-model="config.general.friendly_name" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_language') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.general.locale" @change="lang">
          <option v-for="locale in locales" :key="locale.id" :value="locale.id">
            {{ locale.name }}
          </option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_update_check') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.general.update_check">
            <option value="none">{{ $t('cfg_update_check_none') }}</option>
            <option value="stable">{{ $t('cfg_update_check_stable') }}</option>
            <option value="beta">{{ $t('cfg_update_check_beta') }}</option>
          </b-select>
        </div>
      </div>
      <!--div class="field">
        <div class="label">{{ $t('cfg_upnp_enabled') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.general.upnp_enabled">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
          <span>{{ $t('cfg_requires_restart') }}</span>
        </div>
      </div-->
    </div>
    <div class="group" path="general">
      <div class="section">{{ $t('cfg_scanning') }}</div>
      <div class="field">
        <div class="label">{{ $t('cfg_ignore_folders') }}</div>
        <div class="value"><array-input v-model="config.general.ignored_paths" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_ignore_files') }}</div>
        <div class="value"><array-input v-model="config.general.ignored_files" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_scan_background') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.general.scan_background">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_scan_startup') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.general.scan_startup">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_scan_scheduled') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.general.scan_scheduled">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_scan_time') }}</div>
        <div class="value"><b-input size="sm" type="text" v-model="config.general.scan_time" style="width: 32px; text-align: right;"/> <span>{{ $t('cfg_time') }}</span></div>
      </div>
    </div>
    <div class="group" path="navigation">
      <div class="section">{{ $t('cfg_navigation') }}</div>
      <div class="field">
        <div class="label">{{ $t('cfg_use_itunes_album_artist') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.navigation.use_album_artist">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_group_compilations') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.navigation.show_compilations">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label"><span>{{ $t('cfg_sort_artist_albums') }}</span><img src="../../icons/information.png" :title="$t('cfg_sort_artist_albums_hint')" class="info"/></div>
        <div class="value">
          <b-select size="sm" v-model="config.navigation.sort_artist_albums">
            <option value="alpha">{{ $t('cfg_sort_alpha') }}</option>
            <option value="chrono">{{ $t('cfg_sort_chrono') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_remove_prefixes') }}</div>
        <div class="value"><array-input v-model="config.navigation.mute_prefixes" /></div>
      </div>
    </div>
    <div class="group" path="general">
      <div class="section">{{ $t('cfg_thumbnails') }}</div>
      <div class="field">
        <div class="label"><span>{{ $t('cfg_artist_thumbnail') }}</span></div>
        <div class="value">
          <b-select size="sm" v-model="config.general.artist_thumbnail_type">
            <option value="montage">{{ $t('cfg_artist_thumbnail_montage') }}</option>
            <option value="download">{{ $t('cfg_artist_thumbnail_download') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label"><span>{{ $t('cfg_search_video_thumbnail') }}</span></div>
        <div class="value">
          <b-select size="sm" v-model="config.general.search_video_thumbnails">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_search_video_cleanup') }}</div>
        <div class="value"><array-input v-model="config.general.search_video_filename_cleanup" /></div>
      </div>
      <!--div class="field">
        <div class="label"><span>{{ $t('cfg_search_video_thumbs_mask') }}</span></div>
        <div class="value"><b-input size="sm" type="text" v-model="config..search_video_thumbs_mask" /> <span>{{ $t('cfg_search_video_mask_help') }}</span></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_cache_thumbs') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config..cache_thumbnails">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
        </div>
      </div-->
      <div class="field">
        <div class="label"><span>{{ $t('cfg_moviedb_lang') }}</span></div>
        <div class="value">
          <b-select size="sm" v-model="config.general.moviedb_lang">
            <option value="locale">{{ $t('cfg_lang_system') }}</option>
            <option value="en-US">English</option>
            <option value="fr-FR">Français</option>
            <option value="de-DE">Deutsch</option>
            <option value="es-ES">Español</option>
            <option value="it-IT">Italiano</option>
            <option value="nl-NL">Nederlands</option>
            <option value="pt-PT">Português</option>
          </b-select>
        </div>
      </div>
    </div>
    <div class="group" path="general">
      <div class="section">{{ $t('cfg_images') }}</div>
      <div class="field">
        <div class="label">{{ $t('cfg_rotate_images') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.general.rotate_images">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
        </div>
      </div>
    </div>
    <div class="group" path="general">
      <div class="section">{{ $t('cfg_lyrics') }}</div>
      <div class="field">
        <div class="label">{{ $t('cfg_lyrics_download') }}</div>
        <div class="value">
          <b-select size="sm" v-model="config.general.download_lyrics">
            <option :value="false">{{ $t('no') }}</option>
            <option :value="true">{{ $t('yes') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label"><span>{{ $t('cfg_save_lyrics_mode') }}</span></div>
        <div class="value">
          <b-select size="sm" v-model="config.general.save_lyrics_mode">
            <option value="no">{{ $t('cfg_save_lyrics_no') }}</option>
            <option value="confirm">{{ $t('cfg_save_lyrics_confirm') }}</option>
            <option value="yes">{{ $t('cfg_save_lyrics_save') }}</option>
          </b-select>
        </div>
      </div>
      <div class="field">
        <div class="label"><span>{{ $t('cfg_save_lyrics_type') }}</span></div>
        <div class="value">
          <b-select size="sm" v-model="config.general.save_lyrics_type">
            <option value="tags">{{ $t('cfg_save_lyrics_tags') }}</option>
            <option value="txt">{{ $t('cfg_save_lyrics_txt') }}</option>
            <option value="lrc">{{ $t('cfg_save_lyrics_lrc') }}</option>
          </b-select>
        </div>
      </div>
    </div>
    <div class="group" path="network">
      <div class="section">{{ $t('cfg_network') }}</div>
      <!--div class="field">
        <div class="label">{{ $t('cfg_force_local_ip') }}</div>
        <div class="value"><b-input size="sm" type="text" v-model="config.network.forced_local_ip" /></div>
      </div-->
      <div class="field">
        <div class="label">{{ $t('cfg_http_ports') }}<img src="../../icons/information.png" :title="$t('cfg_http_ports_hint')" class="info"/></div>
        <div class="value"><array-input v-model="config.network.http_config_ports" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_lyrics_server_url') }}</div>
        <div class="value"><b-input size="sm" type="text" v-model="config.general.lyrics_server_url" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_sonos_url') }}</div>
        <div class="value"><b-input size="sm" type="text" v-model="config.player.sonos_url" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_tidal_url') }}</div>
        <div class="value"><b-input size="sm" type="text" v-model="config.player.tidal_url" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_upnp_control_url') }}</div>
        <div class="value"><b-input size="sm" type="text" v-model="config.player.upnp_url" /></div>
      </div>
      <div class="field">
        <div class="label">{{ $t('cfg_text_cmd_url') }}</div>
        <div class="value"><b-input size="sm" type="text" v-model="config.player.text_cmd_url" /></div>
      </div>
    </div>
    <div class="buttons">
      <b-button @click="save" variant="primary"><img alt="" src="../../icons/tick.png" /><span>{{ $t('save') }}</span></b-button>
    </div>
  </div>
</template>

<script>

import Config from '../../js/model/config.js'
import ArrayInput from '../components/ArrayInput.vue'
import { loadLanguageAsync } from '../../js/utils/i18n.js'

export default {
  components: { ArrayInput, },
  data: function() {
    return {
      config: null,
      locales: null,
    }
  },
  methods: {
    async refresh() {
    
      // load locales first
      this.locales = await this.$http.getWs('/control/locales')

      // now load config
      let config = new Config({ http: this.$http })
      this.config = await config.get()

    },
    async lang() {

      // submit this directly
      try {

        // build specific update
        let json = { general: { locale: this.config.general.locale } }

        // save config
        let config = new Config({ http: this.$http })
        await config.submit(json)
        this.$bus.emit('notification:success', 'exec_ok')

        // locale
        loadLanguageAsync(this.$http, this.config.general.locale)

      } catch (err) {
        console.error(err)
        this.$bus.emit('notification:error', 'exec_ko')
      }

    },
    async save() {

      // normal save
      try {
        let config = new Config({ http: this.$http })
        await config.save(this.config)
        this.$bus.emit('notification:success', 'exec_ok')
      } catch (err) {
        console.error(err)
        this.$bus.emit('notification:error', 'exec_ko')
      }

      // locale
      loadLanguageAsync(this.$http, this.config.general.locale)

    }

  }

}

</script>

<style scoped>

</style>
