
// from https://www.wikiwand.com/fr/Liste_des_codes_ISO_639-1
const lookup2 =  {
  'aa': 'Afar',
  'ab': 'Abkhazian',
  'ae': 'Avestan',
  'af': 'Afrikaans',
  'ak': 'Akan',
  'am': 'Amharic',
  'an': 'Aragonese',
  'ar': 'Arabic',
  'as': 'Assamese',
  'av': 'Avaric',
  'ay': 'Aymara',
  'az': 'Azerbaijani',
  'ba': 'Bashkir',
  'be': 'Belarusian',
  'bg': 'Bulgarian',
  'bh': 'Bihari',
  'bi': 'Bislama',
  'bm': 'Bambara',
  'bn': 'Bengali',
  'bo': 'Tibetan',
  'br': 'Breton',
  'bs': 'Bosnian',
  'ca': 'Catalan',
  'ce': 'Chechen',
  'ch': 'Chamorro',
  'co': 'Corsican',
  'cr': 'Cree',
  'cs': 'Czech',
  'cu': 'Old Church Slavonic',
  'cv': 'Chuvash',
  'cy': 'Welsh',
  'da': 'Danish',
  'de': 'German',
  'dv': 'Divehi',
  'dz': 'Dzongkha',
  'ee': 'Ewe',
  'el': 'Greek',
  'en': 'English',
  'eo': 'Esperanto',
  'es': 'Spanish',
  'et': 'Estonian',
  'eu': 'Basque',
  'fa': 'Persian',
  'ff': 'Fulah',
  'fi': 'Finnish',
  'fj': 'Fijian',
  'fo': 'Faroese',
  'fr': 'French',
  'fy': 'Western Frisian',
  'ga': 'Irish',
  'gd': 'Scottish Gaelic',
  'gl': 'Galician',
  'gn': 'Guarani',
  'gu': 'Gujarati',
  'gv': 'Manx',
  'ha': 'Hausa',
  'he': 'Hebrew',
  'hi': 'Hindi',
  'ho': 'Hiri Motu',
  'hr': 'Croatian',
  'ht': 'Haitian',
  'hu': 'Hungarian',
  'hy': 'Armenian',
  'hz': 'Herero',
  'ia': 'Interlingua',
  'id': 'Indonesian',
  'ie': 'Interlingue',
  'ig': 'Igbo',
  'ii': 'Sichuan Yi',
  'ik': 'Inupiaq',
  'io': 'Ido',
  'is': 'Icelandic',
  'it': 'Italian',
  'iu': 'Inuktitut',
  'ja': 'Japanese',
  'jv': 'Javanese',
  'ka': 'Georgian',
  'kg': 'Kongo',
  'ki': 'Kikuyu',
  'kj': 'Kwanyama',
  'kk': 'Kazakh',
  'kl': 'Kalaallisut',
  'km': 'Khmer',
  'kn': 'Kannada',
  'ko': 'Korean',
  'kr': 'Kanuri',
  'ks': 'Kashmiri',
  'ku': 'Kurdish',
  'kv': 'Komi',
  'kw': 'Cornish',
  'ky': 'Kirghiz',
  'la': 'Latin',
  'lb': 'Luxembourgish',
  'lg': 'Ganda',
  'li': 'Limburgish',
  'ln': 'Lingala',
  'lo': 'Lao',
  'lt': 'Lithuanian',
  'lu': 'Luba',
  'lv': 'Latvian',
  'mg': 'Malagasy',
  'mh': 'Marshallese',
  'mi': 'Māori',
  'mk': 'Macedonian',
  'ml': 'Malayalam',
  'mn': 'Mongolian',
  'mo': 'Moldavian',
  'mr': 'Marathi',
  'ms': 'Malay',
  'mt': 'Maltese',
  'my': 'Burmese',
  'na': 'Nauru',
  'nb': 'Norwegian Bokmål',
  'nd': 'North Ndebele',
  'ne': 'Nepali',
  'ng': 'Ndonga',
  'nl': 'Dutch',
  'nn': 'Norwegian Nynorsk',
  'no': 'Norwegian',
  'nr': 'South Ndebele',
  'nv': 'Navajo',
  'ny': 'Chichewa',
  'oc': 'Occitan',
  'oj': 'Ojibwa',
  'om': 'Oromo',
  'or': 'Oriya',
  'os': 'Ossetian',
  'pa': 'Panjabi',
  'pi': 'Pāli',
  'pl': 'Polish',
  'ps': 'Pashto',
  'pt': 'Portuguese',
  'qu': 'Quechua',
  'rc': 'Reunionese',
  'rm': 'Romansh',
  'rn': 'Kirundi',
  'ro': 'Romanian',
  'ru': 'Russian',
  'rw': 'Kinyarwanda',
  'sa': 'Sanskrit',
  'sc': 'Sardinian',
  'sd': 'Sindhi',
  'se': 'Northern Sami',
  'sg': 'Sango',
  'sh': 'Serbo-Croatian',
  'si': 'Sinhalese',
  'sk': 'Slovak',
  'sl': 'Slovenian',
  'sm': 'Samoan',
  'sn': 'Shona',
  'so': 'Somali',
  'sq': 'Albanian',
  'sr': 'Serbian',
  'ss': 'Swati',
  'st': 'Sotho',
  'su': 'Sundanese',
  'sv': 'Swedish',
  'sw': 'Swahili',
  'ta': 'Tamil',
  'te': 'Telugu',
  'tg': 'Tajik',
  'th': 'Thai',
  'ti': 'Tigrinya',
  'tk': 'Turkmen',
  'tl': 'Tagalog',
  'tn': 'Tswana',
  'to': 'Tonga',
  'tr': 'Turkish',
  'ts': 'Tsonga',
  'tt': 'Tatar',
  'tw': 'Twi',
  'ty': 'Tahitian',
  'ug': 'Uighur',
  'uk': 'Ukrainian',
  'ur': 'Urdu',
  'uz': 'Uzbek',
  've': 'Venda',
  'vi': 'Viêt Namese',
  'vo': 'Volapük',
  'wa': 'Walloon',
  'wo': 'Wolof',
  'xh': 'Xhosa',
  'yi': 'Yiddish',
  'yo': 'Yoruba',
  'za': 'Zhuang',
  'zh': 'Chinese',
  'zu': 'Zulu'
}

// from https://www.wikiwand.com/fr/Liste_des_codes_ISO_639-1
const lookup3 = {
  'aar': 'Afar',
  'abk': 'Abkhazian',
  'ave': 'Avestan',
  'afr': 'Afrikaans',
  'aka': 'Akan',
  'amh': 'Amharic',
  'arg': 'Aragonese',
  'ara': 'Arabic',
  'asm': 'Assamese',
  'ava': 'Avaric',
  'aym': 'Aymara',
  'aze': 'Azerbaijani',
  'bak': 'Bashkir',
  'bel': 'Belarusian',
  'bul': 'Bulgarian',
  'bih': 'Bihari',
  'bis': 'Bislama',
  'bam': 'Bambara',
  'ben': 'Bengali',
  'bod': 'Tibetan',
  'bre': 'Breton',
  'bos': 'Bosnian',
  'cat': 'Catalan',
  'che': 'Chechen',
  'cha': 'Chamorro',
  'cos': 'Corsican',
  'cre': 'Cree',
  'ces': 'Czech',
  'chu': 'Old Church Slavonic',
  'chv': 'Chuvash',
  'cym': 'Welsh',
  'dan': 'Danish',
  'deu': 'German',
  'ger': 'German',
  'div': 'Divehi',
  'dzo': 'Dzongkha',
  'ewe': 'Ewe',
  'gre': 'Greek',
  'ell': 'Greek',
  'eng': 'English',
  'epo': 'Esperanto',
  'spa': 'Spanish',
  'est': 'Estonian',
  'baq': 'Basque',
  'eus': 'Basque',
  'per': 'Persian',
  'fas': 'Persian',
  'ful': 'Fulah',
  'fin': 'Finnish',
  'fij': 'Fijian',
  'fao': 'Faroese',
  'fre': 'French',
  'frf': 'French (Forced)',
  'fra': 'French',
  'fry': 'Western Frisian',
  'gle': 'Irish',
  'gla': 'Scottish Gaelic',
  'glg': 'Galician',
  'grn': 'Guarani',
  'guj': 'Gujarati',
  'glv': 'Manx',
  'hau': 'Hausa',
  'heb': 'Hebrew',
  'hin': 'Hindi',
  'hmo': 'Hiri Motu',
  'scr': 'Croatian',
  'hrv': 'Croatian',
  'hat': 'Haitian',
  'hun': 'Hungarian',
  'arm': 'Armenian',
  'hye': 'Armenian',
  'her': 'Herero',
  'ina': 'Interlingua',
  'ind': 'Indonesian',
  'ile': 'Interlingue',
  'ibo': 'Igbo',
  'iii': 'Sichuan Yi',
  'ipk': 'Inupiaq',
  'ido': 'Ido',
  'ice': 'Icelandic',
  'isl': 'Icelandic',
  'ita': 'Italian',
  'iku': 'Inuktitut',
  'jpn': 'Japanese',
  'jav': 'Javanese',
  'geo': 'Georgian',
  'kat': 'Georgian',
  'kon': 'Kongo',
  'kik': 'Kikuyu',
  'kua': 'Kwanyama',
  'kaz': 'Kazakh',
  'kal': 'Kalaallisut',
  'khm': 'Khmer',
  'kan': 'Kannada',
  'kor': 'Korean',
  'kau': 'Kanuri',
  'kas': 'Kashmiri',
  'kur': 'Kurdish',
  'kom': 'Komi',
  'cor': 'Cornish',
  'kir': 'Kirghiz',
  'lat': 'Latin',
  'ltz': 'Luxembourgish',
  'lug': 'Ganda',
  'lim': 'Limburgish',
  'lin': 'Lingala',
  'lao': 'Lao',
  'lit': 'Lithuanian',
  'lub': 'Luba-Katanga',
  'lav': 'Latvian',
  'mlg': 'Malagasy',
  'mah': 'Marshallese',
  'mao': 'Māori',
  'mri': 'Māori',
  'mac': 'Macedonian',
  'mkd': 'Macedonian',
  'mal': 'Malayalam',
  'mon': 'Mongolian',
  'mol': 'Moldavian',
  'mar': 'Marathi',
  'may': 'Malay',
  'msa': 'Malay',
  'mlt': 'Maltese',
  'bur': 'Burmese',
  'mya': 'Burmese',
  'nau': 'Nauru',
  'nob': 'Norwegian Bokmål',
  'nde': 'North Ndebele',
  'nep': 'Nepali',
  'ndo': 'Ndonga',
  'dut': 'Dutch',
  'nld': 'Dutch',
  'nno': 'Norwegian Nynorsk',
  'nor': 'Norwegian',
  'nbl': 'South Ndebele',
  'nav': 'Navajo',
  'nya': 'Chichewa',
  'oci': 'Occitan',
  'oji': 'Ojibwa',
  'orm': 'Oromo',
  'ori': 'Oriya',
  'oss': 'Ossetian',
  'pan': 'Panjabi',
  'pli': 'Pāli',
  'pol': 'Polish',
  'pus': 'Pashto',
  'por': 'Portuguese',
  'que': 'Quechua',
  'rcf': 'Reunionese',
  'roh': 'Romansh',
  'run': 'Kirundi',
  'rum': 'Romanian',
  'ron': 'Romanian',
  'rus': 'Russian',
  'kin': 'Kinyarwanda',
  'san': 'Sanskrit',
  'srd': 'Sardinian',
  'snd': 'Sindhi',
  'sme': 'Northern Sami',
  'sag': 'Sango',
  'sin': 'Sinhalese',
  'slo': 'Slovak',
  'slk': 'Slovak',
  'slv': 'Slovenian',
  'smo': 'Samoan',
  'sna': 'Shona',
  'som': 'Somali',
  'alb': 'Albanian',
  'sqi': 'Albanian',
  'scc': 'Serbian',
  'srp': 'Serbian',
  'ssw': 'Swati',
  'sot': 'Sotho',
  'sun': 'Sundanese',
  'swe': 'Swedish',
  'swa': 'Swahili',
  'tam': 'Tamil',
  'tel': 'Telugu',
  'tgk': 'Tajik',
  'tha': 'Thai',
  'tir': 'Tigrinya',
  'tuk': 'Turkmen',
  'tgl': 'Tagalog',
  'tsn': 'Tswana',
  'ton': 'Tonga',
  'tur': 'Turkish',
  'tso': 'Tsonga',
  'tat': 'Tatar',
  'twi': 'Twi',
  'tah': 'Tahitian',
  'uig': 'Uighur',
  'ukr': 'Ukrainian',
  'urd': 'Urdu',
  'uzb': 'Uzbek',
  'ven': 'Venda',
  'vie': 'Viêt Namese',
  'vol': 'Volapük',
  'wln': 'Walloon',
  'wol': 'Wolof',
  'xho': 'Xhosa',
  'yid': 'Yiddish',
  'yor': 'Yoruba',
  'zha': 'Zhuang',
  'chi': 'Chinese',
  'zho': 'Chinese',
  'zul': 'Zulu'

}

export default {

  getLanguageName: function(code) {

    let lang = null
    if (code != null) {

      // clean
      code = code.trim().toLowerCase()

      // avoid special cases
      if (code != 'und') {

        if (code.length == 2) {
          lang = lookup2[code]
        } else if (code.length == 3) {
          lang = lookup3[code]
        }
      }
    
    }

    // done
    return lang == null ? 'Unknown' : lang

  }

}
