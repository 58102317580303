<template>
  <div class="section" v-if="items && items.length">
    <div class="title" @click="toggle">
      <span>
        <Icon :name="collapsed ? 'caret-right-fill' : 'caret-down-fill'" :size="14" />
      </span>
      <span>{{ $t(title) }}</span>
      <Icon name="chevron-double-right" :size="12" class="scroll" v-if="!collapsed" />
    </div>
    <LibraryItemList ref="list" :items="items" :singleLine="true" v-if="!collapsed" />
  </div>
</template>

<script>

import LibraryItemList from './LibraryItemList.vue'

export default {
  components: { LibraryItemList, },
  props: ['id', 'title', 'items', 'storage_key'],
  computed: {
    collapsedStorageKey() {
      return `${this.storage_key}-collapsed-${this.id}`
    },
    scrollStorageKey() {
      return `${this.storage_key}-scroll-${this.id}`
    },
  },
  data: function () {
    return {
      collapsed: localStorage.getItem(this.collapsedStorageKey) == 'true'
    }
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed
      localStorage.setItem(this.collapsedStorageKey, this.collapsed)
    },

    saveScroll(value) {
      let pos = (value === 0) ? 9 : this._scroll()
      this.$store.commit('save_scroll_position', { id: this.scrollStorageKey, pos: pos })
    },

    restoreScroll() {
      this.$nextTick(() => {
        this._scroll(this.$store.state.scrollPositions[this.scrollStorageKey])
      })
    },

    _scroll(pos) {
      // pick 1st/only single line
      let list = this.$refs.list?.$el
      if (!list) return

      // do it
      if (pos == null) {
        return list.scrollLeft
      } else {
        this.$nextTick(() => {
          list.scrollTo(pos || 0, 0)
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '/css/mixins';

.section {
  margin-bottom: var(--library-item-space);

  .title {
    @include user-select-none;
    text-transform: uppercase;
    font-size: 14pt;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: var(--library-item-space);
    cursor: pointer;

    i {
      display: inline-block;
      width: 24px;
      position: relative;
      text-align: center;
      top: -2px;
      left: -6px;
    }

    .scroll {
      margin-right: 16px;
      float: right;
    }
  }

}</style>
