<template>
  <div class="sharing" v-if="shares">
    <div class="header buttons">
      <b-select class="shares" name="shares" @change="select" v-model="share_id">
        <option value="-1" style="font-weight: bold;">{{ $t('cfg_share_select') }}</option>
        <option v-for="share in shares" :key="share.id" :value="share.id">
          {{ share.path }}
        </option>
      </b-select>
      <span>{{ $t('or') }}</span>
      <b-button @click="add_share" variant="primary"><img src="../../icons/folder_add.png"><span>{{ $t('cfg_share_add') }}</span></b-button>
      <b-modal v-model="pick" modal-class="folder-picker" title="Select a folder to share" :ok-disabled="stack.length == 1" @ok="create">
        <div class="current_folder">{{ stack[stack.length-1] }}&nbsp;</div>
        <div class="folder_browser" v-if="folders">
          <div v-for="folder in folders" :key="folder.path" :class="folder.class" @click="go(folder.path)">
            {{ folder.label }}
          </div>
        </div>
      </b-modal>
    </div>
    <div id="detail" v-if="share">
      <div class="group" path="security">
        <div class="section">{{ $t('cfg_options') }}</div>
        <div class="field">
          <div class="label">{{ $t('cfg_path') }}</div>
          <div class="value" id="share_path">{{ share.path }}</div>
        </div>
        <div class="field">
          <div class="label">{{ $t('enabled') }}</div>
          <div class="value">
            <b-select size="sm" name="share_enabled" v-model="share.enabled">
              <option :value="false">{{ $t('no') }}</option>
              <option :value="true">{{ $t('yes') }}</option>
            </b-select>
          </div>
        </div>
      </div>
      <div class="group">
        <div class="section">{{ $t('media_photo') }}</div>
        <div class="field">
          <div class="label">{{ $t('cfg_find_photos') }}</div>
          <div class="value">
            <b-select size="sm" name="photo_enabled" v-model="photo.enabled">
              <option :value="false">{{ $t('no') }}</option>
              <option :value="true">{{ $t('yes') }}</option>
            </b-select>
          </div>
        </div>
        <div class="field">
          <div class="label">{{ $t('cfg_share_virtual') }}</div>
          <div class="value">
            <b-select size="sm" name="photo_virtual" v-model="photo.virtual" :disabled="!photo.enabled">
              <option :value="false">{{ $t('no') }}</option>
              <option :value="true">{{ $t('yes') }}</option>
            </b-select>
          </div>
        </div>
        <div class="field">
          <div class="label">&nbsp;</div>
          <div class="value"><b-input size="sm" type="text" name="photo_virtual_folder" :disabled="!photo.enabled || !photo.virtual" v-model="photo.folder"/></div>
        </div>
      </div>
      <div class="group">
        <div class="section">{{ $t('media_music') }}</div>
        <div class="field">
          <div class="label">{{ $t('cfg_find_music') }}</div>
          <div class="value">
            <b-select size="sm" name="music_enabled" v-model="music.enabled">
              <option :value="false">{{ $t('no') }}</option>
              <option :value="true">{{ $t('yes') }}</option>
            </b-select>
          </div>
        </div>
        <div class="field">
          <div class="label">{{ $t('cfg_share_virtual') }}</div>
          <div class="value">
            <b-select size="sm" name="music_virtual" v-model="music.virtual" :disabled="!music.enabled">
              <option :value="false">{{ $t('no') }}</option>
              <option :value="true">{{ $t('yes') }}</option>
            </b-select>
          </div>
        </div>
        <div class="field">
          <div class="label">&nbsp;</div>
          <div class="value"><b-input size="sm" type="text" name="music_virtual_folder" :disabled="!music.enabled || !music.virtual" v-model="music.folder" /></div>
        </div>
      </div>
      <div class="group">
        <div class="section">{{ $t('media_video') }}</div>
        <div class="field">
          <div class="label">{{ $t('cfg_find_videos') }}</div>
          <div class="value">
            <b-select size="sm" name="video_enabled" v-model="video.enabled">
              <option :value="false">{{ $t('no') }}</option>
              <option :value="true">{{ $t('yes') }}</option>
            </b-select>
          </div>
        </div>
        <div class="field">
          <div class="label">{{ $t('cfg_share_virtual') }}</div>
          <div class="value">
            <b-select size="sm" name="video_virtual" v-model="video.virtual" :disabled="!video.enabled">
              <option :value="false">{{ $t('no') }}</option>
              <option :value="true">{{ $t('yes') }}</option>
            </b-select>
          </div>
        </div>
        <div class="field">
          <div class="label">&nbsp;</div>
          <div class="value"><b-input size="sm" type="text" name="video_virtual_folder" :disabled="!video.enabled || !video.virtual" v-model="video.folder" /></div>
        </div>
      </div>
      <div class="buttons">
        <b-button @click="cancel" variant="primary"><img alt="" src="../../icons/cancel.png" /><span>{{ $t('cancel') }}</span></b-button>
        <b-button @click="remove" variant="primary"><img alt="" src="../../icons/folder_delete.png" /><span>{{ $t('delete') }}</span></b-button>
        <b-button @click="save" variant="primary"><img alt="" src="../../icons/tick.png" /><span>{{ $t('save') }}</span></b-button>
        <b-button @click="scan" variant="primary"><img alt="" src="../../icons/folder_magnify.png" /><span>{{ $t('cfg_share_scan') }}</span></b-button>
      </div>
    </div>
  </div>
</template>

<script>

import utils from '../../js/utils/utils.js'

export default {

  data: function() {
    return {
      pick: false,
      folders: null,
      stack: [],
      shares: null,
      share_id: -1,
      share: null,
    }
  },

  computed: {
    photo() {
      return this.get_share_type('photo')
    },
    music() {
      return this.get_share_type('music')
    },
    video() {
      return this.get_share_type('video')
    },
  },

  methods: {

    async refresh() {
      this.shares = await this.$http.getWs('/control/shares')
      this.share_id = -1
      this.share = null
    },

    add_share() {
      this.stack = []
      this.go('')
    },

    select(id) {
      let share = this.shares.find(e => e.id == id)
      this.share = utils.clone(share)
    },

    get_share_type(type) {

      // empty shares handling
      if (this.share.shares == null) {
        this.share.shares = []
      }

      // find existing sub-share
      for (const share of this.share?.shares) {
        if (share.type == type) {
          if (share.enabled == null) {
            share.enabled = true
            share.virtual = share.folder != ''
          }
          return share
        }
      }

      // not found
      let share = {
        id: -1,
        type: type,
        enabled: false,
        virtual: false,
        folder: '',
      }
      this.share?.shares.push(share)
      return share

    },

    async go(path) {

      // null is back
      if (path == null) {
        this.stack.pop()
        let last = this.stack.pop()
        this.go(last)
        return
      }

      // reset
      let folders = []
      if (path != '') {
        folders.push({
          class: 'up',
          label: this.$i18n.t('back'),
          path: null,
        })
      }

      // get folders
      let data = await this.$http.getWs(`/utils/folders/list?path=${path}`)
      for (const child of data.paths) {
        folders.push({
          class: 'folder',
          label: child.label,
          path: child.path
        })
      }

      // done
      this.stack.push(path)
      this.folders = folders
      this.pick = true

    },

    create(ev) {
      
      // get it
      let folder = this.stack.pop()

      // in case of
      if (folder == '') {
        ev.preventDefault()
        return
      }

      // create new share
      this.share_id = -1
      this.share = {
        id: -1,
        path: folder,
        enabled: true,
        shares: [],
      }

    },

    cancel() {
      this.share_id = -1
      this.share = null
    },

    save() {

      // save
      this.$http.put('/control/share', this.share).then(() => {
        this.$bus.emit('notification:success', 'exec_ok')
        this.refresh()
      }).catch(() => this.$bus.emit('notification:error', 'exec_ko'))

    },

    async remove() {
      let value = await this.$bvModal.msgBoxConfirm(this.$i18n.t('cfg_share_delete_confirm'), {
        okVariant: 'danger',
        okTitle: 'Yes',
      })
      if (value) {

        // post
        this.$http.delete(`/control/share?id=${encodeURIComponent(this.share_id)}`).then(() => {
          this.$bus.emit('notification:success', 'exec_ok')
          this.refresh()
        }).catch(() => this.$bus.emit('notification:error', 'exec_ko'))
      }
    
    },

    scan() {
      this.$http.get(`/control/scan/${this.share_id}`)
        .then(() => this.$bus.emit('notification:success', 'exec_ok'))
        .catch(() => this.$bus.emit('notification:error', 'exec_ko'))
    },

  },

}


</script>

<style scoped>

.sharing .header {
  text-align: center;
  margin-bottom: 64px;
  margin-top: 0px;
}

.sharing .header .shares {
  width: 250px;
}

.sharing .header span {
  margin: 0px 8px;
}

.folder-picker .folder_browser, .folder-picker .current_folder {
  width: 100%;
}

.folder-picker .folder_browser {
  height: 240px;
  padding-left: 8px;
  overflow: auto;
}

.folder-picker .folder_browser > div {
  cursor: pointer;
  padding-left: 26px;
  margin: 2px 0px;
}

.folder-picker .folder_browser > div:hover {
  color: var(--highlight-color);
}

.folder-picker .folder_browser .folder {
  background: url(../../icons/folder_mini.png) no-repeat;
  background-position: 2px 4px;
  background-size: 16px 16px;
}

.folder-picker .folder_browser .up {
  background: url(../../icons/folder_up.png) no-repeat;
  background-position: 2px 2px;
  background-size: 16px 16px;
}

.folder-picker .current_folder {
  padding-bottom: 16px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--ghosted-text-color);
}

</style>
