<template>
  <div class="app-import">
    <b-tabs align="center" @activate-tab="activate">
        <b-tab id="tidal">
          <template #title>
            <img class="logo" :src="'/icons/tidal.png'" />
          </template>
          <Tidal ref="tidal" />
        </b-tab>
      </b-tabs>
  </div>
</template>

<script>

import Tidal from './Tidal.vue'

export default {
  components: { Tidal },
  data: function() {
    return {
    }
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
    activate(newTabIndex, _, bvEvent) {
      // get the tab by index in the event and the find it by id in the ref...
      let tab = bvEvent?.vueTarget?.getTabs()?.[newTabIndex]
      let ref = this.$refs[tab?.id]
      ref?.refresh()
    },
  }    

}

</script>

<style lang="scss" scoped>

.app-import {
  padding-left: 20%;
  padding-right: 20%;
  margin-top: 4em;
  padding-bottom: 4em;

}

.app-import .app-import-content .tab-pane {
  margin: 0 auto;
  margin-top: 32px;
  width: 900px;
}

.logo {
  height: 12px;
  opacity: var(--faded-opacity);
}

</style>
