
<template>
  <div id="app" v-if="ready">
    <router-view name="fullscreen" class="app-fullscreen"></router-view>
    <div class="app-background"></div>
    <div class="app-navigation-drawer">
      <NavigationBar />
    </div>
    <div class="app-now-playing-drawer">
      <NowPlaying />
    </div>
    <div class="app-content-area">
      <SearchBar class="app-search" />
      <VoiceCommand class="app-command"/>
      <div class="app-content-header">
        <BreadCrumb />
      </div>
      <!--HeroTransition :backSupport="false"-->
        <router-view :key="$route.fullPath" id="app-content" class="app-content"></router-view>
      <!--/HeroTransition-->
    </div>
    <PlaylistPanel class="playlist-panel" :class="showPlaylist ? '' : 'collapsed'" />
    <PlaylistModals />
  </div>
</template>

<script>

import BreadCrumb from './components/BreadCrumb.vue'
import NavigationBar from './navigation/NavigationBar.vue'
import NowPlaying from './audio/NowPlaying.vue'
import PlaylistModals from './playlist/PlaylistModals.vue'
import PlaylistPanel from './playlist/PlaylistPanel.vue'
import SearchBar from './components/SearchBar.vue'
import VoiceCommand from './components/VoiceCommand.vue'
import { loadLanguageAsync } from '../js/utils/i18n.js'

export default {
  components: { NavigationBar, BreadCrumb, NowPlaying, PlaylistModals, PlaylistPanel, SearchBar, VoiceCommand, },
  data: function() {
    return {
      ready: false,
      showPlaylist: false,
    }
  },
  created: async function() {

    // load current locale
    await loadLanguageAsync(this.$http)
    this.ready = true

    // load settings
    this.showPlaylist = localStorage.showPlaylist == 'true'

  },
  mounted: function() {

    // confirmation
    this.$bus.on('notification:success', (msg) => {
      this.$bvToast.toast(this.$i18n.t(msg), {
        title: this.$i18n.t(msg),
        variant: 'success',
        headerClass: '',
        bodyClass: 'd-none',
      })
    })

    // info
    this.$bus.on('notification:info', (msg) => {
      this.$bvToast.toast(this.$i18n.t(msg), {
        title: this.$i18n.t(msg),
        variant: 'info',
        headerClass: '',
        bodyClass: 'd-none',
      })
    })

    // error message
    this.$bus.on('notification:error', (msg) => {
      this.$bvModal.msgBoxOk(this.$i18n.t(msg), {
        okVariant: 'danger',
      })
    })

    // movie
    this.$bus.on('movie:cast', this.playMovie)

    // playlist
    this.$bus.on('playlist:show', () => this.togglePlaylist(true))
    this.$bus.on('playlist:hide', () => this.togglePlaylist(false))

  },
  methods: {

    togglePlaylist(show) {
      this.showPlaylist = show
      localStorage.showPlaylist = show
    },

    playMovie(movie) {
      const cjs = new Castjs()
      if (cjs.available) {
        this.$bvModal.msgBoxConfirm('Do you want to cast this video to a local device?', {
          //centered: true,
        }).then(value => {
          if (value) {
            cjs.cast(movie.upnp_url)
          }
        })
      } else {
        this.$bvModal.msgBoxOk('Chromecast is not available in this browser', {
          okVariant: 'danger',
        })
      }      
    },

  }
}

</script>

<style lang="scss" scoped>

#app {
  height: 100vh;
  display: grid;
  grid-template-columns: var(--navigation-width) 1fr auto;
  grid-template-rows: 1fr var(--now-playing-height);
  grid-template-areas:
    "app-navigation-drawer app-content-area playlist-panel"
    "app-now-playing-drawer app-now-playing-drawer app-now-playing-drawer";
}

.app-fullscreen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}

.app-background {
  background-image: var(--app-background);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.app-content-area {
  
  position: relative;
  grid-area: app-content-area;
  padding: calc(var(--content-padding) / 2) var(--content-padding) 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: hidden;

  .app-content-header {
    height: var(--content-header-height);
    padding-bottom: 16px;
  }

  .app-content {
    overflow-y: scroll;
    height: 100%;
  }

  .app-search {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
  }

  .app-command {
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 10;
  }

}

.app-navigation-drawer {
  grid-area: app-navigation-drawer;
  z-index: 1;
}

.app-now-playing-drawer {
  grid-area: app-now-playing-drawer;
  z-index: 2;
}

.playlist-panel {
  grid-area: playlist-panel;
  width: var(--playlist-panel-width);
  z-index: 1;
}


</style>
