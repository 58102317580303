
import VueI18n from 'vue-i18n'

export let i18n

//i18n
export const initI18n = () => {
  i18n = new VueI18n({
    locale: 'xx',
    fallbackLocale: 'xx',
    messages: {
      xx: {
        message: {
        }
      },
    },
  })
  return i18n
}

const loadedLanguages = ['xx'] // our default language that is preloaded

function setI18nLanguage(http, lang) {
  i18n.locale = lang
  http.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(http, lang) {

  // lang is two chars, locale is 5 chars
  let locale = ''
  if (lang != null) {
    if (lang.length > 2) {
      locale = lang
      lang = lang.substr(0, 2)
    } else {
      locale = {
        en: 'en_US',
        fr: 'fr_FR'
      }[lang]
    }
  }

  // if lang specified
  if (lang != null) {

    // if the same language
    if (i18n.locale === lang) {
      return Promise.resolve(setI18nLanguage(http, lang))
    }

    // if the language was already loaded
    if (loadedLanguages.includes(lang)) {
      return Promise.resolve(setI18nLanguage(http, lang))
    }

  }

  // if the language hasn't been loaded yet
  return http.getWs(`/control/locale?id=${locale}`).then((json) => {

    // if no lang specified get it from json
    if (lang == null) {
      let locale = json.locale
      lang = locale.substr(0, 2)
    }

    // set it
    i18n.setLocaleMessage(lang, json.labels)
    loadedLanguages.push(lang)
    return setI18nLanguage(http, lang)
  
  })
  
}
