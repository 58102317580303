<template>
  <div class="panel">
    <div class="panel-header">
      <PlaylistMenu :playlist="playlist" placement="left" />
      <span class="title">{{ title || $t('browser_pl_editor') }}</span>
      <span class="menu">
        <Icon name="chevron-bar-contract" :size=12 @click.native="empty" />
        <Icon name="arrow-down-left-square" :size=12 @click.native="hide" />
      </span>
    </div>
    <div class="panel-body">
      <PlaylistTracks :playlist="playlist" />
    </div>
  </div>
</template>

<script>

import PlaylistMenu from './PlaylistMenu.vue'
import PlaylistTracks from './PlaylistTracks.vue'

export default {
  components: { PlaylistMenu, PlaylistTracks, },
  data: function() {
    return {
      playlist: null,
    }
  },
  computed: {
    title() {
      if (this.playlist?.id === null) return null
      else return this.playlist?.title
    },
  },
  mounted: function() {
    this.$audio.on('status', (status) => {
      this.playlist = status?.playlist
      //console.log(this.playlist?.title)
    })
  },
  methods: {
    empty() {
      this.$audio.empty()
    },
    hide() {
      this.$bus.emit('playlist:hide')
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
